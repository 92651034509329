import {useParams} from 'react-router-dom'
import {appsAPI} from '../../../../services/AppsService'
import {KTSVG} from '../../../../template/helpers'
import {Domain} from './Domain'
import * as Yup from 'yup'
import {IgnoreLines} from './IgnoreLines'
import {AMOTokens} from '../crm/amo/AMOTokens'
import {UseB24Users} from '../crm/bitrix/UseB24Users'
import {RecordURL} from '../pbx/asterisk/RecordURL'
import {useState} from 'react'
import {ITrunkModel} from '../../../../types/apps/common/TrunkModel'
import {useFormik} from 'formik'
import {IOtherSettingsCreate, OtherSettingsInitValues} from '../../core/_models'
import {IAppsModel} from '../../../../types/apps/AppsModel'

const OtherSettingsEditShema = Yup.object().shape({
  crm_domain: Yup.string().required('Domain is required'),
  record_url: Yup.string(),
  ignore_trunks: Yup.string(),
  ws_amocrm_token: Yup.string(),
  ws_google_chrome_token: Yup.string(),
  users_in_service: Yup.boolean(),
})

const check_ignore_trunks = (ignore_trunks: string) => {
  let ignore_trunks_edit = ignore_trunks.replace(/[.:;*-/,%]/g, '')
  ignore_trunks_edit = ignore_trunks.replace('  ', ' ')
  return ignore_trunks_edit
}

const get_list_ignore_trunks = (str_ignore_trunks: string) => {
  let list_ignore_trunks = str_ignore_trunks.split(' ')
  if (list_ignore_trunks[list_ignore_trunks.length - 1] === '') {
    list_ignore_trunks.splice(list_ignore_trunks.length - 1, 1)
  }

  return list_ignore_trunks
}

const get_ignore_trunks = (ignore_trunks: ITrunkModel[]) => {
  let list_ignore_trunks = []
  for (let i = 0; i < ignore_trunks.length; i++) {
    list_ignore_trunks.push(ignore_trunks[i].trunk)
  }
  if (list_ignore_trunks[list_ignore_trunks.length - 1] === '') {
    list_ignore_trunks.splice(list_ignore_trunks.length - 1, 1)
  }
  let str_ignore_trunks = list_ignore_trunks.join(' ')
  return str_ignore_trunks
}

const OtherSettings = () => {
  const {appId} = useParams()
  const {data: app} = appsAPI.useFetchAppByIdQuery(appId!)
  const [otherSettingsState, updateOtherSettingsState] = useState(false)
  const [updateApp, {isLoading}] = appsAPI.useUpdateAppMutation()

  const formik = useFormik<IOtherSettingsCreate>({
    initialValues: Object.assign(OtherSettingsInitValues, {
      crm_domain: app?.crm_domain,
      ws_amocrm_token: app?.crm === 'AMOCRM' ? app?.settings?.amocrm.ws_tokens.ws_amocrm_token : '',
      ws_google_chrome_token: app?.crm === 'AMOCRM' ? app?.settings?.amocrm.ws_tokens.ws_google_chrome_token : '',
      users_in_service: app?.crm === 'BITRIX24' ? app?.settings?.bitrix24.users_in_service : false,
      record_url: app?.pbx === 'ASTERISK' ? app?.settings?.asterisk.record_url : '',
      ignore_trunks: get_ignore_trunks(app?.settings.ignore_trunks ? app?.settings.ignore_trunks : []),
    }),
    validationSchema: OtherSettingsEditShema,
    onSubmit: async (values) => {
      let other_settings = {}
      if (app?.crm === 'AMOCRM' && app?.pbx === 'ASTERISK') {
        other_settings = {
          amocrm: {
            ws_tokens: {
              ws_amocrm_token: values.ws_amocrm_token,
              ws_google_chrome_token: values.ws_google_chrome_token,
            },
          },
          asterisk: {
            record_url: values.record_url,
          },
          ignore_trunks: get_list_ignore_trunks(values.ignore_trunks),
        }
      } else if (app?.crm === 'BITRIX24' && app?.pbx === 'ASTERISK') {
        other_settings = {
          asterisk: {
            record_url: values.record_url,
          },
          bitrix24: {
            users_in_service: values.users_in_service,
          },
          ignore_trunks: get_list_ignore_trunks(values.ignore_trunks),
        }
      } else if (app?.crm === 'BITRIX24' && app?.pbx === 'YEASTAR') {
        other_settings = {
          bitrix24: {
            users_in_service: values.users_in_service,
          },
          ignore_trunks: get_list_ignore_trunks(values.ignore_trunks),
        }
      } else if (app?.crm === 'AMOCRM' && app?.pbx === 'YEASTAR') {
        other_settings = {
          amocrm: {
            ws_tokens: {
              ws_amocrm_token: values.ws_amocrm_token,
              ws_google_chrome_token: values.ws_google_chrome_token,
            },
          },
          ignore_trunks: get_list_ignore_trunks(values.ignore_trunks),
        }
      }
      const data = {
        id: appId,
        crm_domain: values.crm_domain.replace('https:', '').replace('http:', '').replaceAll('/', ''),
        settings: other_settings,
      }
      await updateApp(data as IAppsModel)
      updateOtherSettingsState(false)
      formik.setFieldValue('crm_domain', data.crm_domain)
    },
  })

  return (
    <>
      {otherSettingsState === false && (
        <div className='card card-custom card-stretch shadow-sm mb-5'>
          <div className='card-header'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Settings</h3>
            </div>
            <button className='btn btn-sm btn-primary align-self-center' onClick={() => updateOtherSettingsState(true)}>
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-info me-1 align-self-center' />
              Edit
            </button>
          </div>
          <div className='card-body pt-5 pb-5'>
            {(app?.crm === 'BITRIX24' || app?.crm === 'AMOCRM') && <Domain />}
            {app?.crm === 'AMOCRM' && (
              <>
                <div className='separator separator-dashed my-5'></div>
                <AMOTokens />
              </>
            )}
            {app?.crm === 'BITRIX24' && (
              <>
                <div className='separator separator-dashed my-5'></div>
                <UseB24Users />
              </>
            )}
            {app?.pbx === 'ASTERISK' && (
              <>
                <div className='separator separator-dashed my-5'></div>
                <RecordURL />
              </>
            )}
            <div className='separator separator-dashed my-5'></div>
            <IgnoreLines />
          </div>
        </div>
      )}
      {otherSettingsState === true && (
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card card-custom card-stretch shadow-sm mb-5'>
            <div className='card-header'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Edit Settings</h3>
              </div>
            </div>
            <div className='card-body pt-5 pb-5'>
              {(app?.crm === 'BITRIX24' || app?.crm === 'AMOCRM') && (
                <div className='row mb-5'>
                  <label className='col-lg-4 col-form-label required fw-bolder text-gray-600'>
                    <span className='small text-uppercase'>CRM Domain</span>
                  </label>

                  <div className='col-lg-8'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-900'
                      placeholder='CRM Domain'
                      {...formik.getFieldProps('crm_domain')}
                    />
                    {formik.touched.crm_domain && formik.errors.crm_domain && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger'>{formik.errors.crm_domain}</div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {app?.crm === 'AMOCRM' && (
                <>
                  <div className='row mb-5'>
                    <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                      <span className='small text-uppercase'>AmoCRM Token</span>
                    </label>

                    <div className='col-lg-8'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-900'
                        placeholder='AmoCRM token'
                        {...formik.getFieldProps('ws_amocrm_token')}
                      />
                      {formik.touched.ws_amocrm_token && formik.errors.ws_amocrm_token && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger'>{formik.errors.ws_amocrm_token}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-5'>
                    <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                      <span className='small text-uppercase'>Google Chrome Token</span>
                    </label>

                    <div className='col-lg-8 d-flex align-items-center'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-900'
                        placeholder='Google Chrome Token'
                        {...formik.getFieldProps('ws_google_chrome_token')}
                      />
                      {formik.touched.ws_google_chrome_token && formik.errors.ws_google_chrome_token && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger'>{formik.errors.ws_google_chrome_token}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
              {app?.crm === 'BITRIX24' && (
                <div className='row mb-5'>
                  <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                    <span className='small text-uppercase'>Use Bitrix24 Users</span>
                  </label>

                  <div className='col-lg-8 d-flex align-items-center'>
                    <div className='form-check form-switch form-check-custom form-check-solid me-10'>
                      <input
                        className='form-check-input h-20px w-30px align-self-center'
                        type='checkbox'
                        id='switchUseB24'
                        defaultChecked={app.settings.bitrix24.users_in_service === true ? true : false}
                        {...formik.getFieldProps('users_in_service')}
                      />
                    </div>
                  </div>
                </div>
              )}
              {app?.pbx === 'ASTERISK' && (
                <div className='row mb-5'>
                  <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                    <span className='small text-uppercase'>Record URL</span>
                  </label>
                  <div className='col-lg-8'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-900'
                      placeholder='http://123.123.123.123:38080/ or https://example.com:38080/'
                      {...formik.getFieldProps('record_url')}
                    />
                  </div>
                </div>
              )}
              <div className='row mb-5'>
                <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>Ignore lines (DID) Numbers</span>
                </label>

                <div className='col-lg-8'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-900'
                    placeholder='+375291234567 +375441111111'
                    onInput={(e: any) => {
                      e.target.value = check_ignore_trunks(e.target.value)
                    }}
                    {...formik.getFieldProps('ignore_trunks')}
                  />
                </div>
              </div>

              <div className='card-footer d-flex justify-content-end py-2 px-1' style={{border: 'none'}}>
                <button type='submit' className='btn btn-sm btn-primary me-3' disabled={isLoading}>
                  {!isLoading && 'Save Changes'}
                  {isLoading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
                <button
                  className='btn btn-sm btn-light-primary align-self-center'
                  onClick={() => {
                    updateOtherSettingsState(false)
                    formik.resetForm()
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  )
}

export {OtherSettings}
