import {createApi} from '@reduxjs/toolkit/dist/query/react'
import {baseQueryWithReauth} from './BaseQueryReauth'
import {IAppActions, IAppsModel, IAppStatus} from '../types/apps/AppsModel'
import {IAppCreateData} from '../template/partials/modals/create-app/IAppModels'

interface IQueryParams {
  skip?: number
  limit?: number
  search?: string
}

interface ListResponse<T> {
  data: T[]
  total: number
}

interface IResponse {
  status: string
  detail: string
}

interface ILicenseUpdate {
  id: string
  data?: {
    is_commercial?: boolean
    maxusers?: number
    license_end?: string
    is_active?: boolean
  }
}

export const appsAPI = createApi({
  reducerPath: 'appsAPI',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['apps', 'license'],
  endpoints: (build) => ({
    fetchAppsMe: build.query<IAppsModel[], void>({
      query: () => ({
        url: '/apps/me',
      }),
      providesTags: (result) =>
        result
          ? [...result.map(({id}) => ({type: 'apps' as const, id})), {type: 'apps', id: 'LIST'}]
          : [{type: 'apps', id: 'LIST'}],
    }),
    createApp: build.mutation<IResponse, IAppCreateData>({
      query: ({crm_domain, crm, pbx, region, is_auto_mode, time_zone, settings}) => ({
        url: '/apps/',
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: {
          crm_domain: crm_domain,
          crm: crm,
          pbx: pbx,
          region: region,
          is_auto_mode: is_auto_mode,
          time_zone: time_zone,
          settings: settings,
        },
      }),
      invalidatesTags: ['apps'],
    }),
    fetchAppById: build.query<IAppsModel, string>({
      query: (id) => ({
        url: `/apps/${id}`,
      }),
      providesTags: (result, error, id) => [{type: 'apps' as const, id}],
    }),
    fetchAppsUserById: build.query<IAppsModel[], {user_id: string; skip?: number; limit?: number}>({
      query: ({user_id, skip = 0, limit = 50}) => ({
        url: `/apps/users/${user_id}`,
        params: {skip: skip, limit: limit},
      }),
      providesTags: (result) =>
        result
          ? [...result.map(({id}) => ({type: 'apps' as const, id})), {type: 'apps', id: 'LIST'}]
          : [{type: 'apps', id: 'LIST'}],
    }),
    fetchApps: build.query<ListResponse<IAppsModel>, IQueryParams>({
      query: ({search, skip = 0, limit = 50}) => ({
        url: '/apps/',
        params: {skip: skip, limit: limit, search: search!},
      }),
      providesTags: (result) =>
        result
          ? [...result.data.map(({id}) => ({type: 'apps' as const, id})), {type: 'apps', id: 'LIST'}]
          : [{type: 'apps', id: 'LIST'}],
    }),
    updateApp: build.mutation<IResponse, IAppsModel>({
      query: (app_settings) => ({
        url: '/apps/' + app_settings.id,
        method: 'PATCH',
        body: app_settings,
      }),
      invalidatesTags: ['apps'],
    }),
    fetchAppStatus: build.query<IAppStatus, string>({
      query: (id) => ({
        url: `/apps/status/${id}`,
      }),
    }),
    actionApp: build.mutation<void, IAppActions>({
      query: ({id, action}) => ({
        url: `/apps/actions/${id}`,
        method: 'POST',
        body: {
          action: action,
        },
      }),
    }),
    updateLicense: build.mutation<void, ILicenseUpdate>({
      query: ({id, data}) => ({
        url: `/licenses/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['apps'],
    }),
  }),
})
