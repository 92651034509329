/* eslint-disable jsx-a11y/anchor-is-valid */
import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {KTCardBody} from '../../../../../../../template/helpers'
import {BitrixUsersColumns} from './columns/_columns'
import {useParams} from 'react-router-dom'
import {appsAPI} from '../../../../../../../services/AppsService'
import {IBitrixUserModel} from '../../../../../../../types/apps/crm/biitrix24/BitrixUserModel'

const BitrixUsersTable = () => {
  const {appId} = useParams()
  const {data: app} = appsAPI.useFetchAppByIdQuery(appId!)
  const users = app?.settings.bitrix24.users_list
  const data = users ? (users as IBitrixUserModel[]) : []

  const columns = useMemo(() => BitrixUsersColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-0'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-8 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-8 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<IBitrixUserModel>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold text-center' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<IBitrixUserModel>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100'>No matching records found</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </KTCardBody>
  )
}

export {BitrixUsersTable}
