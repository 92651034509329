/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {authAPI} from '../../../services/AuthService'
import {useAppDispatch} from '../../../hooks/redux'
import {loginAction} from '../../../slices/authSlice'
import {usersAPI} from '../../../services/UsersService'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const dispatch = useAppDispatch()
  const [userLogin, {isLoading}] = authAPI.useLoginMutation()
  const [getCurrentUser] = usersAPI.useLazyFetchGetMeQuery()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      await userLogin({username: values.email, password: values.password})
        .unwrap()
        .then(async (result) => {
          dispatch(loginAction(result))
          await getCurrentUser()
        })
        .catch((error) => {
          setStatus(error.data.detail)
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_login_signin_form'>
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3 fs-5'>Login to Callbee</h1>
          <Link to='/auth/registration' className='link-primary fs-5 fw-bolder'>
            Create an Account
          </Link>
          {/* </div> */}
        </div>

        {formik.status ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : null}

        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
            placeholder='Email'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          )}
        </div>

        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
              <Link to='/auth/forgot-password' className='link-primary fs-6 fw-bolder' style={{marginLeft: '5px'}}>
                Forgot Password ?
              </Link>
            </div>
          </div>
          <input
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>

        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!isLoading && <span className='indicator-label'>Continue</span>}
            {isLoading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      <a href='https://v1.callbee.io/profile' className='small'>
        Go to Callbee v1{' '}
      </a>
      <span className='text-danger small'>(depricated, old version)</span>
    </>
  )
}
