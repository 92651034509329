import clsx from 'clsx'
import {useRef, useEffect, useCallback, FC, useState} from 'react'
import {useAppSelector} from '../../../hooks/redux'

const REACT_APP_WEBSOCKET_SERVER = process.env.REACT_APP_WEBSOCKET_SERVER

type Props = {
  client_id: string
}

type Message = {
  uid?: string
  event: string
  eventData: string
}

type MessageType = {
  time?: string
  channel: string
  data: Message
}

const AppWsLogs: FC<Props> = ({client_id}) => {
  const {accessToken} = useAppSelector((state) => state.authReduser)
  const ws = useRef<WebSocket | null>()
  const [messages, setMessages] = useState<MessageType[]>([])

  function addZero(i: number | string) {
    if (i < 10) {
      i = '0' + i
    }
    return i
  }

  const getTime = () => {
    const d = new Date()
    let h = addZero(d.getHours())
    let m = addZero(d.getMinutes())
    let s = addZero(d.getSeconds())
    let time = h + ':' + m + ':' + s
    return time
  }

  const addMessage = (msg: MessageType) => {
    msg.time = getTime()
    setMessages((prev) => {
      return [...prev, msg]
    })
  }

  const gettingData = useCallback(() => {
    if (!ws.current) return

    ws.current.onmessage = (e) => {
      const incomingData = JSON.parse(e.data)
      addMessage(incomingData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    ws.current = new WebSocket(`${REACT_APP_WEBSOCKET_SERVER}/ws/${client_id}/?access_token=${accessToken}`)
    const wsCurrent = ws.current
    gettingData()

    return () => wsCurrent.close(1000)
  }, [accessToken, client_id, gettingData])
  return (
    <>
      {messages.length === 0 && <div>No Events</div>}
      <div className='timeline-label'>
        {messages?.map((message, index) => (
          <div key={index} className='timeline-item mb-3'>
            <div className='timeline-label fw-bold text-gray-800 fs-7 me-1'>{message.time}</div>
            <div className='timeline-badge'>
              <i
                className={clsx(
                  'fa fa-genderless fs-3',
                  {'text-success': message.data.event === 'INCOMING'},
                  {'text-info': message.data.event === 'NOTIFICATION'},
                  {'text-warning': message.data.event === 'OUTGOING'},
                  {'text-dark': message.data.event === 'RECORD'},
                  {'text-primary': message.data.event === 'FINISH'}
                )}
              />
            </div>
            <div className='timeline-content d-flex'>
              <span className='fw-bold text-gray-800 ps-3'>
                <span className='badge badge-light-dark'>{message.data.uid}</span> {message.data.event}:{' '}
                {message.data.eventData}
              </span>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export {AppWsLogs}
