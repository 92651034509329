import {createRoot} from 'react-dom/client'
import {RootI18nProvider} from './app/template/i18n/RootI18n'
import 'bootstrap/dist/js/bootstrap.bundle.min'

import 'bootstrap-daterangepicker/daterangepicker.css'
import './app/template/assets/sass/style.scss'
import './app/template/assets/sass/plugins.scss'
import './app/template/assets/sass/style.react.scss'
import 'animate.css'
import {AppRoutes} from './app/routing/AppRoutes'
import {Provider} from 'react-redux'
import store from './app/store/Store'
import {AuthInit} from './app/modules/auth'

const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <Provider store={store}>
      <RootI18nProvider>
        <AuthInit>
          <AppRoutes />
        </AuthInit>
      </RootI18nProvider>
    </Provider>
  )
}
