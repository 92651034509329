import {configureStore} from '@reduxjs/toolkit'
import storage from 'redux-persist/es/storage'
import {persistReducer, persistStore} from 'redux-persist'
import AuthSlice from '../slices/authSlice'
import {usersAPI} from '../services/UsersService'
import {authAPI} from '../services/AuthService'
import {appsAPI} from '../services/AppsService'
import {crmAPI} from '../services/CRMService'

const authPersistConfig = {
  key: 'callbee:auth',
  storage: storage,
  whitelist: ['accessToken'],
}

export const authPersistor = persistReducer(authPersistConfig, AuthSlice)

const store = configureStore({
  reducer: {
    authReduser: authPersistor,
    [usersAPI.reducerPath]: usersAPI.reducer,
    [authAPI.reducerPath]: authAPI.reducer,
    [appsAPI.reducerPath]: appsAPI.reducer,
    [crmAPI.reducerPath]: crmAPI.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(usersAPI.middleware)
      .concat(authAPI.middleware)
      .concat(appsAPI.middleware)
      .concat(crmAPI.middleware),
  devTools: process.env.NODE_ENV === 'development',
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const persistor = persistStore(store)

export default store
