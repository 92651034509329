/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

type Props = {
  phone: string
}

const AmoUsersPhoneCell: FC<Props> = ({phone}) => (
  <input className='text-gray-900 form-control form-control-sm form-control-flush' value={phone} disabled />
)

export {AmoUsersPhoneCell}
