/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

type Props = {
  id: string
}

const BitrixUsersIDCell: FC<Props> = ({id}) => (
  <input className='text-gray-900 form-control form-control-sm form-control-flush' value={id} disabled />
)

export {BitrixUsersIDCell}
