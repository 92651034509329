import {FC} from 'react'

type Props = {
  name: string
}

const BitrixUsersNameCell: FC<Props> = ({name}) => (
  <input className='text-gray-900 form-control form-control-sm form-control-flush' value={name} disabled />
)

export {BitrixUsersNameCell}
