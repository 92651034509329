import {createApi} from '@reduxjs/toolkit/dist/query/react'
import {baseQueryWithReauth} from './BaseQueryReauth'

interface IResponse {
  status: string
  detail: string
}

export const crmAPI = createApi({
  reducerPath: 'crmAPI',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['crm'],
  endpoints: (build) => ({
    checkBitrix24Tokens: build.query<IResponse, string>({
      query: (crm_domain) => ({
        url: `/crm/bitrix24/${crm_domain}`,
      }),
    }),
    checkAmoCRMTokens: build.query<IResponse, string>({
      query: (crm_domain) => ({
        url: `/crm/amocrm/${crm_domain}`,
      }),
    }),
  }),
})
