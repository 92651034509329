/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {appsAPI} from '../../../services/AppsService'
import {KTSVG} from './KTSVG'

type Props = {
  app_id: string
  isActive: boolean
}

const REACT_APP_STATUS_TIMEOUT = process.env.REACT_APP_STATUS_TIMEOUT

const AppStatusById: FC<Props> = ({app_id, isActive}) => {
  const [pollingInterval, setPollingInterval] = useState<number>(0)
  const [skip, setSkip] = useState<boolean>(true)

  const {
    data: appStatus,
    isFetching: appStatusFetching,
    isLoading: appStatusLoading,
  } = appsAPI.useFetchAppStatusQuery(app_id, {
    skip: skip,
    pollingInterval: pollingInterval,
  })

  useEffect(() => {
    if (isActive) {
      setPollingInterval(parseInt(REACT_APP_STATUS_TIMEOUT as string))
      setSkip(false)
    }
  }, [app_id, isActive])

  return (
    <>
      <a
        className='btn btn-icon btn-sm cursor-default'
        title={appStatusLoading || appStatusFetching ? 'Loading' : appStatus?.is_running ? 'Running' : 'Stopped'}
      >
        {appStatusLoading || appStatusFetching ? (
          <KTSVG path='/media/icons/duotune/abstract/abs050.svg' className='svg-icon-muted svg-icon-5' />
        ) : appStatus?.is_running ? (
          <KTSVG path='/media/icons/duotune/abstract/abs050.svg' className='svg-icon-success svg-icon-5' />
        ) : (
          <KTSVG path='/media/icons/duotune/abstract/abs050.svg' className='svg-icon-danger svg-icon-5' />
        )}
      </a>
    </>
  )
}

export {AppStatusById}
