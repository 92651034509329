import {BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from '@reduxjs/toolkit/query'
import {loginAction, logoutAction} from '../slices/authSlice'
import {RootState} from '../store/Store'
import {IAuthModel} from '../types'
import {Mutex} from 'async-mutex'

const API_URL = process.env.REACT_APP_API_URL

const mutex = new Mutex()
const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, {getState}) => {
    headers.set('Content-Type', 'application/json')
    const accessToken = (getState() as RootState).authReduser.accessToken
    if (accessToken) headers.set('Authorization', `Bearer ${accessToken}`)
    return headers
  },
  credentials: 'include',
})
export const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  await mutex.waitForUnlock()
  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        const refreshResult = await baseQuery('/auth/refresh-token', api, extraOptions)
        if (refreshResult.data) {
          api.dispatch(loginAction(refreshResult.data as IAuthModel))
          result = await baseQuery(args, api, extraOptions)
        } else {
          api.dispatch(logoutAction())
        }
      } finally {
        release()
      }
    } else {
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }
  return result
}
