import {useParams} from 'react-router-dom'
import {appsAPI} from '../../../services/AppsService'
import {KTSVG} from '../../../template/helpers'
import {LinesAmoCRM} from './crm/amo/lines/LinesAmoCRM'
import {OutgoingCall} from './crm/amo/OutgoingCall'
import {LinesBitrix} from './crm/bitrix/lines/LinesBitrix'

const AppLines = () => {
  const {appId} = useParams()
  const {data: app} = appsAPI.useFetchAppByIdQuery(appId!)

  return (
    <>
      {app?.crm === 'AMOCRM' && <OutgoingCall />}
      <div className='card card-custom card-stretch shadow-sm mb-5'>
        <div className='card-header'>
          <div className='d-flex flex-stack col-12 mt-5 me-10'>
            <div className='card-title m-0 d-flex flex-wrap'>
              <h3 className='fw-bolder m-0'>Incoming Lines</h3>
            </div>
            <div className='text-align-end align-self-center d-flex'>
              <button className='btn btn-sm btn-primary' data-bs-toggle='modal' data-bs-target='#kt_modal_1'>
                <>
                  <KTSVG path='/media/icons/duotune/arrows/arr009.svg' className='svg-icon-muted svg-icon-1x' />
                  Add line
                </>
              </button>
            </div>
          </div>

          {app?.crm === 'AMOCRM' && (
            <div className='text-align-start row mb-5 mt-5 fs-8'>
              <div className='col-lg-5'>
                <span className='small text-uppercase fw-bolder text-gray-600'>UDPC</span>
                <span className='small text-gray-600'> - Unsorted during a phone call</span>
              </div>
              <div className='col-lg-5'>
                <span className='small text-uppercase fw-bolder text-gray-600'>U/C/CL</span>
                <span className='small text-gray-600'> - Unsorted/Contact/Contact+Lead</span>
              </div>
              <div className='col-lg-5'>
                <span className='small text-uppercase fw-bolder text-gray-600'>RSM</span>
                <span className='small text-gray-600'> - Repeat sales mode</span>
              </div>
              <div className='col-lg-5'>
                <span className='small text-uppercase fw-bolder text-gray-600'>FU</span>
                <span className='small text-gray-600'> - Find in unsorted</span>
              </div>
            </div>
          )}
          {app?.crm === 'BITRIX24' && (
            <div className='text-align-start row mb-5 mt-5 fs-8'>
              <div className='col-lg-12'>
                <span className='small text-uppercase fw-bolder text-gray-600'>manager time</span>
                <span className='small text-gray-600'> - manager time in bitrix24</span>
              </div>
            </div>
          )}
        </div>
        <div className='card-body px-1'>
          <div className='table-responsive'>
            {app?.crm === 'AMOCRM' && <LinesAmoCRM />}
            {app?.crm === 'BITRIX24' && <LinesBitrix />}
          </div>
        </div>
      </div>
    </>
  )
}

export {AppLines}
