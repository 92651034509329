import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {IAuthModel} from '../types'

const initialAuthState: IAuthState = {
  accessToken: undefined,
  authLoading: {
    login: false,
    refresh_token: false,
  },
  error: {
    login: null,
    refresh_token: null,
  },
}

export interface IAuthState {
  accessToken?: string
  authLoading: {
    login: boolean
    refresh_token: boolean
  }
  error: {
    login: string | null
    refresh_token: string | null
  }
}

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    loginAction: (state, action: PayloadAction<IAuthModel>) => {
      state.accessToken = action.payload?.access_token
    },
    logoutAction: (state) => {
      state.accessToken = undefined
    },
  },
})

export const {loginAction, logoutAction} = authSlice.actions
export default authSlice.reducer
