import {useState} from 'react'
import {useParams} from 'react-router-dom'
import {appsAPI} from '../../../../services/AppsService'
import {KTSVG} from '../../../../template/helpers'
import {TimeZone} from './TimeZone'
import * as Yup from 'yup'
import {IRegionSettingsCreate, RegionSettingsInitValues} from '../../core/_models'
import {useFormik} from 'formik'
import {IAppsModel} from '../../../../types/apps/AppsModel'
import {TimeZoneOptions} from '../../../../template/helpers/components/TimeZoneOptions'

const RigionEditShema = Yup.object().shape({
  region: Yup.string().required('Region is required'),
  time_zone: Yup.string().required('Time zone is required'),
  is_auto_mode: Yup.boolean(),
})

const Region = () => {
  const {appId} = useParams()
  const {data: app} = appsAPI.useFetchAppByIdQuery(appId!)
  const [updateApp, {isLoading}] = appsAPI.useUpdateAppMutation()
  const [regionState, updateRegionState] = useState(false)

  const formik = useFormik<IRegionSettingsCreate>({
    initialValues: Object.assign(RegionSettingsInitValues, app),
    validationSchema: RigionEditShema,
    onSubmit: async (values) => {
      const data = {
        id: appId,
        region: values.region,
        time_zone: values.time_zone,
        is_auto_mode: values.is_auto_mode,
      }
      await updateApp(data as IAppsModel)
      updateRegionState(false)
    },
  })

  return (
    <>
      {regionState === false && (
        <div className='card card-custom card-stretch shadow-sm mb-5'>
          <div className='card-header'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Region</h3>
            </div>
            <button className='btn btn-sm btn-primary align-self-center' onClick={() => updateRegionState(true)}>
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-info me-1 align-self-center' />
              Edit
            </button>
          </div>
          <div className='card-body pt-5 pb-5'>
            <div className='row mb-5'>
              <label className='col-lg-4 fw-bolder text-gray-600 text-uppercase small'>Region</label>

              <div className='col-lg-8'>
                {app?.is_auto_mode === true && <span className='badge badge-light-danger small me-2'>A</span>}
                <span className='badge badge-light-info'>{app?.region}</span>
              </div>
            </div>
            <TimeZone />
          </div>
        </div>
      )}
      {regionState === true && (
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card card-custom card-stretch shadow-sm mb-5'>
            <div className='card-header'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Region</h3>
              </div>
            </div>
            <div className='card-body pt-5 pb-5'>
              <div className='row mb-5'>
                <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>Region</span>
                </label>

                <div className='col-lg-8'>
                  <select
                    className='form-select form-select-solid form-select-lg text-gray-900 align-self-center'
                    {...formik.getFieldProps('region')}
                  >
                    <option value='BY'>Belarus</option>
                    <option value='RU'>Russia</option>
                    <option value='DE'>Germany</option>
                  </select>
                </div>
              </div>
              <div className='row mb-5'>
                <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>Auto Mode</span>
                </label>

                <div className='col-lg-8 d-flex align-items-center'>
                  <div className='form-check form-switch form-check-custom form-check-solid me-10'>
                    <input
                      className='form-check-input h-20px w-30px align-self-center'
                      type='checkbox'
                      id='autoModeSwitch'
                      defaultChecked={app?.is_auto_mode === true ? true : false}
                      {...formik.getFieldProps('is_auto_mode')}
                    />
                  </div>
                </div>
              </div>
              <div className='row mb-5'>
                <label className='col-lg-4 fw-bolder text-gray-600 text-uppercase small align-self-center'>
                  Time Zone
                </label>
                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg text-gray-900'
                    {...formik.getFieldProps('time_zone')}
                  >
                    <TimeZoneOptions />
                  </select>
                </div>
              </div>
              <div className='card-footer d-flex justify-content-end py-2 px-1' style={{border: 'none'}}>
                <button type='submit' className='btn btn-sm btn-primary me-3' disabled={isLoading}>
                  {!isLoading && 'Save Changes'}
                  {isLoading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
                <button
                  className='btn btn-sm btn-light-primary align-self-center'
                  onClick={() => {
                    updateRegionState(false)
                    formik.resetForm()
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  )
}

export {Region}
