import {FC, ReactNode} from 'react'
import {IAppsModel} from '../../../../../../types/apps/AppsModel'
import {AppItemHeaderControl} from '../../../item/header/AppItemHeaderControl'
import {AppStatusById} from '../../../../../../template/helpers/components/AppStatus'

type Props = {
  app: IAppsModel
}

type WithChildren = {
  children?: ReactNode
}

const PanelAppItemHeaderWrapper: FC<Props & WithChildren> = ({app, children}) => {
  return (
    <div className='flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10'>
      <div className='flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10'>
        <div className='card mb-5 mb-xl-8'>
          <div className='card-body shadow-sm'>
            <div className='d-flex flex-stack flex-wrap'>
              <AppStatusById app_id={app.id} isActive={app.licenses.is_active} />
              <AppItemHeaderControl app={app} />
            </div>
            <div className='d-flex flex-column'>{children}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {PanelAppItemHeaderWrapper}
