/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useMemo, useState} from 'react'
import {usersAPI} from '../../../../../../services/UsersService'
import {KTSVG} from '../../../../../../template/helpers'
import {formatDateByZone} from '../../../../../../template/helpers/FormatDateZoneHelpers'
import {IAppsModel} from '../../../../../../types/apps/AppsModel'
import {clipboard} from '../../../../../../template/helpers/Clipboard'
import {Link} from 'react-router-dom'

type Props = {
  app: IAppsModel
}

const PanelAppItemHeaderData: FC<Props> = ({app}) => {
  const [buffer, setBuffer] = useState(false)
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()

  const copyBufer = (copyData: string) => {
    setBuffer(true)
    clipboard(copyData)
    setTimeout(() => {
      setBuffer(false)
    }, 600)
  }

  function dateTime(date: string) {
    return formatDateByZone(date, currentUser?.language || 'en', currentUser?.time_zone || 'UTC')
  }

  const activeUsersCount = () => {
    let users_number = 0
    if (app.crm === 'BITRIX24') {
      if (app.settings.bitrix24.users_in_service === true) {
        for (let i = 0; i < app.settings.bitrix24.users_in_service_list.length; i++) {
          if (app.settings.bitrix24.users_in_service_list[i].is_active) users_number += 1
        }
      } else {
        for (let i = 0; i < app.settings.bitrix24.users_list.length; i++) {
          if (app.settings.bitrix24.users_list[i].internal_number !== '') users_number += 1
        }
      }
    } else {
      users_number = app.settings.amocrm.users.length
    }

    return users_number
  }

  const totalUsersCount = () => {
    if (app.crm === 'BITRIX24') {
      if (app.settings.bitrix24.users_in_service === true) {
        return app.settings.bitrix24.users_in_service_list.length
      } else {
        return app.settings.bitrix24.users_list.length
      }
    }
    return app.settings.amocrm.users.length
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const activeUsers = useMemo(() => activeUsersCount(), [app])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const totalUsers = useMemo(() => totalUsersCount(), [app])

  return (
    <>
      <div className='pb-5'>
        <div className='separator'></div>
        <div className='flex-equal'>
          <div className='d-flex fw-bolder text-gray-600 mt-5 mb-1 small text-uppercase'>Integration ID</div>
          <div className='d-flex flex-stack'>
            <div className='d-flex'>
              <span className={clsx('badge', {'badge-light-info': !buffer}, {'badge-light-success': buffer})}>
                {app?.id}
              </span>
            </div>
            <div className='d-flex text-end'>
              <button
                onClick={() => copyBufer(app?.id!)}
                className={clsx('btn btn-link text-gray-900 pt-0 pb-0', {'text-hover-primary': !buffer})}
              >
                {!buffer ? (
                  <KTSVG path='/media/icons/duotune/general/gen054.svg' className='svg-icon-gray-900 svg-icon m-0' />
                ) : (
                  <KTSVG path='/media/icons/duotune/arrows/arr012.svg' className='svg-icon-success m-0' />
                )}
              </button>
            </div>
          </div>

          <div className='d-flex fw-bolder text-gray-600 mt-5 mb-1 small text-uppercase'>CRM Domain</div>
          <div className='d-flex flex-stack'>
            <div className='d-flex col-8 fw-bolder text-gray-800 me-2'>{app?.crm_domain}</div>
            <a href={'https://' + app?.crm_domain} target='_blank' rel='noreferrer' className='ms-1 col-2 text-end'>
              <KTSVG
                path='/media/icons/duotune/arrows/arr095.svg'
                className='svg-icon svg-icon-gray-900 text-hover-primary'
              />
            </a>
          </div>
          <div className='d-flex flex-stack mt-5 mb-1 text-uppercase'>
            <div className='d-flex fw-bolder text-gray-600 small'>CRM / PBX</div>
            <div className='d-flex text-right align-items-center'>
              <div className='badge badge-light-primary me-2'>{app.crm}</div>
              <KTSVG path='/media/icons/duotune/abstract/abs022.svg' className='svg-icon-gray-600 svg-icon-1x' />
              <div className='badge badge-light-warning ms-2'>{app.pbx}</div>
            </div>
          </div>
          <div className='d-flex flex-stack mt-5 mb-1 text-uppercase'>
            <div className='d-flex fw-bolder text-gray-600 small '>Version</div>
            <span className='d-flex text-right badge badge-light-info'>{app.version}</span>
          </div>
          <div className='d-flex flex-stack mt-5 mb-1 text-uppercase'>
            <div className='d-flex fw-bolder text-gray-600 small text-uppercase'>Region</div>
            <div className='d-flex text-right'>
              {app.is_auto_mode && <span className='badge badge-light-danger small me-2'>A</span>}
              <span className='badge badge-light-info'>{app.region}</span>
            </div>
          </div>
          <div className='d-flex flex-stack mt-5 mb-1'>
            <div className='d-flex fw-bolder text-gray-600 small text-uppercase'>Created</div>
            <div className='fw-bolder text-gray-800'>{dateTime(app.created_on)}</div>
          </div>
        </div>
        <div className='separator mb-5 mt-5'></div>

        <div className='flex-equal'>
          {/*<div className='text-muted mb-3'>License Info</div>*/}
          <div className='d-flex flex-stack mt-5 mb-1 text-uppercase'>
            <div className='d-flex fw-bolder text-gray-600 small text-uppercase'>License</div>
            {app.licenses.is_commercial ? (
              <div className='d-flex text-right badge badge-success'>Commercial </div>
            ) : (
              <div className='d-flex text-right badge badge-warning'>Trial </div>
            )}
          </div>
          <div className='d-flex flex-stack mt-5 mb-1 text-uppercase'>
            <div className='d-flex fw-bolder text-gray-600 small text-uppercase'>License Status</div>
            {app.licenses.is_active ? (
              <div className='d-flex text-right badge badge-success'>ACTIVE</div>
            ) : (
              <div className='d-flex text-right badge badge-danger'>EXPIRED</div>
            )}
          </div>
          <div className='d-flex flex-stack mt-5 mb-1 text-uppercase'>
            <div className='d-flex fw-bolder text-gray-600 small text-uppercase'>License Users</div>
            <div className='d-flex text-right fw-bolder text-gray-800'>{app.licenses.maxusers}</div>
          </div>
          <div className='d-flex flex-stack mt-5 mb-1 text-uppercase'>
            {activeUsers > app.licenses.maxusers ? (
              <>
                <div className='d-flex fw-bolder text-danger small text-uppercase'>
                  Active Users (more License Users)
                </div>
                <div className='d-flex text-right fw-bolder text-danger'>{activeUsers}</div>
              </>
            ) : (
              <>
                <div className='d-flex fw-bolder text-gray-600 small text-uppercase'>Active Users</div>
                <div className='d-flex text-right fw-bolder text-gray-800'>{activeUsers}</div>
              </>
            )}
          </div>
          <div className='d-flex flex-stack mt-5 mb-1 text-uppercase'>
            <div className='d-flex fw-bolder text-gray-600 small text-uppercase'>Total Users</div>
            <div className='d-flex text-right fw-bolder text-gray-800'>{totalUsers}</div>
          </div>
          <div className='d-flex flex-stack mt-5 mb-1'>
            <div
              className={clsx(
                'd-flex fw-bolder small text-uppercase',
                {'text-gray-600': app.licenses.is_active},
                {'text-danger': !app.licenses.is_active}
              )}
            >
              License End
            </div>
            <div
              className={clsx(
                'd-flex text-right fw-bolder',
                {'text-gray-800': app.licenses.is_active},
                {'text-danger': !app.licenses.is_active}
              )}
            >
              {dateTime(app.licenses.license_end)}
            </div>
          </div>
        </div>

        {currentUser?.role !== 'CLIENT' && (
          <>
            <div className='separator mb-5 mt-5'></div>
            <div className='flex-equal'>
              <div className='d-flex fw-bolder text-gray-600 mt-5 mb-1 small text-uppercase'>owner info</div>
              <div className='d-flex flex-stack'>
                <div className='d-flex col-8 text-gray-800 me-2'>{app?.user.fullname}</div>
                <Link to={`/management/users/${app.user.id}/`} target='_blank'>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr095.svg'
                    className='svg-icon svg-icon-gray-900 text-hover-primary'
                  />
                </Link>
              </div>
              <div className='d-flex flex-stack'>
                <div className='d-flex col-8 text-gray-800'>{app?.user.company}</div>
              </div>
              <div className='d-flex flex-stack'>
                <div className='d-flex col-8 text-gray-800'>{app?.user.email}</div>
              </div>
              <div className='d-flex flex-stack'>
                <div className='d-flex col-8 text-gray-800'>{app?.user.phone}</div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export {PanelAppItemHeaderData}
