import {useParams} from 'react-router-dom'
import {appsAPI} from '../../../../../../services/AppsService'
import {AmoCRMLineHeader} from './AmoCRMLineHeader'
import {OneLineAmoCRM} from './OneLineAmoCRM'

const LinesAmoCRM = () => {
  const {appId} = useParams()
  const {data: app} = appsAPI.useFetchAppByIdQuery(appId!)
  const lines = app?.settings.amocrm.lines
  const other_lines: any[] = []
  const main_line: any[] = []

  if (lines) {
    for (let i = 0; i < lines?.length; i++) {
      if (lines[i]?.line === '') {
        main_line.push(lines[i])
      } else {
        other_lines.push(lines[i])
      }
    }
  }

  return (
    <table className='table align-middle table-row-dashed fs-7 gy-5 dataTable no-footer'>
      <AmoCRMLineHeader />
      <tbody>
        {main_line?.map((l) => (
          <OneLineAmoCRM line={l} key={l.id} />
        ))}
        {other_lines?.map((l) => (
          <OneLineAmoCRM line={l} key={l.id} />
        ))}
      </tbody>
    </table>
  )
}

export {LinesAmoCRM}
