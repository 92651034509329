import {useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {authAPI} from '../../../services/AuthService'

export function Activate() {
  const {activate_token} = useParams()
  const navigate = useNavigate()
  const [lazyActivateUser] = authAPI.useLazyActivateUserQuery()

  useEffect(() => {
    const activate = async () => {
      await lazyActivateUser({token: activate_token as string})
    }

    activate()
    navigate('/login', {replace: true})
  }, [activate_token, lazyActivateUser, navigate])

  return <></>
}
