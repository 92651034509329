/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {appsAPI} from '../../../../../services/AppsService'
import {KTSVG} from '../../../../../template/helpers'
import {IAppsModel} from '../../../../../types/apps/AppsModel'

type Props = {
  app: IAppsModel
}

const REACT_APP_STATUS_TIMEOUT = process.env.REACT_APP_STATUS_TIMEOUT

const AppItemHeaderControl: FC<Props> = ({app}) => {
  const {appId} = useParams()
  const [pollingInterval, setPollingInterval] = useState<number>(0)
  const [skip, setSkip] = useState<boolean>(true)
  const {
    data: appStatus,
    isFetching: appStatusFetching,
    isLoading: appStatusLoading,
    refetch,
  } = appsAPI.useFetchAppStatusQuery(app.id, {
    skip: skip,
    pollingInterval: pollingInterval,
  })
  const [actionApp, {isLoading: isLoadingAction}] = appsAPI.useActionAppMutation()
  const sendActionApp = (id: string, action: 'START' | 'RESTART' | 'STOP') => {
    actionApp({id: id, action: action})
      .unwrap()
      .then(() => {
        if (action === 'RESTART') {
          setTimeout(() => {
            refetch()
          }, 3000)
        } else {
          refetch()
        }
      })
  }

  useEffect(() => {
    if (app.licenses.is_active) {
      setPollingInterval(parseInt(REACT_APP_STATUS_TIMEOUT as string))
      setSkip(false)
    }
  }, [app])

  return (
    <div className='d-flex my-3 ms-9'>
      <button
        className={clsx(
          'btn btn-icon w-30px h-30px me-1',
          {'btn-active-light-success': appStatus?.is_running},
          {'btn-light-primary': !appStatus?.is_running}
        )}
        disabled={
          appStatusLoading || appStatusFetching || appStatus?.is_running || isLoadingAction || !app.licenses.is_active
        }
        onClick={() => sendActionApp(app.id, 'START')}
      >
        <i className='fas fa-play-circle fs-4'></i>
      </button>
      <button
        className={clsx(
          'btn btn-icon w-30px h-30px me-1',
          {'btn-active-light-primary': !appStatus?.is_running},
          {'btn-light-primary': appStatus?.is_running}
        )}
        disabled={
          appStatusLoading || appStatusFetching || !appStatus?.is_running || isLoadingAction || !app.licenses.is_active
        }
        onClick={() => sendActionApp(app.id, 'RESTART')}
      >
        <i className='fas fa-redo-alt fs-5'></i>
      </button>
      <button
        className={clsx(
          'btn btn-icon w-30px h-30px me-3',
          {'btn-active-light-danger': !appStatus?.is_running},
          {'btn-light-danger': appStatus?.is_running}
        )}
        disabled={appStatusLoading || appStatusFetching || !appStatus?.is_running || isLoadingAction}
        onClick={() => sendActionApp(app.id, 'STOP')}
      >
        <i className='fas fa-stop-circle fs-4'></i>
      </button>
      {!appId && (
        <Link to={`${app.id}/`} className='btn btn-icon btn-light-primary w-30px h-30px me-1'>
          <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-3' />
        </Link>
      )}
    </div>
  )
}

export {AppItemHeaderControl}
