import React, {FC} from 'react'
import {IAppsModel} from '../../../../types/apps/AppsModel'
import {PanelAppItemHeaderData} from './item/header/PanelAppItemHeaderData'
import {PanelAppItemHeaderWrapper} from './item/header/PanelAppItemHeaderWrapper'

type Props = {
  app: IAppsModel
  firstElement: boolean
}

const PanelAppItem: FC<Props> = ({app, firstElement}) => {
  return (
    <>
      <PanelAppItemHeaderWrapper app={app}>
        <PanelAppItemHeaderData app={app} />
      </PanelAppItemHeaderWrapper>
    </>
  )
}

export {PanelAppItem}
