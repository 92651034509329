import {FC} from 'react'
import {Navigate, Route, Routes, useParams} from 'react-router-dom'
import {PageLink, PageTitle} from '../../template/layout/core'
import {AppsHeader} from './AppsHeader'
import {AppInfo} from './components/AppInfo'
import {AppLines} from './components/AppLines'
import {AmoUsers} from './components/crm/amo/users/AmoUsers'
import {BitrixUsers} from './components/crm/bitrix/BitrixUsers'
import {appsAPI} from '../../services/AppsService'
import {Logs} from './components/logs/Logs'
import {AddLineBitrix24} from '../../template/partials/modals/add-line/ModalAddBitrix24Line'
import {BitrixAllUsers} from './components/crm/bitrix/users/BitrixAllUsers'
import {AddLineAmoCRM} from '../../template/partials/modals/add-line/ModalAddAmocrmLine'
import {License} from './components/license/License'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Integration',
    path: 'Info',
    isSeparator: false,
    isActive: false,
  },
]

const AppsPage: FC = () => {
  const {appId} = useParams()
  const {data: app} = appsAPI.useFetchAppByIdQuery(appId!)

  return (
    <Routes>
      <Route
        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Info</PageTitle>
            <AppsHeader />
          </>
        }
      >
        <Route
          index
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Info</PageTitle>
              <AppInfo />
            </>
          }
        />
        <Route
          path='lines/'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Lines</PageTitle>
              <AppLines />
              {app?.crm === 'BITRIX24' ? <AddLineBitrix24 /> : <AddLineAmoCRM />}
            </>
          }
        />
        <Route
          path='users/'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Users</PageTitle>
              {app?.crm === 'BITRIX24' && app.settings.bitrix24.users_in_service === true && <BitrixUsers />}
              {app?.crm === 'BITRIX24' && app.settings.bitrix24.users_in_service === false && <BitrixAllUsers />}
              {app?.crm === 'AMOCRM' && <AmoUsers />}
            </>
          }
        />
        <Route
          path='license/'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>License</PageTitle>
              <License />
            </>
          }
        />
        <Route
          path='logs/'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Real Time Logs</PageTitle>
              <Logs />
            </>
          }
        />
      </Route>
      <Route path='*' element={<Navigate to='/error/404' />} />
    </Routes>
  )
}

export default AppsPage
