/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../template/layout/core'
import {ListAppPage} from './components/ListAppPage'

const AppsWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.APPS'})}</PageTitle>
      <ListAppPage />
    </>
  )
}

export {AppsWrapper}
