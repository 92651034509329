import React, {FC} from 'react'
import {IAppsModel} from '../../../../types/apps/AppsModel'
import {AppItemBody} from './AppItemBody'
import {AppItemHeaderCollapse} from './header/AppItemHeaderCollapse'
import {AppItemHeaderData} from './header/AppItemHeaderData'
import {AppItemHeaderWrapper} from './header/AppItemHeaderWrapper'

type Props = {
  app: IAppsModel
  firstElement: boolean
}

const AppItem: FC<Props> = ({app, firstElement}) => {
  return (
    <>
      <AppItemHeaderWrapper app={app}>
        <AppItemHeaderCollapse app={app} firstElement={firstElement} />
        <AppItemHeaderData app={app} />
      </AppItemHeaderWrapper>
      <AppItemBody app={app} firstElement={firstElement} />
    </>
  )
}

export {AppItem}
