import {FC, useState, useEffect, useRef, ReactNode} from 'react'
import {LayoutSplashScreen} from '../../../template/layout/core'
import {usersAPI} from '../../../services/UsersService'

type WithChildren = {
  children?: ReactNode
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const [currentUser] = usersAPI.useLazyFetchGetMeQuery()

  useEffect(() => {
    const requestUser = async () => {
      if (!didRequest.current) {
        await currentUser()
        setShowSplashScreen(false)
      }
      return () => (didRequest.current = true)
    }

    requestUser()
    // eslint-disable-next-line
  }, [])
  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthInit}
