import clsx from 'clsx'
import React, {FC, useMemo} from 'react'
import {usersAPI} from '../../../../services/UsersService'
import {IAppsModel} from '../../../../types/apps/AppsModel'
import {formatDateByZone} from '../../../../template/helpers/FormatDateZoneHelpers'

type Props = {
  app: IAppsModel
  firstElement: boolean
}

const AppItemBody: FC<Props> = ({app, firstElement}) => {
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()

  function dateTime(date: string) {
    return formatDateByZone(date, currentUser?.language || 'en', currentUser?.time_zone || 'UTC')
  }

  const activeUsersCount = () => {
    let users_number = 0
    if (app.crm === 'BITRIX24') {
      if (app.settings.bitrix24.users_in_service === true) {
        for (let i = 0; i < app.settings.bitrix24.users_in_service_list.length; i++) {
          if (app.settings.bitrix24.users_in_service_list[i].is_active) users_number += 1
        }
      } else {
        for (let i = 0; i < app.settings.bitrix24.users_list.length; i++) {
          if (app.settings.bitrix24.users_list[i].internal_number !== '') users_number += 1
        }
      }
    } else {
      users_number = app.settings.amocrm.users.length
    }

    return users_number
  }

  const totalUsersCount = () => {
    if (app.crm === 'BITRIX24') {
      if (app.settings.bitrix24.users_in_service === true) {
        return app.settings.bitrix24.users_in_service_list.length
      } else {
        return app.settings.bitrix24.users_list.length
      }
    }
    return app.settings.amocrm.users.length
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const activeUsers = useMemo(() => activeUsersCount(), [app])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const totalUsers = useMemo(() => totalUsersCount(), [app])

  return (
    <div id={`cb_app_item_${app.id}`} className={clsx('fs-7 ps-12 collapse', {show: firstElement})}>
      <div className='d-flex flex-wrap py-5 ps-12'>
        <div className='flex-equal me-1'>
          <span className='fw-bolder text-gray-600 text-uppercase small'>Integration Info</span>
          <table className='table table-flush fw-bold gy-1 mt-4'>
            <tbody>
              <tr>
                <td className='fw-bolder text-gray-800 min-w-125px w-125px'>CRM</td>
                <td className='fw-bolder text-gray-900'>
                  <span className='badge badge-light-primary'>
                    {app.crm} / {app.crm_domain}
                  </span>
                </td>
              </tr>
              <tr>
                <td className='fw-bolder text-gray-800 min-w-125px w-125px'>PBX</td>
                <td>
                  <span className='badge badge-light-warning me-2'>{app.pbx}</span>
                </td>
              </tr>
              <tr>
                <td className='fw-bolder text-gray-800 min-w-125px w-125px'>Version</td>
                <td>
                  <span className='badge badge-light-info'>{app.version}</span>
                </td>
              </tr>
              <tr>
                <td className='fw-bolder text-gray-800 min-w-125px w-125px'>Region</td>
                <td>
                  {app.is_auto_mode && <span className='badge badge-light-danger me-2'>A</span>}
                  <span className='badge badge-light-info me-2'>{app.region}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='flex-equal'>
          <span className='fw-bolder text-gray-600 text-uppercase small'>License Info</span>
          <table className='table table-flush fw-bold gy-1 mt-4'>
            <tbody>
              <tr>
                <td className='fw-bolder text-gray-800 min-w-125px w-125px'>License</td>
                <td>
                  {app.licenses.is_commercial ? (
                    <span className='badge badge-success'>COMMERCIAL</span>
                  ) : (
                    <span className='badge badge-warning me-2'>TRIAL</span>
                  )}
                </td>
              </tr>
              <tr>
                <td className='fw-bolder text-gray-800 min-w-125px w-125px'>Status</td>
                <td>
                  {app.licenses.is_active ? (
                    <span className='badge badge-success me-2'>ACTIVE</span>
                  ) : (
                    <span className='badge badge-danger'>EXPIRED</span>
                  )}
                </td>
              </tr>
              <tr>
                <td className='fw-bolder text-gray-800 min-w-125px w-125px'>Users</td>
                <td className='fw-bolder text-gray-900'>
                  <span className={clsx({'text-danger': activeUsers > app.licenses.maxusers})}>
                    active {activeUsers}
                  </span>{' '}
                  / license {app.licenses.maxusers} / total {totalUsers}
                </td>
              </tr>
              <tr>
                <td
                  className={clsx(
                    'fw-bolder min-w-125px w-125px',
                    {'text-gray-800': app.licenses.is_active},
                    {'text-danger': !app.licenses.is_active}
                  )}
                >
                  License End
                </td>
                <td
                  className={clsx(
                    'fw-bolder',
                    {'text-gray-900': app.licenses.is_active},
                    {'text-danger': !app.licenses.is_active}
                  )}
                >
                  {dateTime(app.licenses.license_end)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export {AppItemBody}
