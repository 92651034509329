import {FC, useEffect} from 'react'

const LayoutSplashScreen: FC<{visible?: boolean}> = ({visible = true}) => {
  useEffect(() => {
    if (!visible) {
      return
    }
  }, [visible])

  return null
}

export {LayoutSplashScreen}
