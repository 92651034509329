import {useParams} from 'react-router-dom'
import {FC, useState} from 'react'
import {appsAPI} from '../../../../../../services/AppsService'
import {KTSVG} from '../../../../../../template/helpers'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {IAmoCRMLineUpdate, AmoCRMLineUpdateInitValues} from '../../../../core/_models'
import {IAppsModel} from '../../../../../../types/apps/AppsModel'
import {IAppAmoCRMLines} from '../../../../../../template/partials/modals/create-app/IAppModels'
import clsx from 'clsx'

type Props = {
  line: IAppAmoCRMLines
}

const amoCRMLineEditShema = Yup.object().shape({
  line: Yup.string().required('Line is required'),
  description: Yup.string(),
  pipeline: Yup.string(),
  responsible_user: Yup.string().required('User is required'),
  smart_call: Yup.boolean(),
  start_time: Yup.string(),
  end_time: Yup.string(),
  incoming_call: Yup.string(),
  udpc: Yup.boolean(),
  rsm: Yup.boolean(),
  find_unsorted: Yup.boolean(),
})

const OneLineAmoCRM: FC<Props> = ({line}) => {
  const {appId} = useParams()
  const {data: app} = appsAPI.useFetchAppByIdQuery(appId!)
  const [lineState, updateLineState] = useState(false)
  const [smartCallState, updateSmartCallState] = useState(line.smart_call)
  const [updateApp, {isLoading}] = appsAPI.useUpdateAppMutation()
  const all_lines = app?.settings.amocrm.lines
  const pipelines: any = app?.settings.amocrm.pipelines
  let pipeline = line.pipeline
  const users: any = app?.settings.amocrm.users
  let user: any = line.responsible_user

  if (pipelines.length !== 0) {
    let check_pipeline = false
    for (let i = 0; i < pipelines.length; i++) {
      if (pipeline === pipelines[i].name) {
        check_pipeline = true
        break
      }
      if (check_pipeline === false) {
        pipeline = 'Воронка'
      }
    }
  }

  if (users.length !== 0) {
    for (let i = 0; i < users.length; i++) {
      if (user === users[i].amocrm_user_id) {
        user = users[i]
        break
      }
    }
  }

  const other_lines: any[] = []
  if (all_lines) {
    for (let i = 0; i < all_lines.length; i++) {
      if (all_lines[i]?.id !== line.id) {
        other_lines.push(all_lines[i])
      }
    }
  }
  if (other_lines) {
    for (let i = 0; i < other_lines.length; i++) {
      if (other_lines[i].groups) {
        delete other_lines[i]['groups']
      }
    }
  }

  const formik = useFormik<IAmoCRMLineUpdate>({
    initialValues: Object.assign(AmoCRMLineUpdateInitValues, {
      id: line.id,
      line: line.line === '' ? 'All lines' : line.line,
      description: line.line === '' ? 'All lines' : line.description,
      pipeline: pipeline,
      responsible_user: users.length !== 0 ? user.amocrm_user_id : line.responsible_user,
      smart_call: line.smart_call,
      start_time: line.start_time.slice(0, 5),
      end_time: line.end_time.slice(0, 5),
      incoming_call: line.incoming_call_mode.none
        ? 'None'
        : line.incoming_call_mode.contact
        ? 'Contact'
        : line.incoming_call_mode.contact_lead
        ? 'ContactLead'
        : 'Unsorted',
      udpc: line.udpc,
      rsm: line.repeat_sales_mode,
      find_unsorted: line.find_unsorted,
    }),
    validationSchema: amoCRMLineEditShema,

    onSubmit: async (values) => {
      const edit_line: IAppAmoCRMLines = {
        id: line.id,
        line: values.line === 'All lines' ? '' : values.line,
        description: values.description === 'All lines' ? '' : values.description,
        pipeline: values.pipeline,
        responsible_user: values.responsible_user,
        smart_call: values.smart_call,
        start_time: values.start_time,
        end_time: values.end_time,
        incoming_call_mode: {
          none: values.incoming_call === 'None' ? true : false,
          contact: values.incoming_call === 'Contact' ? true : false,
          contact_lead: values.incoming_call === 'ContactLead' ? true : false,
          unsorted: values.incoming_call === 'Unsorted' ? true : false,
        },
        udpc: values.udpc,
        repeat_sales_mode: values.rsm,
        find_unsorted: values.find_unsorted,
      }
      other_lines.push(edit_line)
      const data = {
        id: appId,
        settings: {
          amocrm: {
            lines: other_lines,
          },
        },
      }
      await updateApp(data as IAppsModel)
      updateLineState(false)
    },
  })

  const deleteLine = () => {
    const data = {
      id: appId,
      settings: {
        amocrm: {
          lines: other_lines,
        },
      },
    }
    updateApp(data as IAppsModel)
    updateLineState(false)
  }

  return (
    <>
      {lineState === false && (
        <tr role='row' className='text-gray-900 text-center'>
          <td role='cell' className='min-w-150px pe-0'>
            <input
              className='form-control form-control-sm form-control-flush text-center'
              value={line.line === '' ? 'All lines' : line.line}
              readOnly
            />
          </td>
          <td role='cell' className='min-w-140px pe-0 px-0'>
            <input
              className='form-control form-control-sm form-control-flush text-gray-900 text-center'
              value={line.line === '' ? 'All lines' : line.description}
              disabled
              readOnly
            />
          </td>
          <td role='cell' className='min-w-100px pe-0 px-0'>
            <select className='form-control form-control-sm form-control-flush text-center text-gray-900' disabled>
              <option value={line.pipeline}>{line.pipeline}</option>
            </select>
          </td>
          <td role='cell' className='min-w-200px pe-0 px-0'>
            <select
              className='form-control form-control-sm form-control-flush text-center text-gray-900'
              disabled={users.length !== 0 ? false : true}
            >
              {users.length !== 0 ? (
                <optgroup label={user.fullname}>
                  <option className='d-none' value={user.amocrm_user_id}>
                    {user.amocrm_user_id}
                  </option>
                </optgroup>
              ) : (
                <option value={user}>{user}</option>
              )}
            </select>
          </td>
          <td role='cell' className='min-w-85px pe-0 px-0'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-block'>
              <input
                className='form-check-input'
                type='checkbox'
                id={'smCall' + line.line}
                checked={line.smart_call}
                readOnly
                disabled
              />
              <label className='form-check-label' htmlFor={'smCall' + line.line}></label>
            </div>
          </td>
          <td role='cell' className='min-w-150px d-flex pe-0 px-0'>
            <input
              className='form-control form-control-sm form-control-flush text-center'
              value={line.start_time.slice(0, 5)}
              readOnly
              disabled
            />
            <label className='align-self-center text-center'>—</label>
            <input
              className='form-control form-control-sm form-control-flush text-center'
              value={line.end_time.slice(0, 5)}
              readOnly
              disabled
            />
          </td>
          <td role='cell' className='min-w-100px pe-0 px-0'>
            <select
              className='badge badge-light-primary form-check-input min-w-100px'
              value={
                line.incoming_call_mode.none
                  ? 'None'
                  : line.incoming_call_mode.contact
                  ? 'Contact'
                  : line.incoming_call_mode.contact_lead
                  ? 'ContactLead'
                  : 'Unsorted'
              }
              disabled
            >
              <option value='None'>None</option>
              <option value='Unsorted'>Unsorted</option>
              <option value='Contact'>Contact</option>
              <option value='ContactLead'>Contact + Lead</option>
            </select>
          </td>
          <td role='cell' className='min-w-85px pe-0 px-0'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-block'>
              <input
                className='form-check-input'
                type='checkbox'
                id={'udpc' + line.line}
                checked={line.udpc}
                readOnly
                disabled
              />
              <label className='form-check-label' htmlFor={'udpc' + line.line}></label>
            </div>
          </td>
          <td role='cell' className='min-w-85px pe-0 px-0'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-block'>
              <input
                className='form-check-input'
                type='checkbox'
                id={'rsm' + line.line}
                checked={line.repeat_sales_mode}
                readOnly
                disabled
              />
              <label className='form-check-label' htmlFor={'rsm' + line.line}></label>
            </div>
          </td>
          <td role='cell' className='min-w-85px pe-0 px-0'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-block'>
              <input
                className='form-check-input'
                type='checkbox'
                id={'find_unsorted' + line.line}
                checked={line.find_unsorted}
                readOnly
                disabled
              />
              <label className='form-check-label' htmlFor={'find_unsorted' + line.line}></label>
            </div>
          </td>

          <td role='cell' className='min-w-150px pe-0 px-0'>
            <button
              type='button'
              onClick={() => {
                updateLineState(true)
              }}
              className='btn btn-icon btn-light-primary btn-sm me-1 align-self-center'
            >
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='align-self-center' />
            </button>
          </td>
        </tr>
      )}
      {lineState === true && (
        <tr role='row' className='text-center'>
          <td className='d-none'>
            <form
              onSubmit={formik.handleSubmit}
              noValidate
              method='GET'
              id={'Line_form' + line?.id}
              className='form'
            ></form>
            <input type='hidden' {...formik.getFieldProps('id')} />
          </td>
          <td role='cell' className='min-w-150px px-0'>
            <input
              className='form-control form-control-sm form-control-flush text-center'
              disabled={line.line === '' ? true : false}
              form={'Line_form' + line?.id}
              {...formik.getFieldProps('line')}
            />
            {formik.touched.line && formik.errors.line && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block text-danger'>{formik.errors.line}</div>
              </div>
            )}
          </td>
          <td role='cell' className='min-w-140px pe-0 px-0'>
            <input
              className='form-control form-control-sm form-control-flush text-center'
              disabled={line.line === '' ? true : false}
              form={'Line_form' + line?.id}
              {...formik.getFieldProps('description')}
            />
          </td>
          <td role='cell' className='min-w-100px pe-0 px-0'>
            <select
              className='form-control form-control-sm form-control-flush text-center text-gray-900'
              {...formik.getFieldProps('pipeline')}
              form={'Line_form' + line?.id}
            >
              {pipelines.length !== 0 ? (
                pipelines?.map((pipeline: any) => (
                  <option value={pipeline.name} key={pipeline.id}>
                    {pipeline.name}
                  </option>
                ))
              ) : (
                <option value={pipeline}>{pipeline}</option>
              )}
            </select>
          </td>
          <td role='cell' className='min-w-200px pe-0 px-0'>
            <select
              className='form-control form-control-sm form-control-flush text-center text-gray-900'
              {...formik.getFieldProps('responsible_user')}
              form={'Line_form' + line?.id}
            >
              {users.length !== 0 ? (
                users?.map((user: any) => (
                  <option value={user.amocrm_user_id} key={user.id}>
                    {user.amocrm_user_id} - {user.fullname}
                  </option>
                ))
              ) : (
                <option value={user}>{user}</option>
              )}
            </select>
            {formik.touched.responsible_user && formik.errors.responsible_user && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block text-danger'>{formik.errors.responsible_user}</div>
              </div>
            )}
          </td>
          <td role='cell' className='min-w-85px pe-0 px-0'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-block text-center'>
              <input
                className='form-check-input'
                type='checkbox'
                id={'editsm' + line.line}
                defaultChecked={line.smart_call}
                form={'Line_form' + line?.id}
                onClick={(e: any) => {
                  updateSmartCallState(e.target.checked)
                }}
                {...formik.getFieldProps('smart_call')}
              />
              <label className='form-check-label' htmlFor={'editsm' + line.line}></label>
            </div>
          </td>
          <td role='cell' className='min-w-150px pe-0 px-0 text-center d-flex'>
            <input
              className={clsx(
                'form-control form-control-sm form-control-flush text-center text-gray-700',
                smartCallState === true && `text-gray-900`
              )}
              id={'editstartTime' + line.line}
              form={'Line_form' + line?.id}
              disabled={!smartCallState}
              {...formik.getFieldProps('start_time')}
            />
            <label className='align-self-center'>—</label>
            <input
              className={clsx(
                'form-control form-control-sm form-control-flush text-center text-gray-700',
                smartCallState === true && `text-gray-900`
              )}
              id={'editendTime' + line.line}
              form={'Line_form' + line?.id}
              disabled={!smartCallState}
              {...formik.getFieldProps('end_time')}
            />
          </td>
          <td role='cell' className='min-w-100px pe-0 px-0'>
            <select
              className='badge badge-light-primary form-check-input min-w-100px'
              {...formik.getFieldProps('incoming_call')}
            >
              <option value='None'>None</option>
              <option value='Unsorted'>Unsorted</option>
              <option value='Contact'>Contact</option>
              <option value='ContactLead'>Contact + Lead</option>
            </select>
          </td>
          <td role='cell' className='min-w-85px pe-0 px-0'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-block text-center'>
              <input
                className='form-check-input text-center'
                type='checkbox'
                defaultChecked={line.udpc}
                id={'editudpc' + line.line}
                form={'Line_form' + line?.id}
                {...formik.getFieldProps('udpc')}
              />
              <label className='form-check-label' htmlFor={'editudpc' + line.line}></label>
            </div>
          </td>
          <td role='cell' className='min-w-85px pe-0 px-0'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-block text-center'>
              <input
                className='form-check-input text-center'
                type='checkbox'
                defaultChecked={line.repeat_sales_mode}
                id={'editrsm' + line.line}
                form={'Line_form' + line?.id}
                {...formik.getFieldProps('rsm')}
              />
              <label className='form-check-label' htmlFor={'editrsm' + line.line}></label>
            </div>
          </td>
          <td role='cell' className='min-w-85px pe-0 px-0'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-block text-center'>
              <input
                className='form-check-input text-center'
                type='checkbox'
                defaultChecked={line.find_unsorted}
                id={'edit_find_unsorted' + line.line}
                form={'Line_form' + line?.id}
                {...formik.getFieldProps('find_unsorted')}
              />
              <label className='form-check-label' htmlFor={'edit_find_unsorted' + line.line}></label>
            </div>
          </td>
          <td role='cell' className='min-w-150px pe-0 px-0'>
            <button
              type='submit'
              title='Save'
              className='btn btn-icon btn-light-success btn-active-color-light btn-sm me-1 align-self-center'
              form={'Line_form' + line?.id}
              disabled={isLoading}
            >
              {!isLoading && <KTSVG path='/media/icons/duotune/general/gen037.svg' className='align-self-center' />}
              {isLoading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  <span className='spinner-border spinner-border-sm align-middle'></span>
                </span>
              )}
            </button>
            <button
              form={'Line_form' + line?.id}
              className='btn btn-icon btn-light-primary btn-active-color-light btn-sm me-1 align-self-center'
              title='Close'
              onClick={() => {
                updateLineState(false)
                formik.resetForm()
              }}
              disabled={isLoading}
              hidden={isLoading}
            >
              {!isLoading && <KTSVG path='/media/icons/duotune/general/gen034.svg' className='align-self-center' />}
            </button>
            {line.line !== '' && (
              <button
                form={'Line_form' + line?.id}
                title='Delete'
                onClick={() => {
                  deleteLine()
                  formik.resetForm()
                }}
                className='btn btn-icon btn-light-danger btn-active-color-light btn-sm me-1 align-self-center'
                disabled={isLoading}
                hidden={isLoading}
              >
                {!isLoading && <KTSVG path='/media/icons/duotune/general/gen027.svg' className='align-self-center' />}
              </button>
            )}
          </td>
        </tr>
      )}
    </>
  )
}

export {OneLineAmoCRM}
