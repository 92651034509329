import clsx from 'clsx'
import {FC} from 'react'
import {KTSVG} from '../../../../../template/helpers'
import {IAppsModel} from '../../../../../types/apps/AppsModel'

type Props = {
  app: IAppsModel
  firstElement?: boolean
}

const AppItemHeaderCollapse: FC<Props> = ({app, firstElement = false}) => {
  return (
    <>
      <div
        className={clsx('d-flex align-items-center', {collapsed: !firstElement})}
        role='button'
        data-bs-toggle='collapse'
        data-bs-target={`#cb_app_item_${app.id}`}
      >
        <div className='me-3'>
          <span className='accordion-icon animate__animated animate__headShake'>
            <KTSVG className='svg-icon svg-icon-3 text-hover-primary' path='/media/icons/duotune/arrows/arr064.svg' />
          </span>
        </div>
      </div>
    </>
  )
}

export {AppItemHeaderCollapse}
