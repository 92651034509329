import React, {Dispatch, MutableRefObject, SetStateAction, useEffect, useRef} from 'react'
import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useParams} from 'react-router-dom'
import {appsAPI} from '../../../../services/AppsService'
import {usersAPI} from '../../../../services/UsersService'
import {KTSVG} from '../../../../template/helpers'
import {formatDateByZone} from '../../../../template/helpers/FormatDateZoneHelpers'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment-timezone'
import clsx from 'clsx'

type Props = {
  id: string
  licenseEnd: string
  timeZone: string
  language?: string
  isEdit?: boolean
  setLicenseEnd: Dispatch<SetStateAction<string>>
}

const DatePickerInput: FC<Props> = ({id, licenseEnd, timeZone, isEdit = true, setLicenseEnd}) => {
  const dateRef = useRef<DateRangePicker>()
  const localeSettings = {format: 'DD/MM/YYYY'}

  useEffect(() => {
    dateRef.current?.setStartDate(moment(licenseEnd).tz(timeZone).format('DD/MM/YYYY'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenseEnd])

  const handleCallback = (end: any) => {
    setLicenseEnd(end.set({hour: 15}).toISOString())
  }
  return (
    <DateRangePicker
      ref={dateRef as MutableRefObject<DateRangePicker>}
      initialSettings={{
        singleDatePicker: true,
        alwaysShowCalendars: false,
        showDropdowns: true,
        locale: localeSettings,
        minDate: moment().tz(timeZone),
        minYear: 2022,
        maxYear: 2050,
      }}
      onCallback={handleCallback}
    >
      <input
        id={id}
        type='text'
        className={clsx('form-control form-control-lg form-control-solid mb-3 mb-lg-0', {
          'text-gray-900': isEdit,
          'text-gray-500': !isEdit,
        })}
        disabled={!isEdit}
      />
    </DateRangePicker>
  )
}

interface ILicenseUpdate {
  is_commercial?: 'COMMERCIAL' | 'TRIAL' | boolean
  maxusers?: number
  license_end?: string
}

const ValidationSchimas = Yup.object().shape({
  maxusers: Yup.number().required(),
  is_auto_mode: Yup.boolean(),
})

const License: FC = () => {
  const {appId} = useParams()
  const {data: app} = appsAPI.useFetchAppByIdQuery(appId!)
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const [mutationLicense, {isLoading: isLoadingUpdate}] = appsAPI.useUpdateLicenseMutation()
  const [licenseUpdate, setLicenseUpdate] = useState<boolean>(false)
  const [licenseEnd, setLicenseEnd] = useState<string>(app?.licenses.license_end!)
  const [isEditDate, setIsEditDate] = useState<boolean>(true)
  const [isCommercial, setIsCommercial] = useState<boolean>(app?.licenses.is_commercial!)

  function dateTime(date: string) {
    return formatDateByZone(date, currentUser?.language || 'en', currentUser?.time_zone || 'UTC')
  }

  function IsCommercialTypeToString(value: boolean): string {
    if (value) {
      return 'COMMERCIAL'
    } else {
      return 'TRIAL'
    }
  }
  function IsCommercialTypeToBoolean(value: 'COMMERCIAL' | 'TRIAL'): boolean {
    if (value === 'COMMERCIAL') {
      return true
    } else {
      return false
    }
  }
  function autoDate(value: number) {
    if (isCommercial && isEditDate) {
      const cd = moment().unix()
      const ls = moment(app?.licenses.license_start).unix()
      const le = moment(app?.licenses.license_end).unix()
      const mu = 1 / (value / app?.licenses.maxusers!)
      const autoDate = cd + (le - ls - (cd - ls)) * mu
      const dateFromTimeStamp = moment.unix(autoDate)
      setLicenseEnd(moment(dateFromTimeStamp).toISOString())
    }
  }

  const formik = useFormik<ILicenseUpdate>({
    initialValues: {
      is_commercial: IsCommercialTypeToString(app?.licenses.is_commercial!) as 'COMMERCIAL' | 'TRIAL',
      maxusers: app?.licenses.maxusers,
      license_end: dateTime(app?.licenses.license_end as string),
    },
    validationSchema: ValidationSchimas,
    onSubmit: async (values) => {
      values.license_end = licenseEnd
      const data = () => {
        if (isEditDate) {
          return {
            is_commercial: IsCommercialTypeToBoolean(values.is_commercial as 'COMMERCIAL' | 'TRIAL') as boolean,
            maxusers: values.maxusers,
            license_end: values.license_end,
            is_active: true,
          }
        }
        return {
          is_commercial: IsCommercialTypeToBoolean(values.is_commercial as 'COMMERCIAL' | 'TRIAL') as boolean,
          maxusers: values.maxusers,
          is_active: true,
        }
      }

      await mutationLicense({
        id: app?.licenses.id!,
        data: data(),
      })
        .unwrap()
        .then(() => {
          formik.initialValues.maxusers = values.maxusers
          formik.initialValues.is_commercial = values.is_commercial
          formik.initialValues.license_end = values.license_end
        })
      formik.resetForm()
      setLicenseUpdate(false)
    },
  })

  return (
    <div className='card card-custom card-stretch shadow-sm mb-5'>
      <div className='card-header'>
        <div className='d-flex flex-stack col-xl-12'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder m-0'>License Info</span>
          </h3>
          {currentUser?.role !== 'CLIENT' && !licenseUpdate && (
            <div className='align-items-end'>
              <button className='btn btn-sm btn-primary align-self-center' onClick={() => setLicenseUpdate(true)}>
                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-info me-1 align-self-center' />
                Edit
              </button>
            </div>
          )}
        </div>
      </div>
      <div className='card-body pt-5 pb-5'>
        {!licenseUpdate ? (
          <>
            <div className='row mb-5'>
              <label className='col-lg-4 fw-bolder text-gray-600 small text-uppercase'>ID</label>
              <div className='col-lg-8'>
                <span className='badge badge-light-info'>{app?.licenses.id}</span>
              </div>
            </div>
            <div className='separator separator-dashed my-5'></div>
            <div className='row mb-5'>
              <label className='col-lg-4 fw-bolder text-gray-600 small text-uppercase'>LICENSE</label>
              <div className='col-lg-8'>
                {app?.licenses.is_commercial ? (
                  <span className='badge badge-success'>COMMERCIAL</span>
                ) : (
                  <span className='badge badge-warning me-2'>TRIAL</span>
                )}
              </div>
            </div>
            <div className='separator separator-dashed my-5'></div>
            <div className='row mb-5'>
              <label className='col-lg-4 fw-bolder text-gray-600 small text-uppercase'>LICENSE USERS</label>
              <div className='col-lg-8'>
                <span className='fw-bolder text-gray-800'>{app?.licenses.maxusers}</span>
              </div>
            </div>
            <div className='separator separator-dashed my-5'></div>
            <div className='row mb-5'>
              <label className='col-lg-4 fw-bolder text-gray-600 small text-uppercase'>LICENSE END</label>
              <div className='col-lg-8 fv-row'>
                <span className='fw-bolder text-gray-800'>{dateTime(app?.licenses.license_end as string)}</span>
              </div>
            </div>
          </>
        ) : (
          <>
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className='row mb-5'>
                <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>LICENSE</span>
                </label>

                <div className='col-lg-8'>
                  <select
                    id={app?.id}
                    className='form-select form-select-solid form-select-lg text-gray-900 align-self-center'
                    onChange={(e) => {
                      setIsCommercial(e.target.value === 'COMMERCIAL' ? true : false)
                      formik.setFieldValue('is_commercial', e.target.value)
                    }}
                    defaultValue={formik.values.is_commercial as 'COMMERCIAL' | 'TRIAL'}
                    disabled={app?.licenses.is_commercial}
                  >
                    <option value='TRIAL'>TRIAL</option>
                    <option value='COMMERCIAL'>COMMERCIAL</option>
                  </select>
                </div>
              </div>
              <div className='row mb-5'>
                <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase required'>LICENSE USERS</span>
                </label>

                <div className='col-lg-8 d-flex align-items-center'>
                  <input
                    type='number'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-900'
                    min='0'
                    max='500'
                    placeholder='max License Users'
                    onChange={(e) => {
                      autoDate(parseInt(e.target.value))
                      formik.setFieldValue('maxusers', e.target.value)
                    }}
                    defaultValue={formik.values.maxusers}
                    // {...formik.getFieldProps('maxusers')}
                  />
                </div>
                {formik.errors.maxusers && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.maxusers}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='row mb-5'>
                <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>LICENSE END</span>
                </label>

                <div className='col-lg-8 align-items-center'>
                  <div className='d-flex align-items-center'>
                    {isCommercial && (
                      <div className='me-3'>
                        <div className='form-check form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            defaultChecked={isEditDate}
                            onChange={(e) => {
                              setIsEditDate(e.target.checked)
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <div className='input-group'>
                      <DatePickerInput
                        id={app?.id!}
                        licenseEnd={licenseEnd}
                        timeZone={currentUser?.time_zone!}
                        setLicenseEnd={setLicenseEnd}
                        isEdit={isEditDate}
                      />
                      <span className='input-group-text border-0'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen014.svg'
                          className='svg-icon-gray-700 svg-icon-2x'
                        />
                      </span>
                    </div>
                  </div>
                  <div className='d-flex justify-content-center mt-2'>
                    {isCommercial ? (
                      <>
                        <button
                          type='button'
                          className='btn btn-sm btn-active-light-info me-2'
                          onClick={(e) => {
                            e.currentTarget.disabled = true
                            setLicenseEnd(moment(licenseEnd).add(1, 'month').toISOString())
                          }}
                          disabled={!isEditDate}
                        >
                          +1 month
                        </button>
                        <button
                          type='button'
                          className='btn btn-sm btn-active-light-info me-2'
                          onClick={(e) => {
                            e.currentTarget.disabled = true
                            setLicenseEnd(moment(licenseEnd).add(6, 'month').toISOString())
                          }}
                          disabled={!isEditDate}
                        >
                          +6 month
                        </button>
                        <button
                          type='button'
                          className='btn btn-sm btn-active-light-info'
                          onClick={(e) => {
                            e.currentTarget.disabled = true
                            setLicenseEnd(moment(licenseEnd).add(1, 'year').toISOString())
                          }}
                          disabled={!isEditDate}
                        >
                          +12 month
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type='button'
                          className='btn btn-sm btn-active-light-info me-2'
                          onClick={(e) => {
                            e.currentTarget.disabled = true
                            setLicenseEnd(moment(licenseEnd).add(1, 'day').toISOString())
                          }}
                        >
                          +1 day
                        </button>
                        <button
                          type='button'
                          className='btn btn-sm btn-active-light-info me-2'
                          onClick={(e) => {
                            e.currentTarget.disabled = true
                            setLicenseEnd(moment(licenseEnd).add(7, 'days').toISOString())
                          }}
                        >
                          +7 days
                        </button>
                        <button
                          type='button'
                          className='btn btn-sm btn-active-light-info me-2'
                          onClick={(e) => {
                            e.currentTarget.disabled = true
                            setLicenseEnd(moment(licenseEnd).add(14, 'days').toISOString())
                          }}
                        >
                          +14 days
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className='card-footer d-flex justify-content-end py-2 px-1 border-0'>
                <button type='submit' className='btn btn-sm btn-primary me-3' disabled={isLoadingUpdate}>
                  {!isLoadingUpdate && 'Save Changes'}
                  {isLoadingUpdate && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
                <button
                  className='btn btn-sm btn-light-primary align-self-center'
                  onClick={() => {
                    setLicenseUpdate(false)
                    setIsEditDate(true)
                    formik.setFieldValue('license_end', setLicenseEnd(app?.licenses.license_end!))
                    formik.resetForm()
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  )
}

export {License}
