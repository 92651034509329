import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {usersAPI} from '../../../../services/UsersService'

export function AsideMenuMain() {
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()

  return (
    <>
      {currentUser?.role !== 'CLIENT' && (
        <>
          <AsideMenuItemWithSub
            to='/management'
            title='Management'
            icon='/media/icons/duotune/general/gen049.svg'
            fontIcon='bi-app-indicator'
          >
            <AsideMenuItem to='/management/apps/' title='Integrations' hasBullet={true} />
            <AsideMenuItem to='/management/users/' title='Users' hasBullet={true} />
            <AsideMenuItem to='/management/history/' title='Service History' hasBullet={true} />
          </AsideMenuItemWithSub>
          <div className='menu-item'>
            <div className='menu-content mx-1 my-4'>
              <div className='separator'></div>
            </div>
          </div>
        </>
      )}

      <AsideMenuItem
        to='/apps/'
        title='Integrations'
        icon='/media/icons/duotune/abstract/abs022.svg'
        fontIcon='bi-app-indicator'
      ></AsideMenuItem>
      <div className='menu-item'>
        <div className='menu-content mx-1 my-4'>
          <div className='separator'></div>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/settings'
        title='Settings'
        icon='/media/icons/duotune/general/gen019.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/settings/profile/' title='Profile' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
    </>
  )
}
