/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {usersAPI} from '../../../../services/UsersService'
import {toAbsoluteUrl} from '../../../helpers'
import {useLang} from '../../../i18n/RootI18n'

const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
  {
    lang: 'ru',
    name: 'Russian',
    flag: toAbsoluteUrl('/media/flags/russia.svg'),
  },
]

const Languages: FC = () => {
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const [updateUser] = usersAPI.useUpdateUserMutation()

  async function setLanguageCurrentUser(lang: string) {
    if (currentUser) {
      const updatedUser = Object.assign({}, currentUser)
      if (lang !== undefined && lang === 'en') {
        updatedUser.language = 'en'
      } else {
        updatedUser.language = 'ru'
      }
      await updateUser(updatedUser)
    }
  }

  function reloadPage() {
    setTimeout(() => {
      document.location.reload()
    }, 500)
  }

  return (
    <div
      className='menu-item px-5'
      data-kt-menu-trigger='hover'
      data-kt-menu-placement='left-start'
      data-kt-menu-flip='bottom'
    >
      <a href='#' className='menu-link px-5'>
        <span className='menu-title position-relative'>
          Language
          <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            {currentLanguage?.name}{' '}
            <img className='w-15px h-15px rounded-1 ms-2' src={currentLanguage?.flag} alt='callbee-flag' />
          </span>
        </span>
      </a>

      <div className='menu-sub menu-sub-dropdown w-175px py-4'>
        {languages.map((l) => (
          <div
            className='menu-item px-3'
            key={l.lang}
            onClick={() => {
              setLanguageCurrentUser(l.lang)
            }}
          >
            <div
              onClick={() => reloadPage()}
              className={clsx('menu-link d-flex px-5', {active: l.lang === currentLanguage?.lang})}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag} alt='callbee-flag' />
              </span>
              {l.name}
            </div>
            {/* <a
              href={location.pathname}
              className={clsx('menu-link d-flex px-5', {active: l.lang === currentLanguage?.lang})}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag} alt='callbee-flag' />
              </span>
              {l.name}
            </a> */}
          </div>
        ))}
      </div>
    </div>
  )
}

export {Languages}
