import {useParams} from 'react-router-dom'
import {appsAPI} from '../../../../services/AppsService'
import {KTSVG} from '../../../../template/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {IAMISettingsCreate, AMISettingsInitValues} from '../../core/_models'
import {useState} from 'react'
import {IAppsModel} from '../../../../types/apps/AppsModel'
import clsx from 'clsx'

const amiSettingsEditShema = Yup.object().shape({
  host: Yup.string().required('Host is required'),
  username: Yup.string().required('Username is required'),
  secret: Yup.string().required('Secret is required'),
})

const AMISettings = () => {
  const {appId} = useParams()
  const {data: app} = appsAPI.useFetchAppByIdQuery(appId!)
  const [updateApp, {isLoading}] = appsAPI.useUpdateAppMutation()
  const [amiState, updateAMIState] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const formik = useFormik<IAMISettingsCreate>({
    initialValues: Object.assign(AMISettingsInitValues, app?.settings.ami),
    validationSchema: amiSettingsEditShema,
    onSubmit: async (values) => {
      const data = {
        id: appId,
        settings: {
          ami: {
            host: values.host,
            username: values.username,
            secret: values.secret.trim(),
          },
        },
      }
      await updateApp(data as IAppsModel)
      updateAMIState(false)
      setShowPassword(false)
    },
  })

  return (
    <>
      {amiState === false && (
        <div className='card card-custom card-stretch shadow-sm mb-5'>
          <div className='card-header'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>AMI Settings</h3>
            </div>
            <button className='btn btn-sm btn-primary align-self-center' onClick={() => updateAMIState(true)}>
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-info me-1 align-self-center' />
              Edit
            </button>
          </div>
          <div className='card-body pt-5 pb-5'>
            <div className='row mb-5'>
              <label className='col-lg-4 fw-bolder text-gray-600 small text-uppercase'>Host</label>

              <div className='col-lg-8'>
                <span className='fw-bolder text-gray-800'>{app?.settings.ami.host}</span>
              </div>
            </div>

            <div className='separator separator-dashed my-5'></div>

            <div className='row mb-5'>
              <label className='col-lg-4 fw-bolder text-gray-600 small text-uppercase'>Username</label>

              <div className='col-lg-8 d-flex align-items-center'>
                <span className='fw-bolder text-gray-800'>{app?.settings.ami.username}</span>
              </div>
            </div>

            <div className='separator separator-dashed my-5'></div>

            <div className='row mb-5'>
              <label className='col-lg-4 fw-bolder text-gray-600 small text-uppercase'>Secret</label>

              <div className='col-lg-8 fv-row'>
                <div className='input-group d-flex align-items-center'>
                  <input
                    className='form-control fw-bolder text-gray-800 border-0 fs-7 p-0'
                    type={showPassword ? 'text' : 'password'}
                    value={app?.settings.ami.secret}
                    style={{borderRadius: 0}}
                    readOnly
                  ></input>
                  <span
                    className='border-0 p-0 me-2'
                    style={{cursor: 'pointer'}}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <i
                      className={clsx('bi text-gray-800 fs-3', {
                        'bi-eye-fill': !showPassword,
                        'bi-eye-slash-fill': showPassword,
                      })}
                    ></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {amiState === true && (
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card card-custom card-stretch shadow-sm mb-5'>
            <div className='card-header'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Edit AMI Settings</h3>
              </div>
            </div>
            <div className='card-body pt-5 pb-5'>
              <div className='row mb-5'>
                <label className='col-lg-4 col-form-label required fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>Host</span>
                </label>

                <div className='col-lg-8'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-900'
                    placeholder='AMI Host:port'
                    {...formik.getFieldProps('host')}
                  />
                  {formik.touched.host && formik.errors.host && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block text-danger'>{formik.errors.host}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-5'>
                <label className='col-lg-4 col-form-label required fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>Username</span>
                </label>

                <div className='col-lg-8'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-900'
                    placeholder='AMI Username'
                    {...formik.getFieldProps('username')}
                  />
                  {formik.touched.username && formik.errors.username && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block text-danger'>{formik.errors.username}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-5'>
                <label className='col-lg-4 col-form-label required fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>Secret</span>
                </label>

                <div className='col-lg-8'>
                  <div className='input-group input-group-solid'>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className='form-control form-control-lg form-control-solid text-gray-900'
                      placeholder='AMI Secret'
                      autoComplete='off'
                      {...formik.getFieldProps('secret')}
                    />
                    <span
                      className='input-group-text border-0'
                      style={{cursor: 'pointer'}}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <i
                        className={clsx('bi text-gray-800 fs-3', {
                          'bi-eye-fill': !showPassword,
                          'bi-eye-slash-fill': showPassword,
                        })}
                      ></i>
                    </span>
                  </div>
                  {formik.touched.secret && formik.errors.secret && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block text-danger'>{formik.errors.secret}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='card-footer d-flex justify-content-end py-2 px-1' style={{border: 'none'}}>
                <button type='submit' className='btn btn-sm btn-primary me-3' disabled={isLoading}>
                  {!isLoading && 'Save Changes'}
                  {isLoading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
                <button
                  className='btn btn-sm btn-light-primary align-self-center'
                  onClick={() => {
                    updateAMIState(false)
                    setShowPassword(false)
                    formik.resetForm()
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  )
}

export {AMISettings}
