import {useParams} from 'react-router-dom'
import {appsAPI} from '../../../../../services/AppsService'
import {Bitri24UsersInService} from './users_in_service/Bitri24UsersInService'

const BitrixUsers = () => {
  const {appId} = useParams()
  const {data: app} = appsAPI.useFetchAppByIdQuery(appId!)

  return (
    <div className='card card-custom card-stretch shadow-sm mb-5'>
      <div className='card-header mt-5'>
        <div className='d-flex flex-stack col-12 me-10'>
          <div className='card-title m-0 d-flex flex-wrap'>
            <h3 className='fw-bolder m-0'>Users</h3>
          </div>
        </div>

        {app?.settings.bitrix24.users_in_service === true && (
          <div className='text-align-start row mb-5 mt-5 fs-8'>
            <div className='col-lg-5'>
              <span className='small text-uppercase fw-bolder text-gray-600'>Number</span>
              <span className='small text-gray-600'> - Internal number</span>
            </div>
            <div className='col-lg-5'>
              <span className='small text-uppercase fw-bolder text-gray-600'>AA</span>
              <span className='small text-gray-600'> - Auto Answer</span>
            </div>
            <div className='col-lg-5'>
              <span className='small text-uppercase fw-bolder text-gray-600'>Mobile</span>
              <span className='small text-gray-600'> - Personal mobile</span>
            </div>
            <div className='col-lg-5'>
              <span className='small text-uppercase fw-bolder text-gray-600'>MR</span>
              <span className='small text-gray-600'> - Multiple Registation</span>
            </div>
            <div className='col-lg-5'>
              <span className='small text-uppercase fw-bolder text-gray-600'>Manager time</span>
              <span className='small text-gray-600'> - Manager time from Bitrix24</span>
            </div>
          </div>
        )}
      </div>
      <div className='card-body px-1'>
        <div className='table-responsive'>
          {app?.settings.bitrix24.users_in_service === true && <Bitri24UsersInService />}
        </div>
      </div>
    </div>
  )
}

export {BitrixUsers}
