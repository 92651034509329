const AmoCRMLineHeader = () => {
  return (
    <thead>
      <tr className='text-muted fw-bolder fs-8 text-uppercase gs-0 text-center'>
        <th role='columnheader' className='min-w-150px'>
          line (did)
        </th>
        <th role='columnheader' className='min-w-140px px-0'>
          description
        </th>
        <th role='columnheader' className='min-w-100px px-0'>
          pipeline
        </th>
        <th role='columnheader' className='min-w-200px px-0'>
          user
        </th>
        <th role='columnheader' className='min-w-85px px-0'>
          smart call
        </th>
        <th role='columnheader' className='min-w-150px px-0'>
          smart call time
        </th>
        <th role='columnheader' className='min-w-100px px-0'>
          U/C/CL
        </th>
        <th role='columnheader' className='min-w-85px px-0'>
          UDPC
        </th>
        <th role='columnheader' className='min-w-85px px-0'>
          RSM
        </th>
        <th role='columnheader' className='min-w-85px px-0'>
          FU
        </th>
        <th role='columnheader' className='min-w-150px px-0'>
          edit
        </th>
      </tr>
    </thead>
  )
}

export {AmoCRMLineHeader}
