import {lazy, FC, Suspense, ReactNode} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../template/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {AppsWrapper} from '../pages/apps/AppsWrapper'
import {getCSSVariableValue} from '../template/assets/ts/_utils'
import {usersAPI} from '../services/UsersService'
import AppsPage from '../modules/apps/AppsPage'

const PrivateRoutes = () => {
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const ManagementPage = lazy(() => import('../modules/management/ManagementPage'))
  // const AppsPage = lazy(() => import('../modules/apps/AppPage'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/apps' />} />
        {/*<Route path='dashboard' element={<DashboardWrapper />} />*/}
        {currentUser?.role === 'ADMIN' && (
          <Route
            path='management/*'
            element={
              <SuspensedView>
                <ManagementPage />
              </SuspensedView>
            }
          />
        )}
        <Route
          path='apps/'
          element={
            <SuspensedView>
              <AppsWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='apps/:appId/*'
          element={
            <SuspensedView>
              <AppsPage />
            </SuspensedView>
          }
        />
        <Route
          path='settings/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

type WithChildren = {
  children?: ReactNode
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
