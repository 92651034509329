import {FC, useEffect, useRef, useState} from 'react'
import {Navigate} from 'react-router-dom'
import {useAppDispatch} from '../../hooks/redux'
import {authAPI} from '../../services/AuthService'
import {logoutAction} from '../../slices/authSlice'
import {LayoutSplashScreen} from '../../template/layout/core'

const Logout: FC = () => {
  const dispatch = useAppDispatch()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const [logoutUser] = authAPI.useLazyLogoutQuery()

  useEffect(() => {
    const logout = async () => {
      if (!didRequest.current) {
        await logoutUser()
        dispatch(logoutAction())
        setShowSplashScreen(false)
      }
      return () => (didRequest.current = true)
    }

    logout()
  }, [dispatch, logoutUser])

  return showSplashScreen ? <LayoutSplashScreen /> : <Navigate to={'/'} replace />
}

export {Logout}
