/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {AppsHeaderWrapper} from '../../../modules/apps/components/AppsHeaderWrapper'
import {AppItem} from '../../../modules/apps/components/item/AppItem'
import {appsAPI} from '../../../services/AppsService'
import {KTSVG} from '../../../template/helpers'

const ListAppPage: FC = () => {
  const {data: apps, isLoading} = appsAPI.useFetchAppsMeQuery()

  return (
    <>
      {isLoading ? (
        'Loading...'
      ) : (
        <>
          {apps?.length! > 0 ? (
            <AppsHeaderWrapper>
              <div className='py-0 accordion accordion-icon-toggle'>
                {apps?.map((app, index) => (
                  <div key={index}>
                    <AppItem key={index} app={app} firstElement={apps.slice(0)[0] === app ? true : false} />
                    {apps.slice(-1)[0] !== app && <div className='separator separator-dashed'></div>}
                  </div>
                ))}
              </div>
            </AppsHeaderWrapper>
          ) : (
            <>
              <div className='d-flex flex-row h-500px'>
                <div className='d-flex flex-column flex-row-fluid'>
                  <div className='d-flex flex-row flex-column-fluid'>
                    <div className='d-flex flex-row-fluid flex-center'>
                      <div className='card-0 text-center'>
                        <KTSVG
                          path='/media/icons/duotune/abstract/abs022.svg'
                          className='svg-icon-gray svg-icon-3x me-2'
                        />
                        <div className='card-header py-4'>
                          <h3 className='card-title'>Create new integration</h3>
                        </div>
                        <div className='card-body'>
                          <a
                            className='btn btn-primary'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_create_app'
                            id='kt_toolbar_primary_button'
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr009.svg'
                              className='svg-icon-muted svg-icon-1x'
                            />
                            Create integration
                          </a>
                          <a
                            href='https://wiki.callbee.io/'
                            target='_blank'
                            className='btn btn-secondary ms-2'
                            rel='noreferrer'
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr095.svg'
                              className='svg-icon svg-icon-gray-900 text-hover-primary'
                            />
                            Callbee Wiki
                          </a>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}

export {ListAppPage}
