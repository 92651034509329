import {useParams} from 'react-router-dom'
import {appsAPI} from '../../../../../../services/AppsService'
import {Bitrix24LineHeader} from './Bitrix24LineHeader'
import {OneLineBitrix24} from './OneLineBitrix24'
import {IBitrixLineModel} from '../../../../../../types/apps/crm/biitrix24/BitrixLineModel'

const LinesBitrix = () => {
  const {appId} = useParams()
  const {data: app} = appsAPI.useFetchAppByIdQuery(appId!)
  const lines = app?.settings.bitrix24.lines
  const mainLine: IBitrixLineModel = lines?.filter((l) => l.line === '')[0] as IBitrixLineModel
  const userLines: IBitrixLineModel[] = lines?.filter((l) => l.line !== '') as IBitrixLineModel[]

  return (
    <table className='table align-middle table-row-dashed fs-7 gy-5 dataTable no-footer'>
      <Bitrix24LineHeader />
      <tbody>
        <OneLineBitrix24 line={mainLine} key={mainLine.id} />
        {userLines?.map((l) => (
          <OneLineBitrix24 line={l} key={l.id} />
        ))}
      </tbody>
    </table>
  )
}

export {LinesBitrix}
