import {useParams} from 'react-router-dom'
import {FC, useState} from 'react'
import {appsAPI} from '../../../../../../services/AppsService'
import {KTSVG} from '../../../../../../template/helpers'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {IBitrix24LineUpdate, Bitrix24LineUpdateInitValues} from '../../../../core/_models'
import {IAppsModel} from '../../../../../../types/apps/AppsModel'

type Props = {
  line: IBitrix24LineUpdate
}

const bitrix24LineEditShema = Yup.object().shape({
  line: Yup.string().required('Line is required'),
  description: Yup.string(),
  responsible_user: Yup.string().required('User is required'),
  smart_call: Yup.boolean(),
  b24_manager_time: Yup.boolean(),
  start_time: Yup.string(),
  end_time: Yup.string(),
  auto_lead: Yup.boolean(),
  chat: Yup.boolean(),
})

const OneLineBitrix24: FC<Props> = ({line}) => {
  const {appId} = useParams()
  const {data: app} = appsAPI.useFetchAppByIdQuery(appId!)
  const [lineState, updateLineState] = useState(false)
  const [smartCallState, updateSmartCallState] = useState(line.smart_call)
  const [b24TimeState, updateb24TimeState] = useState(line.b24_manager_time)
  const [timeState, updateTimeState] = useState(line.b24_manager_time)
  const [b24TimeDisabledState, updateb24TimeDisabledState] = useState(!line.smart_call)
  const [updateApp, {isLoading}] = appsAPI.useUpdateAppMutation()
  const all_lines = app?.settings.bitrix24.lines

  const checkSMCallDisabled = (checked: boolean) => {
    updateSmartCallState(checked)
    const b24Time = document.getElementById('b24_manager_time' + line.line)
    if (smartCallState === true) {
      if (b24TimeState === true) {
        b24Time?.click()
        updateb24TimeState(false)
      }
      updateb24TimeDisabledState(true)
      updateTimeState(true)
    } else if (smartCallState === false) {
      updateb24TimeDisabledState(false)
      updateTimeState(false)
    }
  }

  const checkTimeDisabled = (checked: boolean) => {
    updateb24TimeState(checked)
    if (b24TimeState === false) {
      updateTimeState(true)
    } else {
      updateTimeState(false)
    }
  }

  const other_lines: any[] = []
  if (all_lines) {
    for (let i = 0; i < all_lines.length; i++) {
      if (all_lines[i]?.id !== line.id) {
        other_lines.push(all_lines[i])
      }
    }
  }
  if (other_lines) {
    for (let i = 0; i < other_lines.length; i++) {
      if (other_lines[i].groups) {
        delete other_lines[i]['groups']
      }
    }
  }

  const formik = useFormik<IBitrix24LineUpdate>({
    initialValues: Object.assign(Bitrix24LineUpdateInitValues, {
      id: line.id,
      line: line.line === '' ? 'All lines' : line.line,
      description: line.line === '' ? 'All lines' : line.description,
      responsible_user: line.responsible_user,
      smart_call: line.smart_call,
      b24_manager_time: line.b24_manager_time,
      start_time: line.start_time.slice(0, 5),
      end_time: line.end_time.slice(0, 5),
      auto_lead: line.auto_lead,
      chat: line.chat,
    }),
    validationSchema: bitrix24LineEditShema,

    onSubmit: async (values) => {
      const edit_line: IBitrix24LineUpdate = {
        id: line.id,
        line: values.line === 'All lines' ? '' : values.line.trim(),
        description: values.description === 'All lines' ? '' : values.description,
        responsible_user: values.responsible_user,
        smart_call: values.smart_call,
        b24_manager_time: values.b24_manager_time,
        start_time: values.start_time,
        end_time: values.end_time,
        auto_lead: values.auto_lead,
        chat: values.chat,
      }

      other_lines.push(edit_line)
      const data = {
        id: appId,
        settings: {
          bitrix24: {
            lines: other_lines,
          },
        },
      }
      await updateApp(data as IAppsModel)
      updateLineState(false)
    },
  })

  const deleteLine = () => {
    const data = {
      id: appId,
      settings: {
        bitrix24: {
          lines: other_lines,
        },
      },
    }
    updateApp(data as IAppsModel)
    updateLineState(false)
  }

  return (
    <>
      {lineState === false && (
        <tr role='row' className='text-gray-900 text-center'>
          <td role='cell' className='min-w-150px pe-0'>
            <input
              className='form-control form-control-sm form-control-flush text-center'
              value={line.line === '' ? 'All lines' : line.line}
              readOnly
            />
          </td>
          <td role='cell' className='min-w-140px pe-0 px-0'>
            <input
              className='form-control form-control-sm form-control-flush text-gray-900 text-center'
              value={line.line === '' ? 'All lines' : line.description}
              disabled
              readOnly
            />
          </td>
          <td role='cell' className='min-w-100px pe-0 px-0'>
            <input
              className='form-control form-control-sm form-control-flush text-center'
              value={line.responsible_user}
              disabled
              readOnly
            />
          </td>
          <td role='cell' className='min-w-85px pe-0 px-0'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-block'>
              <input
                className='form-check-input'
                type='checkbox'
                id={'smCall' + line.line}
                checked={line.smart_call}
                readOnly
                disabled
              />
              <label className='form-check-label' htmlFor={'smCall' + line.line}></label>
            </div>
          </td>
          <td role='cell' className='min-w-150px px-0'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-block'>
              <input
                className='form-check-input'
                type='checkbox'
                id={'b24_manager_time' + line.line}
                checked={line.b24_manager_time}
                readOnly
                disabled
              />
              <label className='form-check-label' htmlFor={'b24_manager_time' + line.line}></label>
            </div>
          </td>
          <td role='cell' className='min-w-150px d-flex pe-0 px-0'>
            <input
              className='form-control form-control-sm form-control-flush text-center'
              value={line.start_time.slice(0, 5)}
              readOnly
              disabled
            />
            <label className='align-self-center text-center'>—</label>
            <input
              className='form-control form-control-sm form-control-flush text-center'
              value={line.end_time.slice(0, 5)}
              readOnly
              disabled
            />
          </td>
          <td role='cell' className='min-w-100px pe-0 px-0'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-block'>
              <input
                className='form-check-input'
                type='checkbox'
                id={'auto_lead' + line.line}
                checked={line.auto_lead}
                readOnly
                disabled
              />
              <label className='form-check-label' htmlFor={'auto_lead' + line.line}></label>
            </div>
          </td>
          <td role='cell' className='min-w-100px pe-0 px-0'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-block'>
              <input
                className='form-check-input'
                type='checkbox'
                id={'chat' + line.line}
                checked={line.chat}
                readOnly
                disabled
              />
              <label className='form-check-label' htmlFor={'chat' + line.line}></label>
            </div>
          </td>
          <td role='cell' className='min-w-150px pe-0 px-0'>
            <button
              type='button'
              onClick={() => {
                updateLineState(true)
              }}
              className='btn btn-icon btn-light-primary btn-sm me-1 align-self-center'
            >
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='align-self-center' />
            </button>
          </td>
        </tr>
      )}
      {lineState === true && (
        <tr role='row' className='text-center'>
          <td className='d-none'>
            <form
              onSubmit={formik.handleSubmit}
              noValidate
              method='GET'
              id={'Line_form' + line?.id}
              className='form'
            ></form>
            <input type='hidden' {...formik.getFieldProps('id')} />
          </td>
          <td role='cell' className='min-w-150px px-0'>
            <input
              className='form-control form-control-sm form-control-flush text-center'
              disabled={line.line === '' ? true : false}
              form={'Line_form' + line?.id}
              {...formik.getFieldProps('line')}
            />
            {formik.touched.line && formik.errors.line && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block text-danger'>{formik.errors.line}</div>
              </div>
            )}
          </td>
          <td role='cell' className='min-w-140px pe-0 px-0'>
            <input
              className='form-control form-control-sm form-control-flush text-center'
              disabled={line.line === '' ? true : false}
              form={'Line_form' + line?.id}
              {...formik.getFieldProps('description')}
            />
          </td>
          <td role='cell' className='min-w-100px pe-0 px-0'>
            <input
              className='form-control form-control-sm form-control-flush text-center'
              {...formik.getFieldProps('responsible_user')}
              form={'Line_form' + line?.id}
            />
            {formik.touched.responsible_user && formik.errors.responsible_user && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block text-danger'>{formik.errors.responsible_user}</div>
              </div>
            )}
          </td>
          <td role='cell' className='min-w-85px pe-0 px-0'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-block text-center'>
              <input
                className='form-check-input'
                type='checkbox'
                id={'editsm' + line.line}
                defaultChecked={line.smart_call}
                form={'Line_form' + line?.id}
                onClick={(e: any) => {
                  checkSMCallDisabled(e.target.checked)
                }}
                {...formik.getFieldProps('smart_call')}
              />
              <label className='form-check-label' htmlFor={'editsm' + line.line}></label>
            </div>
          </td>
          <td role='cell' className='min-w-100px pe-0 px-0'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-block text-center'>
              <input
                className='form-check-input'
                type='checkbox'
                id={'b24_manager_time' + line.line}
                defaultChecked={line.b24_manager_time}
                form={'Line_form' + line?.id}
                disabled={b24TimeDisabledState}
                onClick={(e: any) => {
                  checkTimeDisabled(e.target.checked)
                }}
                {...formik.getFieldProps('b24_manager_time')}
              />
              <label className='form-check-label' htmlFor={'b24_manager_time' + line.line}></label>
            </div>
          </td>
          <td role='cell' className='min-w-150px pe-0 px-0 text-center d-flex'>
            <input
              className='form-control form-control-sm form-control-flush text-center'
              id={'editstartTime' + line.line}
              form={'Line_form' + line?.id}
              disabled={timeState}
              {...formik.getFieldProps('start_time')}
            />
            <label className='align-self-center'>—</label>
            <input
              className='form-control form-control-sm form-control-flush text-center'
              id={'editendTime' + line.line}
              disabled={timeState}
              form={'Line_form' + line?.id}
              {...formik.getFieldProps('end_time')}
            />
          </td>
          <td role='cell' className='min-w-100px pe-0 px-0'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-block text-center'>
              <input
                className='form-check-input text-center'
                type='checkbox'
                defaultChecked={line.auto_lead}
                id={'editauto_lead' + line.line}
                form={'Line_form' + line?.id}
                {...formik.getFieldProps('auto_lead')}
              />
              <label className='form-check-label' htmlFor={'editauto_lead' + line.line}></label>
            </div>
          </td>
          <td role='cell' className='min-w-100px pe-0 px-0'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-block text-center'>
              <input
                className='form-check-input text-center'
                type='checkbox'
                id={'editchat' + line.line}
                defaultChecked={line.chat}
                form={'Line_form' + line?.id}
                {...formik.getFieldProps('chat')}
              />
              <label className='form-check-label' htmlFor={'editchat' + line.line}></label>
            </div>
          </td>
          <td role='cell' className='min-w-150px pe-0 px-0'>
            <button
              type='submit'
              title='Save'
              className='btn btn-icon btn-light-success btn-active-color-light btn-sm me-1 align-self-center'
              form={'Line_form' + line?.id}
              disabled={isLoading}
            >
              {!isLoading && <KTSVG path='/media/icons/duotune/general/gen037.svg' className='align-self-center' />}
              {isLoading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  <span className='spinner-border spinner-border-sm align-middle'></span>
                </span>
              )}
            </button>
            <button
              form={'Line_form' + line?.id}
              className='btn btn-icon btn-light-primary btn-active-color-light btn-sm me-1 align-self-center'
              title='Close'
              onClick={() => {
                updateLineState(false)
                formik.resetForm()
              }}
              disabled={isLoading}
              hidden={isLoading}
            >
              {!isLoading && <KTSVG path='/media/icons/duotune/general/gen034.svg' className='align-self-center' />}
            </button>
            {line.line !== '' && (
              <button
                form={'Line_form' + line?.id}
                title='Delete'
                onClick={() => {
                  deleteLine()
                  formik.resetForm()
                }}
                className='btn btn-icon btn-light-danger btn-active-color-light btn-sm me-1 align-self-center'
                disabled={isLoading}
                hidden={isLoading}
              >
                {!isLoading && <KTSVG path='/media/icons/duotune/general/gen027.svg' className='align-self-center' />}
              </button>
            )}
          </td>
        </tr>
      )}
    </>
  )
}

export {OneLineBitrix24}
