import {useParams} from 'react-router-dom'
import {appsAPI} from '../../../../services/AppsService'

const TimeZone = () => {
  const {appId} = useParams()
  const {data: app} = appsAPI.useFetchAppByIdQuery(appId!)

  return (
    <>
      <div className='separator separator-dashed my-5'></div>
      <div className='row mb-5'>
        <label className='col-lg-4 fw-bolder text-gray-600 text-uppercase small'>Time Zone</label>
        <div className='col-lg-8'>{app?.time_zone}</div>
      </div>
    </>
  )
}

export {TimeZone}
