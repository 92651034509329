import {Column} from 'react-table'
import {AmoUser} from '../../core/_models'
import {AmoUserCustomHeader} from './AmoUserCustomHeader'
import {AmoUsersIDCell} from './AmoUsersIDCell'
import {AmoUsersNameCell} from './AmoUsersNameCell'
import {AmoUsersPhoneCell} from './AmoUsersPhoneCell'

const BitrixUsersColumns: Column<AmoUser>[] = [
  {
    Header: (props) => <AmoUserCustomHeader tableProps={props} title='AmoCRM ID' className='min-w-125px' />,
    accessor: 'amocrm_user_id',
    Cell: ({...props}) => <AmoUsersIDCell id={props.data[props.row.index].amocrm_user_id} />,
  },
  {
    Header: (props) => <AmoUserCustomHeader tableProps={props} title='Full Name' className='min-w-125px' />,
    accessor: 'fullname',
    Cell: ({...props}) => <AmoUsersNameCell name={props.data[props.row.index].fullname} />,
  },
  {
    Header: (props) => <AmoUserCustomHeader tableProps={props} title='Internal Number' className='min-w-125px' />,
    accessor: 'internal_number',
    Cell: ({...props}) => <AmoUsersPhoneCell phone={props.data[props.row.index].internal_number} />,
  },
]

export {BitrixUsersColumns}
