import React, {FC} from 'react'

type Props = {
  userAvatar?: string
  className?: string
  imgClassName?: string
  userFullName?: string
}

const Avatar: FC<Props> = ({className = '', imgClassName = '', userAvatar, userFullName}) => {
  return (
    <>
      {userAvatar ? (
        <div className={className}>
          <img src={userAvatar} className={imgClassName} alt='avatar' style={{objectFit: 'cover'}} />
        </div>
      ) : (
        <div className={`overflow-hidden ${className}`}>
          <div className='symbol-label text-uppercase fw-bolder text-gray-700'>{userFullName && userFullName[0]}</div>
        </div>
      )}
    </>
  )
}

export {Avatar}
