import {FC} from 'react'
import {useParams} from 'react-router-dom'
import {KTSVG} from '../../../template/helpers'

type Props = {
  error?: any
}

const EmptyAppPage: FC<Props> = ({error}) => {
  const {appId} = useParams()

  return (
    <>
      <div className='alert alert-danger d-flex align-items-center p-5 mb-10'>
        <KTSVG path='/media/icons/duotune/files/fil024.svg' className='svg-icon-danger svg-icon-2hx me-3' />

        <div className='d-flex flex-column'>
          <h5 className='mb-1'>No Content</h5>
          <span>{`Integration ID #${appId} not found`}</span>
          {Array.isArray(error?.data?.detail) ? (
            <div className='mb-1'>Datail: {error?.data?.detail[0].msg}</div>
          ) : (
            <div className='mb-1'>Datail: {error?.data?.detail}</div>
          )}
        </div>
      </div>
    </>
  )
}

export {EmptyAppPage}
