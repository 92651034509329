import React from 'react'
import * as Yup from 'yup'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {authAPI} from '../../../services/AuthService'

const initialValues = {
  password: '',
  changepassword: '',
}

const UpdatePasswordSchema = Yup.object().shape({
  password: Yup.string().min(8, 'Minimum 8 symbols').max(35, 'Maximum 35 symbols').required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
})

export function UpdatePassword() {
  const {token} = useParams()
  const navigate = useNavigate()
  const [updatePassword, {isLoading: loading, isError, isSuccess}] = authAPI.useUpdatePasswordMutation()

  const formik = useFormik({
    initialValues,
    validationSchema: UpdatePasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      await updatePassword({token: token as string, password: values.password, confirm_password: values.changepassword})
        .unwrap()
        .then((response) => {
          setStatus(response?.detail)
        })
        .catch((err) => {
          setSubmitting(false)
          setStatus(err.data.detail)
        })
        .finally(() => {
          setTimeout(() => {
            navigate('/login', {replace: true})
          }, 7000)
        })
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Update Password</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>Change your password.</div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {formik.status && (
          <>
            {isError && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>
                  Sorry, looks like there are some errors detected, please try again.
                </div>
              </div>
            )}
            {!isError && formik.status !== 'Bad Request' && (
              <div className='mb-10 bg-light-info p-8 rounded'>
                <div className='text-info'>{formik.status}</div>
              </div>
            )}
          </>
        )}

        {/* end::Title */}

        <div className='fv-row mb-6'>
          <label htmlFor='newpassword' className='form-label fw-bolder text-gray-900 fs-6'>
            New Password
          </label>
          <input
            type='password'
            className='form-control form-control-lg form-control-solid '
            {...formik.getFieldProps('password')}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.password}</div>
            </div>
          )}
        </div>

        <div className='fv-row mb-10'>
          <label htmlFor='confirmpassword' className='form-label fs-6 fw-bolder'>
            Confirm New Password
          </label>
          <input
            type='password'
            className='form-control form-control-lg form-control-solid '
            {...formik.getFieldProps('changepassword')}
          />
          {formik.touched.changepassword && formik.errors.changepassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.changepassword}</div>
            </div>
          )}
        </div>

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
            disabled={formik.isSubmitting || !formik.isValid || isSuccess}
          >
            {!loading && <span className='indicator-label'>Submit</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
