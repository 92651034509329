const Bitrix24UsersInServiceHeader = () => {
  return (
    <thead>
      <tr className='text-muted fw-bolder fs-8 text-uppercase gs-0 text-center'>
        <th role='columnheader' className='min-w-70px text-end'>
          active
        </th>
        <th role='columnheader' className='min-w-100px px-0'>
          user id
        </th>
        <th role='columnheader' className='min-w-150px px-0'>
          name
        </th>
        <th role='columnheader' className='min-w-150px px-0 required'>
          number
        </th>
        <th role='columnheader' className='min-w-150px px-0 required'>
          mobile
        </th>
        <th role='columnheader' className='min-w-100px px-0'>
          smart call
        </th>
        <th role='columnheader' className='min-w-100px px-0 required'>
          manager time
        </th>
        <th role='columnheader' className='min-w-70px px-0'>
          chat
        </th>
        <th role='columnheader' className='min-w-70px px-0 required'>
          aa
        </th>
        <th role='columnheader' className='min-w-70px px-0 required'>
          mr
        </th>
        <th role='columnheader' className='min-w-100px px-0'>
          edit
        </th>
      </tr>
    </thead>
  )
}

export {Bitrix24UsersInServiceHeader}
