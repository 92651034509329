/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {KTSVG} from '../../../helpers'
import * as Yup from 'yup'
import {IBitrix24LineNew, Bitrix24LineNewInitValues} from '../../../../modules/apps/core/_models'
import {FormikState, useFormik} from 'formik'
import {useParams} from 'react-router-dom'
import {appsAPI} from '../../../../services/AppsService'
import {IBitrixLineModel} from '../../../../types/apps/crm/biitrix24/BitrixLineModel'
import {IAppsModel} from '../../../../types/apps/AppsModel'

const addLineBitrix24SettingsEditShema = Yup.object().shape({
  line_newline: Yup.string().required('Line is required'),
  description_newline: Yup.string(),
  responsible_user_newline: Yup.string().required('User ID is required'),
  smart_call_newline: Yup.boolean(),
  b24_manager_time_newline: Yup.boolean(),
  start_time_newline: Yup.string(),
  end_time_newline: Yup.string(),
  auto_lead_newline: Yup.boolean(),
  chat_newline: Yup.boolean(),
})

const AddLineBitrix24: FC = () => {
  const {appId} = useParams()
  const {data: app} = appsAPI.useFetchAppByIdQuery(appId!)
  const [updateApp, {isLoading}] = appsAPI.useUpdateAppMutation()
  const [smartCallState, updateSmartCallState] = useState(false)
  const [b24TimeState, updateb24TimeState] = useState(false)
  const [b24TimeDisabledState, updateb24TimeDisabledState] = useState(true)
  const [timeState, updateTimeState] = useState(true)
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const all_lines: IBitrixLineModel[] = app?.settings.bitrix24.lines!
  let obj: IBitrixLineModel[] = []

  const checkDID = (did: string) => {
    const isInclude = all_lines.filter((l) => l.line === did)
    if (did === '' || did === 'all lines' || isInclude.length > 0) {
      if (did === '') {
        setErrorMessage('Line is required')
      } else {
        setErrorMessage('This DID is already in used')
      }
      setSubmitBtnDisabled(true)
    } else {
      setErrorMessage('')
      setSubmitBtnDisabled(false)
    }
  }

  const checkSMCallDisabled = (checked: boolean) => {
    updateSmartCallState(checked)
    const b24Time = document.getElementById('b24TimeNewSwitch')
    if (smartCallState === true) {
      if (b24TimeState === true) {
        b24Time?.click()
      }
      updateb24TimeDisabledState(true)
      updateTimeState(true)
    } else if (smartCallState === false) {
      updateTimeState(false)
      updateb24TimeDisabledState(false)
    }
  }

  const checkTimeDisabled = (checked: boolean) => {
    updateb24TimeState(checked)
    if (b24TimeState === false) {
      updateTimeState(true)
    } else {
      updateTimeState(false)
    }
  }

  const formik = useFormik<IBitrix24LineNew>({
    initialValues: Bitrix24LineNewInitValues,
    validationSchema: addLineBitrix24SettingsEditShema,
    onSubmit: (values) => {
      const new_line = {
        line: values.line_newline.trim(),
        description: values.description_newline,
        responsible_user: values.responsible_user_newline,
        smart_call: values.smart_call_newline,
        b24_manager_time: values.b24_manager_time_newline,
        start_time: values.start_time_newline,
        end_time: values.end_time_newline,
        auto_lead: values.auto_lead_newline,
        chat: values.chat_newline,
      }
      obj = [...all_lines, new_line]

      const data = {
        id: appId,
        settings: {
          bitrix24: {
            lines: obj,
          },
        },
      }
      updateApp(data as IAppsModel)
        .unwrap()
        .then(() => {
          const smartCallNewSwitch = document.getElementById('smartCallNewSwitch')
          const b24TimeNewSwitch = document.getElementById('b24TimeNewSwitch')
          const autoLeadNewSwitch = document.getElementById('autoLeadNewSwitch')
          const chatNewSwitch = document.getElementById('chatNewSwitch')

          if (smartCallNewSwitch?.getAttribute('value') === 'true') {
            smartCallNewSwitch?.click()
          }

          if (b24TimeNewSwitch?.getAttribute('value') === 'true') {
            b24TimeNewSwitch?.click()
          }

          if (autoLeadNewSwitch?.getAttribute('value') === 'true') {
            autoLeadNewSwitch?.click()
          }

          if (chatNewSwitch?.getAttribute('value') === 'true') {
            chatNewSwitch?.click()
          }
        })
      formik.resetForm({...formik.initialValues} as Partial<FormikState<IBitrix24LineNew>>)
      const close = document.getElementById('close-modal')
      close?.click()
    },
  })

  return (
    <form onSubmit={formik.handleSubmit} noValidate className='form'>
      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog modal-dialog-centered mw-700px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2>Create new line</h2>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
              </div>
            </div>
            <div className='modal-body'>
              <div className='flex-row-fluid py-5 px-15'>
                <div className='current' data-kt-stepper-element='content'>
                  <div className='col-12'>
                    <div className='mb-10'>
                      <div className='d-flex flex-stack'>
                        <label className='d-flex align-items-center col-5 self-align-center required'>
                          <span className='small text-uppercase fw-bolder text-gray-600'>Line (DID)</span>
                        </label>
                        <input
                          type='text'
                          className='form-control form-control-solid'
                          placeholder='DID'
                          onChange={(e) => {
                            checkDID(e.target.value.toLowerCase().trim())
                            formik.setFieldValue('line_newline', e.target.value.trim())
                          }}
                        />
                      </div>
                      {errorMessage !== '' && (
                        <div className='d-flex justify-content-end'>
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger'>{errorMessage}</div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='mb-10 d-flex flex-stack'>
                      <label className='d-flex align-items-center col-5 self-align-center'>
                        <span className='small text-uppercase fw-bolder text-gray-600'>Description</span>
                      </label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='Description'
                        {...formik.getFieldProps('description_newline')}
                      />
                    </div>
                    <div className='mb-10'>
                      <div className='d-flex flex-stack'>
                        <div className='stepper-label align-items-center col-5 self-align-center'>
                          <h3 className='small text-uppercase fw-bolder text-gray-600 required'>Bitrix user ID</h3>
                          <div className='text-muted fs-8'>For missed calls</div>
                        </div>
                        <input
                          type='text'
                          className='form-control form-control-solid'
                          placeholder='user ID'
                          {...formik.getFieldProps('responsible_user_newline')}
                        />
                      </div>
                      <div className='d-flex justify-content-end'>
                        {formik.touched.responsible_user_newline && formik.errors.responsible_user_newline && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger'>{formik.errors.responsible_user_newline}</div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='mb-10 d-flex'>
                      <div className='d-flex me-15 col-4 flex-stack'>
                        <label className='d-flex align-items-center self-align-center me-5'>
                          <span className='small text-uppercase fw-bolder text-gray-600'>Smart Call</span>
                        </label>
                        <div className='d-flex align-items-center'>
                          <div className='form-check form-switch form-check-custom form-check-solid '>
                            <input
                              className='form-check-input h-20px w-30px align-self-center'
                              type='checkbox'
                              id='smartCallNewSwitch'
                              onClick={(e: any) => {
                                checkSMCallDisabled(e.target.checked)
                              }}
                              {...formik.getFieldProps('smart_call_newline')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='d-flex flex-stack col-5'>
                        <label className='d-flex align-items-center self-align-center me-5'>
                          <span className='small text-uppercase fw-bolder text-gray-600'>Manager time in Bitrix24</span>
                        </label>
                        <div className='d-flex align-items-center'>
                          <div className='form-check form-switch form-check-custom form-check-solid'>
                            <input
                              className='form-check-input h-20px w-30px align-self-center'
                              type='checkbox'
                              id='b24TimeNewSwitch'
                              disabled={b24TimeDisabledState}
                              onClick={(e: any) => {
                                checkTimeDisabled(e.target.checked)
                              }}
                              {...formik.getFieldProps('b24_manager_time_newline')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='mb-10 d-flex flex-stack'>
                      <label className='d-flex align-items-center self-align-center col-5'>
                        <span className='small text-uppercase fw-bolder text-gray-600'>Time</span>
                      </label>
                      <div className='d-flex align-items-center col-7'>
                        <input
                          type='text'
                          className='form-control form-control-solid me-5'
                          placeholder='09:00'
                          disabled={timeState}
                          {...formik.getFieldProps('start_time_newline')}
                        />
                        -
                        <input
                          type='text'
                          className='form-control form-control-solid ms-5'
                          placeholder='18:00'
                          disabled={timeState}
                          {...formik.getFieldProps('end_time_newline')}
                        />
                      </div>
                    </div>

                    <div className='mb-10 d-flex'>
                      <div className='d-flex me-15 col-4 flex-stack'>
                        <label className='d-flex align-items-center self-align-center me-5'>
                          <span className='small text-uppercase fw-bolder text-gray-600'>Auto Lead (Deal)</span>
                        </label>
                        <div className='d-flex align-items-center'>
                          <div className='form-check form-switch form-check-custom form-check-solid '>
                            <input
                              className='form-check-input h-20px w-30px align-self-center'
                              type='checkbox'
                              id='autoLeadNewSwitch'
                              {...formik.getFieldProps('auto_lead_newline')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='d-flex flex-stack col-3'>
                        <label className='d-flex align-items-center self-align-center me-5'>
                          <span className='small text-uppercase fw-bolder text-gray-600'>Chat</span>
                        </label>
                        <div className='d-flex align-items-center'>
                          <div className='form-check form-switch form-check-custom form-check-solid'>
                            <input
                              className='form-check-input h-20px w-30px align-self-center'
                              type='checkbox'
                              id='chatNewSwitch'
                              {...formik.getFieldProps('chat_newline')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <div className='card-footer d-flex justify-content-end py-2 px-1' style={{border: 'none'}}>
                <button type='submit' className='btn btn-sm btn-primary me-3' disabled={isLoading || submitBtnDisabled}>
                  {!isLoading && 'Save Changes'}
                  {isLoading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
                <button
                  type='button'
                  className='btn btn-sm btn-light-primary align-self-center'
                  id='close-modal'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export {AddLineBitrix24}
