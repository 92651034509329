import {BitrixUsersTable} from './table/BitrixUsersTable'

const BitrixAllUsers = () => {
  return (
    <div>
      <div className='card card-custom card-stretch shadow-sm mb-5'>
        <div className='card-header mt-5'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Users</h3>
          </div>
        </div>
        <div className='card-body'>
          <BitrixUsersTable />
        </div>
      </div>
    </div>
  )
}

export {BitrixAllUsers}
