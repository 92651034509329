import {useState} from 'react'
import {useParams} from 'react-router-dom'
import {appsAPI} from '../../../../../services/AppsService'
import {KTSVG} from '../../../../../template/helpers'
import {IOutgoingCallModeUpdate, OutgoingCallModeInitValues} from '../../../core/_models'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {IAppsModel} from '../../../../../types/apps/AppsModel'

const OutgoingCallModeSettingsEditShema = Yup.object().shape({
  outgoing_call_mode: Yup.string(),
})

const OutgoingCall = () => {
  const {appId} = useParams()
  const {data: app} = appsAPI.useFetchAppByIdQuery(appId!)
  const [outgoingCallState, updateOutgoingCallState] = useState(false)
  const [updateApp, {isLoading}] = appsAPI.useUpdateAppMutation()

  const outgoing_call_mode =
    app?.settings.amocrm.outgoing_call_mode.none === true
      ? 'None'
      : app?.settings.amocrm.outgoing_call_mode.contact === true
      ? 'Contact'
      : app?.settings.amocrm.outgoing_call_mode.contact_lead === true
      ? 'ContactLead'
      : app?.settings.amocrm.outgoing_call_mode.unsorted === true
      ? 'Unsorted'
      : ''

  const formik = useFormik<IOutgoingCallModeUpdate>({
    initialValues: Object.assign(OutgoingCallModeInitValues, {outgoing_call_mode: outgoing_call_mode}),
    validationSchema: OutgoingCallModeSettingsEditShema,
    onSubmit: async (values) => {
      const data = {
        id: appId,
        settings: {
          amocrm: {
            outgoing_call_mode: {
              none: values.outgoing_call_mode === 'None' ? true : false,
              contact: values.outgoing_call_mode === 'Contact' ? true : false,
              unsorted: values.outgoing_call_mode === 'Unsorted' ? true : false,
              contact_lead: values.outgoing_call_mode === 'ContactLead' ? true : false,
            },
          },
        },
      }
      await updateApp(data as IAppsModel)
      updateOutgoingCallState(false)
    },
  })

  return (
    <>
      {outgoingCallState === false && (
        <div className='card card-custom card-stretch shadow-sm mb-5'>
          <div className='card-header'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Outgoing Lines</h3>
            </div>
            <button className='btn btn-sm btn-primary align-self-center' onClick={() => updateOutgoingCallState(true)}>
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-info me-1 align-self-center' />
              Edit
            </button>
          </div>
          <div className='card-body pt-5 pb-5'>
            <div className='row mb-5'>
              <label className='col-lg-4 fw-bolder text-gray-600 small text-uppercase'>Outgoing call mode</label>

              <div className='col-lg-8 d-flex align-items-center'>
                <span className='badge badge-light-primary'>
                  {outgoing_call_mode === 'ContactLead' ? 'Contact + Lead' : outgoing_call_mode}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {outgoingCallState === true && (
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card card-custom card-stretch shadow-sm mb-5'>
            <div className='card-header'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Edit Outgoing Lines</h3>
              </div>
            </div>
            <div className='card-body pt-5 pb-5'>
              <div className='row mb-5'>
                <label className='col-lg-4 fw-bolder text-gray-600 small align-self-center text-uppercase'>
                  Outgoing call mode
                </label>

                <div className='col-lg-8'>
                  <select
                    className='form-select form-select-solid form-select-lg text-gray-900 align-self-center'
                    {...formik.getFieldProps('outgoing_call_mode')}
                  >
                    <option value='None'>None</option>
                    <option value='Unsorted'>Unsorted</option>
                    <option value='Contact'>Contact</option>
                    <option value='ContactLead'>Contact + Lead</option>
                  </select>
                </div>
              </div>
              <div className='card-footer d-flex justify-content-end py-2 px-1' style={{border: 'none'}}>
                <button type='submit' className='btn btn-sm btn-primary me-3' disabled={isLoading}>
                  {!isLoading && 'Save Changes'}
                  {isLoading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
                <button
                  className='btn btn-sm btn-light-primary align-self-center'
                  onClick={() => {
                    updateOutgoingCallState(false)
                    formik.resetForm()
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  )
}

export {OutgoingCall}
