import {useParams} from 'react-router-dom'
import {appsAPI} from '../../../../services/AppsService'

const IgnoreLines = () => {
  const {appId} = useParams()
  const {data: app} = appsAPI.useFetchAppByIdQuery(appId!)
  return (
    <>
      <div className='row mb-5'>
        <label className='col-lg-4 fw-bolder text-gray-600 text-uppercase small'>Ignore lines (DID) Numbers</label>

        <div className='col-lg-8'>
          {app?.settings.ignore_trunks &&
            app?.settings.ignore_trunks.map((ignoreTrunk) => (
              <span className='badge badge-secondary me-3 mb-3' key={ignoreTrunk.trunk}>
                {ignoreTrunk.trunk}
              </span>
            ))}
        </div>
      </div>
    </>
  )
}

export {IgnoreLines}
