/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useLayout} from '../core'

const Footer: FC = () => {
  const {classes} = useLayout()
  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row text-center align-items-center justify-content-center`}
      >
        <div className='text-dark order-2 order-md-1'>
          <span className='text-muted fw-bold me-1'>{new Date().getFullYear()} &copy;</span>
          <Link to='/' className='text-gray-800 text-hover-primary'>
            callbee.io
          </Link>
        </div>
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Footer}
