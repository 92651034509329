/* eslint-disable jsx-a11y/anchor-is-valid */
import {TimeZoneOptions} from '../../../helpers/components/TimeZoneOptions'
import {Formik, Form, FormikValues, Field, ErrorMessage} from 'formik'
import {replaceProtokol} from '../../../helpers/ReplaceProtokol'
import {useQueryRequestCreateApp} from './QueryRequestProvider'
import {StepperComponent} from '../../../assets/ts/components'
import React, {FC, useEffect, useRef, useState} from 'react'
import {appsAPI} from '../../../../services/AppsService'
import {crmAPI} from '../../../../services/CRMService'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {ICreateApp, inits} from './IAppModels'
import * as Yup from 'yup'
import clsx from 'clsx'

const createAppSchema = [
  Yup.object({
    crm: Yup.string().required().label('CRM'),
    domain: Yup.string().required().label('Domain'),
    pbx: Yup.string().required().label('PBX'),
  }),
  Yup.object({
    ami_host: Yup.string().required().label('Host'),
    ami_username: Yup.string().required().label('Username'),
    ami_secret: Yup.string().required().label('Secret'),
  }),
  Yup.object({
    ignore_trunks: Yup.string().label('Ignore trunks'),
    record_url: Yup.string().label('Record URL'),
    /*usersInService: Yup.boolean().label('User in service'),*/
    api_is_active: Yup.boolean().label('FTP/API'),
    mp3_is_active: Yup.boolean().label('MP3'),
    yeastar_host: Yup.string().label('FTP/API host'),
    yeastar_username: Yup.string().label('FTP/API username'),
    yeastar_secret: Yup.string().label('FTP/API secret'),
    ftp_path: Yup.string().label('FTP path'),
  }),
  Yup.object({
    manager_id: Yup.string().required().label('Manager ID'),
  }),
  Yup.object({
    region: Yup.string().required().label('Region'),
    auto_mode: Yup.boolean().required().label('Auto mode'),
    time_zone: Yup.string().required().label('Time zone'),
  }),
]

const Main: FC = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAppSchema[0])
  const [initValues] = useState<ICreateApp>(inits)
  const {state, updateState} = useQueryRequestCreateApp()
  const [protocol, updateProtocol] = useState('https://')
  const [createApp, {isLoading}] = appsAPI.useCreateAppMutation()
  const [checkBitrix24Tokens, {isFetching: isFetchingBitrix24TryAgain}] = crmAPI.useLazyCheckBitrix24TokensQuery()
  const [checkAmoCRMTokens, {isFetching: isFetchingAmoCRMTryAgain}] = crmAPI.useLazyCheckAmoCRMTokensQuery()
  const closeButton = document.getElementById('CloseModalCreateApp')
  const [showPasswordAMI, setShowPasswordAMI] = useState(false)
  const [showPasswordRecord, setShowPasswordRecord] = useState(false)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    updateState({error: '', create: false, tokens_bitrix24: '', error_tokens_bitrix24: false, tokens_amocrm: ''})
    stepper.current.goPrev()

    if (state.yeastar_series === 'P' && stepper.current.currentStepIndex > 1) {
      setCurrentSchema(createAppSchema[stepper.current.currentStepIndex])
    } else {
      setCurrentSchema(createAppSchema[stepper.current.currentStepIndex - 1])
    }
  }

  const check_ignore_trunks = (ignore_trunks: string) => {
    let ignore_trunks_edit = ignore_trunks.replace(/[.:;*-/,%]/g, '')
    ignore_trunks_edit = ignore_trunks.replace('  ', ' ')
    return ignore_trunks_edit
  }

  const get_list_ignore_trunks = (str_ignore_trunks: string) => {
    let list_ignore_trunks = str_ignore_trunks.split(' ')
    return list_ignore_trunks
  }

  const check_outgoing_call = (outgoing_call: string) => {
    return {
      none: outgoing_call === 'None' ? true : false,
      unsorted: outgoing_call === 'Unsorted' ? true : false,
      contact: outgoing_call === 'Contact' ? true : false,
      contact_lead: outgoing_call === 'ContactLead' ? true : false,
    }
  }

  const submitStep = (values: ICreateApp, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }
    if (state.yeastar_series === 'P' && stepper.current.currentStepIndex > 0) {
      setCurrentSchema(createAppSchema[stepper.current.currentStepIndex + 1])
    } else {
      setCurrentSchema(createAppSchema[stepper.current.currentStepIndex])
    }
    if (
      (state.yeastar_series !== 'P' &&
        stepper.current.currentStepIndex !== stepper.current.totatStepsNumber - 1 &&
        stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) ||
      (state.yeastar_series === 'P' &&
        stepper.current.currentStepIndex !== stepper.current.totatStepsNumber - 2 &&
        stepper.current.currentStepIndex !== stepper.current.totatStepsNumber - 1)
    ) {
      stepper.current.goNext()
    } else if (state.crm === 'BITRIX24') {
      checkBitrix24Tokens(state.domain)
        .unwrap()
        .then((response) => {
          if (response) {
            createApp({
              crm_domain: state.domain,
              crm: state.crm,
              pbx: state.pbx,
              region: values.region,
              is_auto_mode: values.auto_mode,
              time_zone: values.time_zone,
              settings: {
                ami: {
                  host: state.yeastar_series !== 'P' ? values.ami_host : '0.0.0.0',
                  username: state.yeastar_series !== 'P' ? values.ami_username : 'default',
                  secret: state.yeastar_series !== 'P' ? values.ami_secret.trim() : 'default',
                },
                asterisk: {record_url: values.record_url},
                yeastar: {
                  series: state.yeastar_series,
                  api_is_active: state.yeastar_series !== 'P' ? values.api_is_active : true,
                  mp3_is_active: values.mp3_is_active,
                  host: state.api_active ? protocol + replaceProtokol(values.yeastar_host) : values.yeastar_host,
                  username: values.yeastar_username,
                  secret: values.yeastar_secret.trim(),
                  ftp_path: state.yeastar_series !== 'P' ? values.ftp_path : '',
                },
                ignore_trunks: get_list_ignore_trunks(values.ignore_trunks),
                bitrix24: {
                  users_in_service: false,
                  lines: [
                    {
                      line: '',
                      description: '',
                      responsible_user: values.manager_id,
                      smart_call: values.line_smcall_b24_0,
                      b24_manager_time: values.line_b24time_b24_0,
                      start_time: values.line_b24time_start_b24_0,
                      end_time: values.line_b24time_end_b24_0,
                      auto_lead: values.line_autolead_b24_0,
                      chat: values.line_chat_b24_0,
                      groups: [],
                    },
                  ],
                },
              },
            })
              .unwrap()
              .then(() => {
                updateState({
                  error: '',
                  create: true,
                  tokens_bitrix24: 'true',
                  error_tokens_bitrix24: false,
                  tokens_amocrm: '',
                })
                actions.resetForm()
                setTimeout(() => {
                  updateState({error: '', create: false, tokens_bitrix24: ''})
                  stepper?.current?.goto(1)
                  closeButton?.click()
                }, 1500)
              })
              .catch((err) => {
                updateState({error: err.data.detail, create: false})
              })
          } else {
            updateState({tokens_bitrix24: 'false'})
          }
        })
        .catch(() => {
          updateState({error_tokens_bitrix24: true})
        })
      if (
        (state.yeastar_series !== 'P' && stepper.current.currentStepIndex === stepper.current.totatStepsNumber - 1) ||
        (state.yeastar_series === 'P' && stepper.current.currentStepIndex === stepper.current.totatStepsNumber - 2)
      ) {
        stepper.current.goNext()
      }
    } else if (state.crm === 'AMOCRM') {
      checkAmoCRMTokens(state.domain)
        .unwrap()
        .then((response) => {
          if (response) {
            createApp({
              crm_domain: state.domain,
              crm: state.crm,
              pbx: state.pbx,
              region: values.region,
              is_auto_mode: values.auto_mode,
              time_zone: values.time_zone,
              settings: {
                ami: {host: values.ami_host, username: values.ami_username, secret: values.ami_secret.trim()},
                asterisk: {record_url: values.record_url},
                yeastar: {
                  series: 'S',
                  api_is_active: values.api_is_active,
                  mp3_is_active: values.mp3_is_active,
                  host: state.api_active ? protocol + values.yeastar_host : values.yeastar_host,
                  username: values.yeastar_username,
                  secret: values.yeastar_secret.trim(),
                  ftp_path: values.ftp_path,
                },
                ignore_trunks: get_list_ignore_trunks(values.ignore_trunks),
                amocrm: {
                  outgoing_call_mode: check_outgoing_call(state.outgoing_call),
                  lines: [
                    {
                      line: '',
                      description: '',
                      pipeline: 'Main',
                      responsible_user: values.manager_id,
                      incoming_call_mode: check_outgoing_call(values.line_incomingcall_amo_0),
                      smart_call: values.line_smcall_amo_0,
                      start_time: values.line_smtime_start_amo_0,
                      end_time: values.line_smtime_end_amo_0,
                      udpc: values.line_unsorted_amo_0,
                      repeat_sales_mode: values.line_rsm_amo_0,
                      find_unsorted: values.line_findunsorted_amo_0,
                    },
                  ],
                },
              },
            })
              .unwrap()
              .then(() => {
                updateState({
                  error: '',
                  create: true,
                  tokens_bitrix24: '',
                  error_tokens_bitrix24: false,
                  tokens_amocrm: 'true',
                })
                actions.resetForm()
                setTimeout(() => {
                  updateState({error: '', create: false, tokens_amocrm: ''})
                  stepper?.current?.goto(1)
                  closeButton?.click()
                }, 1500)
              })
              .catch((err) => {
                updateState({error: err.data.detail, create: false})
              })
          } else {
            updateState({tokens_amocrm: 'false'})
          }
        })
      if (
        (state.yeastar_series !== 'P' && stepper.current.currentStepIndex === stepper.current.totatStepsNumber - 1) ||
        (state.yeastar_series === 'P' && stepper.current.currentStepIndex === stepper.current.totatStepsNumber - 2)
      ) {
        stepper.current.goNext()
      }
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }
    loadStepper()
  }, [stepperRef])

  return (
    <div className='modal fade' id='kt_modal_create_app' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered mw-900px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>Create Integration</h2>

            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              id='CloseModalCreateApp'
              data-bs-dismiss='modal'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body py-10 px-10 px-sm-2'>
            <div
              ref={stepperRef}
              className='stepper stepper-pills stepper-column d-flex flex-xl-row flex-row-fluid'
              id='kt_modal_create_app_stepper'
            >
              <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-200px w-xl-300px me-10'>
                <div className='stepper-nav ps-10'>
                  <div className='stepper-item current' data-kt-stepper-element='nav'>
                    <div className='stepper-wrapper'>
                      <div className='stepper-icon w-40px h-40px'>
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'>1</span>
                      </div>

                      <div className='stepper-label'>
                        <h3 className='stepper-title text-uppercase'>CRM & PBX</h3>
                        <div className='stepper-desc'>Your CRM and PBX</div>
                      </div>
                    </div>
                    <div className='stepper-line h-40px'></div>
                  </div>
                  {state.yeastar_series !== 'P' && (
                    <>
                      <div className='stepper-item' data-kt-stepper-element='nav'>
                        <div className='stepper-wrapper'>
                          <div className='stepper-icon w-40px h-40px'>
                            <i className='stepper-check fas fa-check'></i>
                            <span className='stepper-number'>2</span>
                          </div>

                          <div className='stepper-label'>
                            <h3 className='stepper-title text-uppercase'>AMI</h3>
                            <div className='stepper-desc'>Connect to AMI PBX</div>
                          </div>
                        </div>
                        <div className='stepper-line h-40px'></div>
                      </div>
                    </>
                  )}

                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    <div className='stepper-wrapper'>
                      <div className='stepper-icon w-40px h-40px'>
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'>{state.yeastar_series === 'P' ? 2 : 3}</span>
                      </div>
                      <div className='stepper-label'>
                        <h3 className='stepper-title text-uppercase'>
                          {state.pbx === 'ASTERISK' && 'Asterisk'}
                          {state.pbx === 'YEASTAR' && 'Yeastar'}
                        </h3>
                        <div className='stepper-desc'>
                          {state.pbx === 'ASTERISK' && 'Asterisk settings'}
                          {state.pbx === 'YEASTAR' && 'Yeastar settings'}
                        </div>
                      </div>
                    </div>
                    <div className='stepper-line h-40px'></div>
                  </div>

                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    <div className='stepper-wrapper'>
                      <div className='stepper-icon w-40px h-40px'>
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'>{state.yeastar_series === 'P' ? 3 : 4}</span>
                      </div>

                      <div className='stepper-label'>
                        <h3 className='stepper-title text-uppercase'>Main line</h3>
                        <div className='stepper-desc'>Main line settings</div>
                      </div>
                    </div>
                    <div className='stepper-line h-40px'></div>
                  </div>

                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    <div className='stepper-wrapper'>
                      <div className='stepper-icon w-40px h-40px'>
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'>{state.yeastar_series === 'P' ? 4 : 5}</span>
                      </div>

                      <div className='stepper-label'>
                        <h3 className='stepper-title text-uppercase'>Region</h3>
                        <div className='stepper-desc'>Location settings</div>
                      </div>
                    </div>
                  </div>
                  <div className='stepper-item d-none' data-kt-stepper-element='nav'>
                    <div className='stepper-wrapper'>
                      <div className='stepper-icon w-40px h-40px'>
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'>{state.yeastar_series === 'P' ? 5 : 6}</span>
                      </div>

                      <div className='stepper-label'>
                        <h3 className='stepper-title text-uppercase'>Submit</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex-row-fluid py-5 px-15'>
                <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
                  {() => (
                    <Form className='form' noValidate id='kt_modal_create_app_form'>
                      <div className='current' data-kt-stepper-element='content'>
                        <div className='col-12'>
                          <div className='mb-10 d-flex flex-stack'>
                            <label className='d-flex align-items-center col-5 self-align-center'>
                              <span className='small text-uppercase fw-bolder text-gray-600'>CRM System</span>
                            </label>
                            <select
                              name='crm'
                              className='form-select form-select-lg form-select-solid'
                              onChange={(e) => updateState({crm: e.target.value})}
                            >
                              <option value='BITRIX24'>BITRIX24</option>
                              {/* <option value='AMOCRM'>AMOCRM</option> */}
                            </select>
                          </div>
                          <div className='mb-10'>
                            <div className='d-flex flex-stack'>
                              <label className='d-flex align-items-center col-5'>
                                <span className='small text-uppercase fw-bolder text-gray-600 required'>
                                  {state.crm === 'BITRIX24' && 'BITRIX24'}
                                  {state.crm === 'AMOCRM' && 'AMOCRM'} Domain
                                </span>
                              </label>
                              <Field
                                name='domain'
                                className='form-control form-control-solid'
                                placeholder={
                                  state.crm === 'BITRIX24'
                                    ? 'example: callbee.bitrix24.ru'
                                    : 'example: callbee.amocrm.ru'
                                }
                                value={state.domain}
                                onInput={(e: {target: {value: string}}) =>
                                  updateState({
                                    domain: e.target.value
                                      .replace('http:', '')
                                      .replace('https:', '')
                                      .replaceAll('/', ''),
                                  })
                                }
                              />
                            </div>
                            <div className='text-danger d-flex flex-stack mb-4'>
                              <div className='col-4'></div>
                              <ErrorMessage name='domain' />
                            </div>
                          </div>

                          <div className='d-flex flex-stack mb-10'>
                            <label className='d-flex align-items-center col-5'>
                              <span className='small text-uppercase fw-bolder text-gray-600'>PBX</span>
                            </label>
                            <select
                              name='pbx'
                              className='form-select form-select-lg form-select-solid'
                              onChange={(e) => updateState({pbx: e.target.value, yeastar_series: 'S'})}
                            >
                              <option value='ASTERISK'>ASTERISK</option>
                              <option value='YEASTAR'>YEASTAR</option>
                            </select>
                          </div>
                          {state.pbx === 'YEASTAR' && (
                            <>
                              <div className='d-flex flex-stack mb-10'>
                                <label className='d-flex align-items-center col-5'>
                                  <span className='small text-uppercase fw-bolder text-gray-600'>Yeastar</span>
                                </label>
                                <select
                                  name='pbx'
                                  className='form-select form-select-lg form-select-solid'
                                  onChange={(e) =>
                                    updateState({
                                      yeastar_series: e.target.value,
                                      api_active: e.target.value === 'P' ? true : false,
                                    })
                                  }
                                >
                                  <option value='S'>S-Series</option>
                                  <option value='P'>P-Series</option>
                                </select>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      {state.yeastar_series !== 'P' && (
                        <>
                          <div data-kt-stepper-element='content'>
                            <div className='col-12'>
                              <div className='mb-10'>
                                <div className='d-flex flex-stack'>
                                  <label className='d-flex align-items-center col-5'>
                                    <span className='small text-uppercase fw-bolder text-gray-600 required'>
                                      AMI host
                                    </span>
                                  </label>
                                  <Field
                                    name='ami_host'
                                    className='form-control form-control-solid'
                                    placeholder='AMI Host, pbx.callbee.io:5038'
                                  />
                                </div>
                                <div className='text-danger d-flex flex-stack mb-4'>
                                  <div className='col-4'></div>
                                  <ErrorMessage name='ami_host' />
                                </div>
                              </div>
                              <div className='mb-10'>
                                <div className='d-flex flex-stack'>
                                  <label className='d-flex align-items-center col-5'>
                                    <span className='small text-uppercase fw-bolder text-gray-600 required'>
                                      AMI username
                                    </span>
                                  </label>
                                  <Field
                                    name='ami_username'
                                    className='form-control form-control-solid'
                                    placeholder='AMI Username'
                                  />
                                </div>
                                <div className='text-danger d-flex flex-stack mb-4'>
                                  <div className='col-4'></div>
                                  <ErrorMessage name='ami_username' />
                                </div>
                              </div>
                              <div className='mb-10'>
                                <div className='d-flex flex-stack'>
                                  <label className='d-flex align-items-center col-5'>
                                    <span className='small text-uppercase fw-bolder text-gray-600 required'>
                                      AMI secret
                                    </span>
                                  </label>
                                  <div className='input-group input-group-solid'>
                                    <Field
                                      name='ami_secret'
                                      type={showPasswordAMI ? 'text' : 'password'}
                                      className='form-control form-control-solid'
                                      placeholder='AMI Secret'
                                    />
                                    <span
                                      className='input-group-text border-0'
                                      style={{cursor: 'pointer'}}
                                      onClick={() => setShowPasswordAMI(!showPasswordAMI)}
                                    >
                                      <i
                                        className={clsx('bi text-gray-800 fs-3', {
                                          'bi-eye-fill': !showPasswordAMI,
                                          'bi-eye-slash-fill': showPasswordAMI,
                                        })}
                                      ></i>
                                    </span>
                                  </div>
                                </div>
                                <div className='text-danger d-flex flex-stack mb-4'>
                                  <div className='col-4'></div>
                                  <ErrorMessage name='ami_secret' />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      <div data-kt-stepper-element='content'>
                        <div className='col-12'>
                          {state.pbx === 'ASTERISK' && (
                            <div className='mb-10'>
                              <div className='d-flex flex-stack'>
                                <label className='d-flex align-items-center col-5'>
                                  <span className='small text-uppercase fw-bolder text-gray-600'>Record URL</span>
                                </label>
                                <Field
                                  name='record_url'
                                  className='form-control form-control-solid'
                                  placeholder='http://example.com:8080/monitor'
                                />
                              </div>
                              <div className='text-danger d-flex flex-stack mb-4'>
                                <div className='col-4'></div>
                                <ErrorMessage name='record_url' />
                              </div>
                            </div>
                          )}
                          {state.pbx === 'YEASTAR' && (
                            <>
                              {/* <h6 className='mb-8 text-uppercase text-gray-800'>Yeastar Connection</h6> */}
                              <label className='text-center text-gray-500 text-sm mb-10 text-uppercase d-flex flex-center'>
                                Yeastar Connection
                              </label>
                              {state.yeastar_series !== 'P' && (
                                <div className='mb-8 d-flex flex-stack'>
                                  <label className='d-flex align-items-center col-5'>
                                    <span className='small text-uppercase fw-bolder text-gray-600'>FTP / API</span>
                                    <i
                                      className='fas fa-exclamation-circle ms-2 fs-7'
                                      data-bs-toggle='tooltip'
                                      title='Check this switch if you want to use API'
                                    ></i>
                                  </label>
                                  <div className='d-flex col-8'>
                                    <div className='form-check text-align-start form-switch form-check-custom form-check-solid me-10'>
                                      <Field
                                        className='form-check-input h-20px w-30px'
                                        name='api_is_active'
                                        type='checkbox'
                                        onClick={(e: any) => {
                                          updateState({api_active: e.target.checked})
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className='mb-8'>
                                <div className='d-flex flex-stack'>
                                  <label className='d-flex align-items-center col-5'>
                                    <span className='small text-uppercase fw-bolder text-gray-600 required'>
                                      {state.api_active ? 'API' : 'FTP'} Host
                                    </span>
                                  </label>
                                  {state.api_active === true && (
                                    <div className='input-group input-group-solid'>
                                      <select
                                        className='form-select form-select-solid b-none input-group-text text-start mw-100px'
                                        defaultValue={protocol}
                                        onChange={(e: any) => {
                                          updateProtocol(e.target.value)
                                        }}
                                      >
                                        <option value='https://'>https://</option>
                                        <option value='http://'>http://</option>
                                      </select>
                                      <Field
                                        name='yeastar_host'
                                        className='form-control form-control-lg form-control-solid  text-gray-900'
                                        placeholder={
                                          state.api_active ? 'yeastar.callbee.io:8088' : 'yeastar.callbee.io:21'
                                        }
                                        onInput={(e: any) => {
                                          e.target.value = replaceProtokol(e.target.value)
                                        }}
                                      />
                                    </div>
                                  )}
                                  {state.api_active === false && (
                                    <Field
                                      name='yeastar_host'
                                      className='form-control form-control-lg form-control-solid  text-gray-900'
                                      placeholder={
                                        state.api_active ? 'yeastar.callbee.io:8088' : 'yeastar.callbee.io:21'
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              <div className='mb-8'>
                                <div className='d-flex flex-stack'>
                                  <label className='d-flex align-items-center col-5'>
                                    <span className='small text-uppercase fw-bolder text-gray-600 required'>
                                      {state.api_active ? 'API' : 'FTP'} Username
                                    </span>
                                  </label>
                                  <Field
                                    name='yeastar_username'
                                    className='form-control form-control-solid'
                                    placeholder={state.api_active ? 'API Username' : 'FTP Username'}
                                  />
                                </div>
                              </div>
                              <div className='mb-8'>
                                <div className='d-flex flex-stack'>
                                  <label className='d-flex align-items-center col-5'>
                                    <span className='small text-uppercase fw-bolder text-gray-600 required'>
                                      {state.api_active ? 'API' : 'FTP'} Secret
                                    </span>
                                  </label>
                                  <div className='input-group input-group-solid'>
                                    <Field
                                      name='yeastar_secret'
                                      type={showPasswordRecord ? 'text' : 'password'}
                                      className='form-control form-control-solid'
                                      placeholder={state.api_active ? 'API Secret' : 'FTP Secret'}
                                    />
                                    <span
                                      className='input-group-text border-0'
                                      style={{cursor: 'pointer'}}
                                      onClick={() => setShowPasswordRecord(!showPasswordRecord)}
                                    >
                                      <i
                                        className={clsx('bi text-gray-800 fs-3', {
                                          'bi-eye-fill': !showPasswordRecord,
                                          'bi-eye-slash-fill': showPasswordRecord,
                                        })}
                                      ></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {!state.api_active && state.yeastar_series !== 'P' && (
                                <div className='mb-8'>
                                  <div className='d-flex flex-stack'>
                                    <label className='d-flex align-items-center col-5'>
                                      <span className='small text-uppercase fw-bolder text-gray-600'>FTP path</span>
                                    </label>
                                    <Field
                                      name='frp_path'
                                      className='form-control form-control-solid'
                                      placeholder='/ftp_media/mmc/autorecords/'
                                    />
                                  </div>
                                  <div className='text-danger d-flex flex-stack mb-4'>
                                    <div className='col-4'></div>
                                    <ErrorMessage name='ftp_path' />
                                  </div>
                                </div>
                              )}
                              <div className='separator mb-5'></div>
                              {/* <h6 className='mb-8 text-uppercase text-gray-800'>other settings</h6> */}
                              <label className='text-center text-gray-500 text-sm mb-10 text-uppercase d-flex flex-center'>
                                other settings
                              </label>
                              <div className='mb-8 d-flex flex-stack'>
                                <label className='d-flex align-items-center col-5'>
                                  <span className='small text-uppercase fw-bolder text-gray-600'>
                                    Convert record to mp3
                                  </span>
                                  {/* <i className='fas fa-exclamation-circle ms-2 fs-7' data-bs-toggle='tooltip'></i> */}
                                </label>
                                <div className='d-flex col-8'>
                                  <div className='form-check text-align-start form-switch form-check-custom form-check-solid me-10'>
                                    <Field
                                      className='form-check-input h-20px w-30px'
                                      name='mp3_is_active'
                                      type='checkbox'
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          <div className='mb-10'>
                            <div className='d-flex flex-stack'>
                              <label className='d-flex align-items-center col-5'>
                                <span className='small text-uppercase fw-bolder text-gray-600'>Ignore Trunks</span>
                              </label>
                              <Field
                                name='ignore_trunks'
                                className='form-control form-control-solid'
                                placeholder='+3753333333 +375441234567'
                                onInput={(e: any) => {
                                  e.target.value = check_ignore_trunks(e.target.value)
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div data-kt-stepper-element='content'>
                        <div className='col-12'>
                          {state.crm === 'AMOCRM' && (
                            <>
                              <label className='text-center text-gray-500 text-sm mb-10 text-uppercase d-flex flex-center'>
                                Settings for all lines
                              </label>
                              <div className='mb-10'>
                                <div className='d-flex flex-stack'>
                                  <label className='d-flex flex-column align-items-start col-5'>
                                    <span className='small text-uppercase fw-bolder text-gray-600'>Outgoing call</span>
                                    <span className='small text-gray-500'>all lines</span>
                                  </label>
                                  <select
                                    name='outgoing_call_amo'
                                    className='form-select form-select-lg form-select-solid'
                                    onChange={(e) => {
                                      updateState({outgoing_call: e.target.value})
                                    }}
                                    defaultValue={state.outgoing_call}
                                  >
                                    <option value='None'>None</option>
                                    <option value='Unsorted'>Unsorted</option>
                                    <option value='Contact'>Contact</option>
                                    <option value='ContactLead'>Contact + Lead</option>
                                  </select>
                                </div>
                                {state.outgoing_call === 'Unsorted' && (
                                  <span className='small text-gray-500 m-t-5'>
                                    Unsorted <span className='text-danger'>*</span> - Unsorted is created in amoCRM as
                                    an incoming call
                                  </span>
                                )}
                              </div>
                              <div className='separator mb-5'></div>

                              <div className='mb-10'>
                                <div className='d-flex flex-stack'>
                                  <label className='d-flex col-5'>
                                    <span className='small text-uppercase fw-bolder text-gray-600'>
                                      AMOCRM pipeline
                                    </span>
                                  </label>
                                  <Field
                                    className='form-control form-control-solid'
                                    name='line_pipeline_amo_0'
                                    value='Main'
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className='mb-10'>
                                <div className='d-flex flex-stack'>
                                  <label className='d-flex flex-column col-5'>
                                    <span className='small text-uppercase fw-bolder text-gray-600 required'>
                                      AMOCRM user ID
                                    </span>
                                    <span className='small text-gray-500'>for missed calls</span>
                                  </label>
                                  <Field name='manager_id' className='form-control form-control-solid' />
                                </div>
                                <div className='text-danger d-flex flex-stack mb-4'>
                                  <div className='col-4'></div>
                                  <ErrorMessage name='manager_id' />
                                </div>
                              </div>
                              <div className='mb-10'>
                                <div className='d-flex flex-stack'>
                                  <label className='d-flex flex-column col-5'>
                                    <span className='small text-uppercase fw-bolder text-gray-600'>Smart call</span>
                                    <span className='small text-gray-500'>by time</span>
                                  </label>
                                  <div className='d-flex col-8'>
                                    <div className='form-check text-align-start form-switch form-check-custom form-check-solid me-10'>
                                      <Field
                                        className='form-check-input h-20px w-30px'
                                        name='line_smcall_amo_0'
                                        type='checkbox'
                                        onClick={(e: any) => {
                                          updateState({sm_call_amo: e.target.checked})
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className='d-flex flex-stack mt-5'>
                                  <label className='d-flex col-5'></label>
                                  <div className='d-flex flex-stack'>
                                    <Field
                                      name='line_smtime_start_amo_0'
                                      className='form-control form-control-solid d-flex me-5 text-center'
                                      disabled={!state.sm_call_amo}
                                    />
                                    -
                                    <Field
                                      name='line_smtime_end_amo_0'
                                      className='form-control form-control-solid d-flex ms-5 text-center'
                                      disabled={!state.sm_call_amo}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className='mb-10'>
                                <div className='d-flex flex-stack'>
                                  <label className='d-flex col-5'>
                                    <span className='small text-uppercase fw-bolder text-gray-600'>Incoming call</span>
                                  </label>
                                  <Field
                                    as='select'
                                    className='form-select form-select-lg form-select-solid'
                                    name='line_incomingcall_amo_0'
                                  >
                                    <option value='None'>None</option>
                                    <option value='Unsorted'>Unsorted</option>
                                    <option value='Contact'>Contact</option>
                                    <option value='ContactLead'>Contact + Lead</option>
                                  </Field>
                                </div>
                              </div>
                              <div className='mb-10'>
                                <div className='d-flex flex-stack'>
                                  <label className='d-flex flex-column col-5'>
                                    <span className='small text-uppercase fw-bolder text-gray-600'>Unsorted</span>
                                    <span className='small text-gray-500'>during a phone call</span>
                                  </label>
                                  <div className='d-flex col-8'>
                                    <div className='form-check text-align-start form-switch form-check-custom form-check-solid me-10'>
                                      <Field
                                        className='form-check-input h-20px w-30px'
                                        name='line_unsorted_amo_0'
                                        type='checkbox'
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='mb-10'>
                                <div className='d-flex flex-stack'>
                                  <label className='d-flex col-5'>
                                    <span className='small text-uppercase fw-bolder text-gray-600'>
                                      Repeat Sales Mode
                                    </span>
                                  </label>
                                  <div className='d-flex col-8'>
                                    <div className='form-check text-align-start form-switch form-check-custom form-check-solid me-10'>
                                      <Field
                                        className='form-check-input h-20px w-30px'
                                        name='line_rsm_amo_0'
                                        type='checkbox'
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='mb-10'>
                                <div className='d-flex flex-stack'>
                                  <label className='d-flex col-5'>
                                    <span className='small text-uppercase fw-bolder text-gray-600'>
                                      Find in unsorted
                                    </span>
                                  </label>
                                  <div className='d-flex col-8'>
                                    <div className='form-check text-align-start form-switch form-check-custom form-check-solid me-10'>
                                      <Field
                                        className='form-check-input h-20px w-30px'
                                        name='line_findunsorted_amo_0'
                                        type='checkbox'
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {state.crm === 'BITRIX24' && (
                            <>
                              <label className='text-center text-gray-500 text-sm mb-10 text-uppercase d-flex flex-center'>
                                Settings for all lines
                              </label>
                              <div className='mb-10'>
                                <div className='d-flex flex-stack'>
                                  <label className='d-flex flex-column col-5'>
                                    <span className='small text-uppercase fw-bolder text-gray-600 required'>
                                      Manager ID
                                    </span>
                                    <span className='small text-gray-500'>for missed calls</span>
                                  </label>
                                  <Field name='manager_id' className='form-control form-control-solid' />
                                </div>
                                <div className='text-danger d-flex flex-stack mb-4'>
                                  <div className='col-4'></div>
                                  <ErrorMessage name='manager_id' />
                                </div>
                              </div>
                              <div className='mb-10'>
                                <div className='d-flex flex-stack'>
                                  <label className='d-flex col-5'>
                                    <span className='small text-uppercase fw-bolder text-gray-600'>Smart call</span>
                                  </label>
                                  <div className='d-flex col-8'>
                                    <div className='form-check text-align-start form-switch form-check-custom form-check-solid me-10'>
                                      <Field
                                        className='form-check-input h-20px w-30px'
                                        name='line_smcall_b24_0'
                                        type='checkbox'
                                        onClick={(e: any) => {
                                          if (e.target.checked === false) {
                                            updateState({
                                              time_b24: false,
                                              sm_call_b24: e.target.checked,
                                              time_block: !e.target.checked,
                                            })
                                          } else {
                                            updateState({
                                              time_b24: false,
                                              sm_call_b24: e.target.checked,
                                              time_block: !e.target.checked,
                                            })
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='mb-10'>
                                <div className='d-flex flex-stack'>
                                  <label className='d-flex flex-column col-5'>
                                    <span className='small text-uppercase fw-bolder text-gray-600'>Manager time</span>
                                    <span className='small text-gray-500'>in bitrix24</span>
                                  </label>
                                  <div className='d-flex col-8'>
                                    <div className='form-check text-align-start form-switch form-check-custom form-check-solid me-10'>
                                      <Field
                                        className='form-check-input h-20px w-30px'
                                        name='line_b24time_b24_0'
                                        type='checkbox'
                                        checked={state.time_b24}
                                        disabled={!state.sm_call_b24}
                                        onClick={(e: any) => {
                                          updateState({time_block: e.target.checked, time_b24: e.target.checked})
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='mb-10'>
                                <div className='d-flex flex-stack'>
                                  <label className='d-flex col-5'>
                                    <span className='small text-uppercase fw-bolder text-gray-600'>Work Time</span>
                                  </label>
                                  <div className='d-flex flex-stack'>
                                    <Field
                                      name='line_b24time_start_b24_0'
                                      className='form-control form-control-solid d-flex me-5 text-center'
                                      disabled={state.time_block}
                                    />
                                    -
                                    <Field
                                      name='line_b24time_end_b24_0'
                                      className='form-control form-control-solid d-flex ms-5 text-center'
                                      disabled={state.time_block}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className='mb-10'>
                                <div className='d-flex flex-stack'>
                                  <label className='d-flex col-5'>
                                    <span className='small text-uppercase fw-bolder text-gray-600'>Auto lead</span>
                                  </label>
                                  <div className='d-flex col-8'>
                                    <div className='form-check text-align-start form-switch form-check-custom form-check-solid me-10'>
                                      <Field
                                        className='form-check-input h-20px w-30px'
                                        name='line_autolead_b24_0'
                                        type='checkbox'
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='mb-10'>
                                <div className='d-flex flex-stack'>
                                  <label className='d-flex flex-column col-5'>
                                    <span className='small text-uppercase fw-bolder text-gray-600'>Add to chat</span>
                                    <span className='small text-gray-600'>bitrix24</span>
                                  </label>
                                  <div className='d-flex col-8'>
                                    <div className='form-check text-align-start form-switch form-check-custom form-check-solid me-10'>
                                      <Field
                                        className='form-check-input h-20px w-30px'
                                        name='line_chat_b24_0'
                                        type='checkbox'
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          <div className='separator mb-5'></div>
                          <span className='text-gray-500 small'>
                            To adding lines, please, create integration and create more lines
                          </span>
                        </div>
                      </div>
                      <div data-kt-stepper-element='content'>
                        <div className='col-12'>
                          <div className='d-flex flex-stack mb-10'>
                            <label className='d-flex align-items-center col-5'>
                              <span className='small text-uppercase fw-bolder text-gray-600'>Region</span>
                              <i
                                className='fas fa-exclamation-circle ms-2 fs-7'
                                data-bs-toggle='tooltip'
                                title='Select the closest region to connect to PBX'
                              ></i>
                            </label>
                            <Field as='select' name='region' className='form-select form-select-lg form-select-solid'>
                              <option value='BY'>BY</option>
                              <option value='RU'>RU</option>
                              <option value='DE'>DE</option>
                            </Field>

                            <div className='text-danger'>
                              <div className='col-4'></div>
                              <ErrorMessage name='region' />
                            </div>
                          </div>
                          <div className='mb-10 d-flex flex-stack'>
                            <label className='d-flex align-items-center col-5'>
                              <span className='small text-uppercase fw-bolder text-gray-600'>Auto mode</span>
                              <i
                                className='fas fa-exclamation-circle ms-2 fs-7'
                                data-bs-toggle='tooltip'
                                title='Check this switch if you want to auto region selecting'
                              ></i>
                            </label>
                            <div className='d-flex col-8'>
                              <div className='form-check text-align-start form-switch form-check-custom form-check-solid me-10'>
                                <Field className='form-check-input h-20px w-30px' name='auto_mode' type='checkbox' />
                              </div>
                            </div>
                          </div>
                          <div className='d-flex flex-stack mb-10'>
                            <label className='d-flex align-items-center col-5'>
                              <span className='small text-uppercase fw-bolder text-gray-600'>Time zone</span>
                            </label>
                            <Field
                              as='select'
                              name='time_zone'
                              className='form-select form-select-lg form-select-solid'
                            >
                              <TimeZoneOptions />
                            </Field>
                          </div>
                        </div>
                      </div>
                      <div data-kt-stepper-element='content'>
                        <div className='w-100 text-center'>
                          {(!isFetchingBitrix24TryAgain || !isFetchingAmoCRMTryAgain) &&
                            !isLoading &&
                            state.error !== '' && (
                              <>
                                <h1 className='fw-bolder text-dark mb-10'>Сreation of integration failed :( </h1>
                                <label className='text-center fw-bolder test-sm'>{state.error}</label>
                                <div className='text-center'>
                                  <img
                                    src={toAbsoluteUrl('/media/illustrations/unitedpalms-1/5.png')}
                                    alt=''
                                    className='mh-300px'
                                  />
                                </div>
                              </>
                            )}
                          {!isFetchingBitrix24TryAgain &&
                            !isLoading &&
                            state.crm === 'BITRIX24' &&
                            state.tokens_bitrix24 === 'false' && (
                              <>
                                <h1 className='fw-bolder text-dark mb-10'>
                                  Please, install the{' '}
                                  <a
                                    className='text-center fw-bolder test-sm'
                                    target='_blank'
                                    rel='noreferrer'
                                    href={
                                      state.domain.includes('.by')
                                        ? 'https://www.bitrix24.by/apps/app/5757539.callbee_by/'
                                        : 'https://www.bitrix24.ru/apps/app/5757539.callbee/'
                                    }
                                    data-bs-toggle='tooltip'
                                    data-bs-trigger='hover'
                                    data-bs-dismiss-='click'
                                  >
                                    Callbee application{' '}
                                  </a>
                                  in your Bitrix24
                                </h1>

                                <div className='text-center'>
                                  <img
                                    src={toAbsoluteUrl('/media/illustrations/sigma-1/17.png')}
                                    alt=''
                                    className='mh-300px'
                                  />
                                </div>
                              </>
                            )}
                          {!isFetchingAmoCRMTryAgain &&
                            !isLoading &&
                            state.crm === 'AMOCRM' &&
                            state.tokens_amocrm === 'false' && (
                              <>
                                <h1 className='fw-bolder text-dark mb-10'>
                                  Please, install application "Asterisk и Yeastar" in your AmoCRM
                                </h1>

                                <div className='text-center'>
                                  <img
                                    src={toAbsoluteUrl('/media/illustrations/sigma-1/17.png')}
                                    alt=''
                                    className='mh-300px'
                                  />
                                </div>
                              </>
                            )}
                          {!isFetchingBitrix24TryAgain &&
                            !isLoading &&
                            state.crm === 'BITRIX24' &&
                            state.error_tokens_bitrix24 === true && (
                              <>
                                <h1 className='fw-bolder text-dark mb-10'>
                                  Something went wrong, please make sure the{' '}
                                  <a
                                    className='text-center fw-bolder test-sm'
                                    rel='noreferrer'
                                    data-bs-toggle='tooltip'
                                    data-bs-trigger='hover'
                                    data-bs-dismiss-='click'
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                      stepper?.current?.goto(1)
                                      updateState({
                                        error: '',
                                        create: false,
                                        error_tokens_bitrix24: false,
                                        tokens_bitrix24: '',
                                      })
                                    }}
                                  >
                                    Bitrix24 Domain
                                  </a>{' '}
                                  you entered is correct and try again
                                </h1>
                                <div className='text-center'>
                                  <img
                                    src={toAbsoluteUrl('/media/illustrations/dozzy-1/5.png')}
                                    alt=''
                                    className='mh-300px'
                                  />
                                </div>
                              </>
                            )}
                          {!isFetchingBitrix24TryAgain &&
                            !isFetchingAmoCRMTryAgain &&
                            !isLoading &&
                            state.create === true &&
                            state.error === '' && (
                              <>
                                <h1 className='fw-bolder text-dark mb-3'>Integration created successfully!</h1>
                                <div className='text-center'>
                                  <img
                                    src={toAbsoluteUrl('/media/illustrations/unitedpalms-1/17.png')}
                                    alt=''
                                    className='mh-300px'
                                  />
                                </div>
                              </>
                            )}
                          {isFetchingBitrix24TryAgain && (
                            <>
                              <h1 className='fw-bolder text-dark mb-10'>Loading...</h1>

                              <div className='text-center'>
                                <img
                                  src={toAbsoluteUrl('/media/illustrations/sigma-1/20.png')}
                                  alt=''
                                  className='mh-300px'
                                />
                              </div>
                            </>
                          )}
                          {isFetchingAmoCRMTryAgain && (
                            <>
                              <h1 className='fw-bolder text-dark mb-10'>Loading...</h1>

                              <div className='text-center'>
                                <img
                                  src={toAbsoluteUrl('/media/illustrations/sigma-1/20.png')}
                                  alt=''
                                  className='mh-300px'
                                />
                              </div>
                            </>
                          )}
                          {isLoading && (
                            <>
                              <h1 className='fw-bolder text-dark mb-10'>Loading...</h1>

                              <div className='text-center'>
                                <img
                                  src={toAbsoluteUrl('/media/illustrations/sigma-1/20.png')}
                                  alt=''
                                  className='mh-300px'
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      {state.error === '' && state.create === false && (
                        <div className='d-flex flex-stack pt-10'>
                          <div className='me-2'>
                            <button
                              onClick={prevStep}
                              type='button'
                              className='btn btn-lg btn-light-primary me-3'
                              data-kt-stepper-action='previous'
                            >
                              <KTSVG path='/media/icons/duotune/arrows/arr063.svg' className='svg-icon-4 me-1' />
                              Back
                            </button>
                          </div>
                          <div>
                            {((state.yeastar_series !== 'P' &&
                              !isLoading &&
                              !isFetchingBitrix24TryAgain &&
                              !isFetchingAmoCRMTryAgain &&
                              stepper.current?.currentStepIndex !== stepper.current?.totatStepsNumber! - 1 &&
                              stepper.current?.currentStepIndex !== stepper.current?.totatStepsNumber!) ||
                              (state.yeastar_series === 'P' &&
                                !isLoading &&
                                !isFetchingBitrix24TryAgain &&
                                !isFetchingAmoCRMTryAgain &&
                                stepper.current?.currentStepIndex !== stepper.current?.totatStepsNumber! - 2 &&
                                stepper.current?.currentStepIndex !== stepper.current?.totatStepsNumber! - 1)) && (
                              <>
                                <button type='submit' className='btn btn-lg btn-primary'>
                                  <span className='indicator-label'>
                                    Continue
                                    <KTSVG
                                      path='/media/icons/duotune/arrows/arr064.svg'
                                      className='svg-icon-3 ms-2 me-0'
                                    />
                                  </span>
                                </button>
                              </>
                            )}
                            {((state.yeastar_series !== 'P' &&
                              !isLoading &&
                              !isFetchingBitrix24TryAgain &&
                              !isFetchingAmoCRMTryAgain &&
                              stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! - 1) ||
                              (state.yeastar_series === 'P' &&
                                !isLoading &&
                                !isFetchingBitrix24TryAgain &&
                                !isFetchingAmoCRMTryAgain &&
                                stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! - 2)) && (
                              <>
                                <button type='submit' className='btn btn-lg btn-primary'>
                                  <span className='indicator-label'>
                                    Create
                                    <KTSVG
                                      path='/media/icons/duotune/arrows/arr064.svg'
                                      className='svg-icon-3 ms-2 me-0'
                                    />
                                  </span>
                                </button>
                              </>
                            )}
                            {((state.yeastar_series !== 'P' &&
                              !isLoading &&
                              !isFetchingBitrix24TryAgain &&
                              state.crm === 'BITRIX24' &&
                              state.tokens_bitrix24 === 'false' &&
                              stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber!) ||
                              (state.yeastar_series === 'P' &&
                                !isLoading &&
                                !isFetchingBitrix24TryAgain &&
                                state.crm === 'BITRIX24' &&
                                state.tokens_bitrix24 === 'false' &&
                                stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! - 1)) && (
                              <>
                                <button type='submit' className='btn btn-lg btn-primary'>
                                  <span className='indicator-label'>Try again</span>
                                </button>
                              </>
                            )}
                            {((state.yeastar_series !== 'P' &&
                              !isLoading &&
                              !isFetchingAmoCRMTryAgain &&
                              state.crm === 'AMOCRM' &&
                              state.tokens_amocrm === 'false' &&
                              stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber!) ||
                              (state.yeastar_series === 'P' &&
                                !isLoading &&
                                !isFetchingAmoCRMTryAgain &&
                                state.crm === 'AMOCRM' &&
                                state.tokens_amocrm === 'false' &&
                                stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! - 1)) && (
                              <>
                                <button type='submit' className='btn btn-lg btn-primary'>
                                  <span className='indicator-label'>Try again</span>
                                </button>
                              </>
                            )}
                            {(isFetchingBitrix24TryAgain || isFetchingAmoCRMTryAgain || isLoading) && (
                              <>
                                <button type='submit' className='btn btn-lg btn-primary'>
                                  <span className='indicator-label'>
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                      Please wait...
                                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                  </span>
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      )}

                      {state.error !== '' && (
                        <div className='d-flex flex-stack pt-10'>
                          <div className='me-2'>
                            <button
                              onClick={prevStep}
                              type='button'
                              className='btn btn-lg btn-light-primary me-3'
                              data-kt-stepper-action='previous'
                            >
                              <KTSVG path='/media/icons/duotune/arrows/arr063.svg' className='svg-icon-4 me-1' />
                              Back
                            </button>
                          </div>

                          <div></div>
                        </div>
                      )}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Main}
