import {FC, useState, createContext, useContext, ReactNode} from 'react'
import {CreateAppQueryState, initialQueryRequestCreateApp, QueryRequestContextPropsCreateApp} from './IAppModels'

type WithChildren = {
  children?: ReactNode
}

const QueryRequestContext = createContext<QueryRequestContextPropsCreateApp>(initialQueryRequestCreateApp)

const QueryRequestProviderCreateApp: FC<WithChildren> = ({children}) => {
  const [state, setState] = useState<CreateAppQueryState>(initialQueryRequestCreateApp.state)

  const updateState = (updates: Partial<CreateAppQueryState>) => {
    const updatedState = {...state, ...updates} as CreateAppQueryState
    setState(updatedState)
  }

  return <QueryRequestContext.Provider value={{state, updateState}}>{children}</QueryRequestContext.Provider>
}

const useQueryRequestCreateApp = () => useContext(QueryRequestContext)
export {QueryRequestProviderCreateApp, useQueryRequestCreateApp}
