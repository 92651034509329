export interface ICreateApp {
  crm: 'BITRIX24' | 'AMOCRM'
  pbx: 'ASTERISK' | 'YEASTAR'
  region: string
  auto_mode: boolean
  time_zone: string
  ami_host: string
  ami_username: string
  ami_secret: string
  domain: string
  ignore_trunks: string
  record_url: string
  /*usersInService: boolean*/
  outgoing_call_amo: string
  /*outgoing_call_b24: boolean*/
  line_did_amo_0: string
  line_description_amo_0: string
  line_pipeline_amo_0: string
  manager_id: string
  line_smcall_amo_0: boolean
  line_smtime_start_amo_0: string
  line_smtime_end_amo_0: string
  line_incomingcall_amo_0: string
  line_unsorted_amo_0: boolean
  line_rsm_amo_0: boolean
  line_findunsorted_amo_0: boolean
  line_did_b24_0: string
  line_description_b24_0: string
  line_smcall_b24_0: boolean
  line_b24time_b24_0: boolean
  line_b24time_end_b24_0: string
  line_b24time_start_b24_0: string
  line_autolead_b24_0: boolean
  line_chat_b24_0: boolean
  api_is_active: boolean
  mp3_is_active: boolean
  yeastar_host: string
  yeastar_username: string
  yeastar_secret: string
  ftp_path: string
}

export const inits: ICreateApp = {
  crm: 'BITRIX24',
  pbx: 'ASTERISK',
  region: 'BY',
  auto_mode: false,
  time_zone: 'UTC',
  ami_host: '',
  ami_username: '',
  ami_secret: '',
  domain: '',
  ignore_trunks: '',
  record_url: '',
  /*usersInService: false,*/
  outgoing_call_amo: 'Contact',
  /*outgoing_call_b24: false,*/
  line_did_amo_0: '',
  line_description_amo_0: '',
  line_pipeline_amo_0: '',
  manager_id: '',
  line_smcall_amo_0: false,
  line_smtime_start_amo_0: '09:00',
  line_smtime_end_amo_0: '18:00',
  line_incomingcall_amo_0: 'Contact',
  line_unsorted_amo_0: false,
  line_rsm_amo_0: false,
  line_findunsorted_amo_0: false,
  line_did_b24_0: '',
  line_description_b24_0: '',
  line_smcall_b24_0: false,
  line_b24time_b24_0: false,
  line_b24time_end_b24_0: '18:00',
  line_b24time_start_b24_0: '09:00',
  line_autolead_b24_0: false,
  line_chat_b24_0: false,
  api_is_active: false,
  mp3_is_active: false,
  yeastar_host: '',
  yeastar_username: '',
  yeastar_secret: '',
  ftp_path: '/ftp_media/mmc/autorecords/',
}

export interface IAppAMISettings {
  host: string
  username: string
  secret: string
}

export interface IAppAsterisk {
  record_url: string
}

export interface IAppYeastar {
  series: string
  api_is_active: boolean
  mp3_is_active: boolean
  host: string
  username: string
  secret: string
  ftp_path: string
}

export interface IAppIncomingCallMode {
  none: boolean
  unsorted: boolean
  contact: boolean
  contact_lead: boolean
}

export interface IAppBitrix24Lines {
  line: string
  description: string
  responsible_user: string
  smart_call: boolean
  b24_manager_time: boolean
  start_time: string
  end_time: string
  auto_lead: boolean
  chat: boolean
  groups: Array<string>
}

export interface IAppBitrix24 {
  users_in_service: boolean
  lines: Array<IAppBitrix24Lines>
}

export interface IAppAmoCRMLines {
  id?: string
  line: string
  description: string
  pipeline: string
  responsible_user: string
  incoming_call_mode: IAppIncomingCallMode
  smart_call: boolean
  start_time: string
  end_time: string
  udpc: boolean
  repeat_sales_mode: boolean
  find_unsorted: boolean
}

export interface IAppAmoCRM {
  outgoing_call_mode: IAppIncomingCallMode
  lines: Array<IAppAmoCRMLines>
}
export interface IAppCreateData {
  crm_domain: string
  crm: string
  pbx: string
  region: string
  is_auto_mode: boolean
  time_zone: string
  settings: {
    ami: IAppAMISettings
    asterisk: IAppAsterisk
    yeastar: IAppYeastar
    ignore_trunks: Array<string>
    bitrix24?: IAppBitrix24
    amocrm?: IAppAmoCRM
  }
}

export type CreateAppQueryState = {
  crm: string
  pbx: string
  sm_call_b24: boolean
  sm_call_amo: boolean
  time_b24: boolean
  time_block: boolean
  api_active: boolean
  error: string
  create: boolean
  outgoing_call: string
  tokens_bitrix24: string
  tokens_amocrm: string
  domain: string
  error_tokens_bitrix24: boolean
  yeastar_series: string
}

export const defaultCreateAppState: CreateAppQueryState = {
  crm: 'BITRIX24',
  pbx: 'ASTERISK',
  sm_call_b24: false,
  sm_call_amo: false,
  time_b24: false,
  time_block: true,
  api_active: false,
  error: '',
  create: false,
  outgoing_call: 'Contact',
  tokens_bitrix24: '',
  domain: '',
  error_tokens_bitrix24: false,
  tokens_amocrm: '',
  yeastar_series: 'S',
}

export type QueryRequestContextPropsCreateApp = {
  state: CreateAppQueryState
  updateState: (updates: Partial<CreateAppQueryState>) => void
}

export const initialQueryRequestCreateApp: QueryRequestContextPropsCreateApp = {
  state: defaultCreateAppState,
  updateState: () => {},
}
