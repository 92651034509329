import {createApi} from '@reduxjs/toolkit/dist/query/react'
import {IUserAuthLog} from '../types/users/UserAuthLog'
import {baseQueryWithReauth} from './BaseQueryReauth'
import {IUserModel} from '../types'
import {History} from '../modules/management/history/core/_models'

interface IQueryParams {
  skip?: number
  limit?: number
  search?: string
}

interface ListResponse<T> {
  data: T[]
  total: number
}

export const usersAPI = createApi({
  reducerPath: 'usersAPI',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['users', 'currentUser'],
  endpoints: (build) => ({
    fetchGetMe: build.query<IUserModel, void>({
      query: () => ({
        url: '/users/me',
      }),
      // transformResponse: (response: IUserModel1) => response,
      providesTags: ['currentUser'],
      keepUnusedDataFor: 60 * 60 * 6,
    }),
    fetchUserById: build.query<IUserModel, string>({
      query: (id) => ({
        url: `/users/${id}`,
      }),
      providesTags: (result, error, id) => [{type: 'users', id}],
    }),
    fetchUserAuthLogs: build.query<IUserAuthLog[], {id: string; skip?: number; limit?: number}>({
      query: ({id, skip = 0, limit = 50}) => ({
        url: `/users/authlog/${id}`,
        params: {skip: skip, limit: limit},
      }),
      keepUnusedDataFor: 3600,
    }),
    fetchUsers: build.query<ListResponse<IUserModel>, IQueryParams>({
      query: ({search, skip = 0, limit = 50}) => ({
        url: '/users',
        params: {skip: skip, limit: limit, search: search!},
      }),
      // providesTags: [{type: 'users', id: 'LIST'}],
      // invalidatesTags: (result, error, id) => [{type: 'users', id}],
      // providesTags: (result, error, id) => [{type: 'users', id}],

      providesTags: (result) =>
        result
          ? [...result.data.map(({id}) => ({type: 'users' as const, id})), {type: 'users', id: 'LIST'}]
          : [{type: 'users', id: 'LIST'}],
    }),
    // fetchUsers: build.query<IUserModel[], IQueryParams>({
    //   query: ({skip = 0, limit = 50}) => ({
    //     url: '/users',
    //     params: {skip: skip, limit: limit},
    //   }),
    //   // providesTags: [{type: 'users', id: 'LIST'}],
    //   // invalidatesTags: (result, error, id) => [{type: 'users', id}],
    //   // providesTags: (result, error, id) => [{type: 'users', id}],
    //   providesTags: (result) =>
    //     result
    //       ? [...result.map(({id}) => ({type: 'users' as const, id})), {type: 'users', id: 'LIST'}]
    //       : [{type: 'users', id: 'LIST'}],
    // }),
    updateUser: build.mutation<IUserModel, IUserModel>({
      query: (user) => ({
        url: `/users/${user?.id}`,
        method: 'PUT',
        body: user,
      }),
      // invalidatesTags: () => [{type: 'users', id: 'LIST'}],
      invalidatesTags: ['users'],
      // invalidatesTags: (result) =>
      //   result
      //     ? [
      //         {type: 'users', id: result.id},
      //         {type: 'users', id: 'LIST'},
      //       ]
      //     : [],
      async onQueryStarted(updatedUser, {dispatch, queryFulfilled}) {
        // const getUserMe = dispatch(
        //   usersAPI.util.updateQueryData('fetchGetMe', undefined, (currentUser) => {
        //     if (currentUser.id === updatedUser.id) {
        //       Object.assign(currentUser, updatedUser)
        //     }
        //   })
        // )
        // try {
        //   await queryFulfilled
        // } catch {
        //   getUserMe.undo()
        // }
        try {
          await queryFulfilled
          dispatch(
            usersAPI.util.updateQueryData('fetchGetMe', undefined, (currentUser) => {
              if (currentUser.id === updatedUser.id) {
                Object.assign(currentUser, updatedUser)
              }
            })
          )
        } catch {}
      },
    }),
    fetchServiceHistory: build.query<ListResponse<History>, IQueryParams>({
      query: ({skip = 0, limit = 50}) => ({
        url: '/service/histrory',
        params: {skip: skip, limit: limit},
      }),
      providesTags: (result) => [{type: 'users', id: 'LIST'}],
    }),
  }),
})
