import {FC, useState} from 'react'
import {useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {KTSVG} from '../../../../../../template/helpers'
import {appsAPI} from '../../../../../../services/AppsService'
import {IAppsModel} from '../../../../../../types/apps/AppsModel'
import {Bitrix24UserInServiceInitValues} from '../../../../core/_models'
import {IBitrix24UserInServiceModel} from '../../../../../../types/apps/crm/biitrix24/BitrixUserInServiceModel'

type Props = {
  user: IBitrix24UserInServiceModel
}

const bitrix24UsersInServiceEditShema = Yup.object().shape({
  is_active: Yup.boolean(),
  internal_number: Yup.string(),
  external_number: Yup.string(),
  bitrix24_user_id: Yup.string(),
  fullname: Yup.string().required('Name is required'),
  bitrix24_manager_time: Yup.boolean(),
  smart_call: Yup.boolean(),
  chat: Yup.boolean(),
  auto_answer: Yup.boolean(),
  multiple_registration: Yup.boolean(),
})

const OneUserInServiceBitrix24: FC<Props> = ({user}) => {
  const {appId} = useParams()
  const {data: app} = appsAPI.useFetchAppByIdQuery(appId!)
  const [userState, updateUserState] = useState(false)
  const [smartCallState, updateSmartCallState] = useState(user.smart_call)
  const [b24TimeState, updateb24TimeState] = useState(user.bitrix24_manager_time)
  const [timeState, updateTimeState] = useState(
    user.smart_call === true && user.bitrix24_manager_time === false ? false : true
  )
  const [b24TimeDisabledState, updateb24TimeDisabledState] = useState(!user.smart_call)
  const [updateApp, {isLoading}] = appsAPI.useUpdateAppMutation()
  const all_users = app?.settings.bitrix24.users_in_service_list

  const checkSMCallDisabled = (checked: boolean) => {
    updateSmartCallState(checked)
    const b24Time = document.getElementById('userEditB24Time' + user.bitrix24_user_id)
    if (smartCallState === true) {
      if (b24TimeState === true) {
        b24Time?.click()
        updateb24TimeState(false)
      }
      updateb24TimeDisabledState(true)
      updateTimeState(true)
    } else if (smartCallState === false) {
      updateb24TimeDisabledState(false)
      updateTimeState(false)
    }
  }

  const validateNumber = (number: string) => {
    const alph = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '+']
    let mark = false
    let new_number = ''
    for (let j = 0; j < number.length; j++) {
      for (let i = 0; i < alph.length; i++) {
        if (number[j] === alph[i]) {
          mark = true
          break
        } else {
          mark = false
          continue
        }
      }
      if (mark === true) {
        new_number += number[j]
      }
    }
    return new_number
  }

  const checkTimeDisabled = (checked: boolean) => {
    updateb24TimeState(checked)
    if (b24TimeState === false) {
      updateTimeState(true)
    } else {
      updateTimeState(false)
    }
  }

  const other_users: any[] = []
  if (all_users) {
    for (let i = 0; i < all_users.length; i++) {
      if (all_users[i]?.id !== user.id) {
        other_users.push(all_users[i])
      }
    }
  }
  if (other_users) {
    for (let i = 0; i < other_users.length; i++) {
      if (other_users[i].groups) {
        delete other_users[i]['groups']
      }
    }
  }

  const formikUser = useFormik<IBitrix24UserInServiceModel>({
    initialValues: Object.assign(Bitrix24UserInServiceInitValues, {
      id: user.id,
      is_active: user.is_active,
      bitrix24_user_id: user.bitrix24_user_id,
      internal_number: validateNumber(user.internal_number),
      external_number: validateNumber(user.external_number),
      fullname: user.fullname,
      bitrix24_manager_time: user.bitrix24_manager_time,
      smart_call: user.smart_call,
      smart_call_time: {
        MON_is_active: user.smart_call_time.MON_is_active,
        MON_start_time: user.smart_call_time.MON_start_time.slice(0, 5),
        MON_end_time: user.smart_call_time.MON_end_time.slice(0, 5),
        TUE_is_active: user.smart_call_time.TUE_is_active,
        TUE_start_time: user.smart_call_time.TUE_start_time.slice(0, 5),
        TUE_end_time: user.smart_call_time.TUE_end_time.slice(0, 5),
        WED_is_active: user.smart_call_time.WED_is_active,
        WED_start_time: user.smart_call_time.WED_start_time.slice(0, 5),
        WED_end_time: user.smart_call_time.WED_end_time.slice(0, 5),
        THU_is_active: user.smart_call_time.THU_is_active,
        THU_start_time: user.smart_call_time.THU_start_time.slice(0, 5),
        THU_end_time: user.smart_call_time.THU_end_time.slice(0, 5),
        FRI_is_active: user.smart_call_time.FRI_is_active,
        FRI_start_time: user.smart_call_time.FRI_start_time.slice(0, 5),
        FRI_end_time: user.smart_call_time.FRI_end_time.slice(0, 5),
        SAT_is_active: user.smart_call_time.SAT_is_active,
        SAT_start_time: user.smart_call_time.SAT_start_time.slice(0, 5),
        SAT_end_time: user.smart_call_time.SAT_end_time.slice(0, 5),
        SUN_is_active: user.smart_call_time.SUN_is_active,
        SUN_start_time: user.smart_call_time.SUN_start_time.slice(0, 5),
        SUN_end_time: user.smart_call_time.SUN_end_time.slice(0, 5),
      },
      chat: user.chat,
      auto_answer: user.auto_answer,
      multiple_registration: user.multiple_registration,
    }),
    validationSchema: bitrix24UsersInServiceEditShema,

    onSubmit: async (values) => {
      const edit_user: IBitrix24UserInServiceModel = {
        id: user.id,
        is_active: values.is_active,
        bitrix24_user_id: user.bitrix24_user_id,
        internal_number: values.internal_number,
        external_number: values.external_number,
        fullname: values.fullname,
        bitrix24_manager_time: values.bitrix24_manager_time,
        smart_call: values.smart_call,
        smart_call_time: {
          MON_is_active: values.smart_call_time.MON_is_active,
          MON_start_time: values.smart_call_time.MON_start_time,
          MON_end_time: values.smart_call_time.MON_end_time,
          TUE_is_active: values.smart_call_time.TUE_is_active,
          TUE_start_time: values.smart_call_time.TUE_start_time,
          TUE_end_time: values.smart_call_time.TUE_end_time,
          WED_is_active: values.smart_call_time.WED_is_active,
          WED_start_time: values.smart_call_time.WED_start_time,
          WED_end_time: values.smart_call_time.WED_end_time,
          THU_is_active: values.smart_call_time.THU_is_active,
          THU_start_time: values.smart_call_time.THU_start_time,
          THU_end_time: values.smart_call_time.THU_end_time,
          FRI_is_active: values.smart_call_time.FRI_is_active,
          FRI_start_time: values.smart_call_time.FRI_start_time,
          FRI_end_time: values.smart_call_time.FRI_end_time,
          SAT_is_active: values.smart_call_time.SAT_is_active,
          SAT_start_time: values.smart_call_time.SAT_start_time,
          SAT_end_time: values.smart_call_time.SAT_end_time,
          SUN_is_active: values.smart_call_time.SUN_is_active,
          SUN_start_time: values.smart_call_time.SUN_start_time,
          SUN_end_time: values.smart_call_time.SUN_end_time,
        },
        chat: values.chat,
        auto_answer: values.auto_answer,
        multiple_registration: values.multiple_registration,
      }
      const data = {
        id: appId!,
        settings: {
          bitrix24: {
            users_in_service: app?.settings.bitrix24.users_in_service,
            users_in_service_list: [edit_user],
          },
        },
      }
      await updateApp(data as IAppsModel)
      updateUserState(false)
    },
  })

  return (
    <>
      {userState === false && (
        <tr role='row' className='text-gray-900 text-center'>
          <td role='cell' className='min-w-70px'>
            <div className='d-flex justify-content-end'>
              <div className='form-check form-check-custom form-check-solid form-check-sm d-block'>
                <input
                  className='form-check-input align-self-center'
                  type='checkbox'
                  id={'switchActive' + user.bitrix24_user_id}
                  checked={user.is_active}
                  readOnly
                  disabled
                />
              </div>
            </div>
          </td>
          <td role='cell' className='min-w-100px pe-0 px-0'>
            <input
              className='form-control form-control-sm form-control-flush text-gray-900 text-center'
              value={user.bitrix24_user_id}
              disabled
              readOnly
            />
          </td>
          <td role='cell' className='min-w-150px pe-0 px-0'>
            <input
              className='form-control form-control-sm form-control-flush text-center'
              value={user.fullname}
              disabled
              readOnly
            />
          </td>
          <td role='cell' className='min-w-150px pe-0 px-0'>
            <input
              className='form-control form-control-sm form-control-flush text-center'
              value={validateNumber(user.internal_number)}
              disabled
              readOnly
            />
          </td>
          <td role='cell' className='min-w-150px pe-0 px-0'>
            <input
              className='form-control form-control-sm form-control-flush text-center'
              value={validateNumber(user.external_number)}
              disabled
              readOnly
            />
          </td>
          <td role='cell' className='min-w-100px px-0 d-flex justify-content-center'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-block me-2 align-self-center'>
              <input
                className='form-check-input'
                type='checkbox'
                id={'userSmartCall' + user.bitrix24_user_id}
                checked={user.smart_call}
                readOnly
                disabled
              />
              <label className='form-check-label' htmlFor={'userSmartCall' + user.bitrix24_user_id}></label>
            </div>
            <button type='button' className='btn btn-icon btn-light-primary btn-sm align-self-center' disabled>
              <KTSVG path='/media/icons/duotune/general/gen013.svg' className='align-self-center' />
            </button>
          </td>
          <td role='cell' className='min-w-100px px-0'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-block'>
              <input
                className='form-check-input'
                type='checkbox'
                id={'userB24Time' + user.bitrix24_user_id}
                checked={user.bitrix24_manager_time}
                readOnly
                disabled
              />
              <label className='form-check-label' htmlFor={'userB24Time' + user.bitrix24_user_id}></label>
            </div>
          </td>
          <td role='cell' className='min-w-70px pe-0 px-0'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-block'>
              <input
                className='form-check-input'
                type='checkbox'
                id={'userChat' + user.bitrix24_user_id}
                checked={user.chat}
                readOnly
                disabled
              />
              <label className='form-check-label' htmlFor={'userChat' + user.bitrix24_user_id}></label>
            </div>
          </td>
          <td role='cell' className='min-w-70px pe-0 px-0'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-block'>
              <input
                className='form-check-input'
                type='checkbox'
                id={'autoAnswer' + user.bitrix24_user_id}
                checked={user.auto_answer}
                readOnly
                disabled
              />
              <label className='form-check-label' htmlFor={'autoAnswer' + user.bitrix24_user_id}></label>
            </div>
          </td>
          <td role='cell' className='min-w-70px pe-0 px-0'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-block'>
              <input
                className='form-check-input'
                type='checkbox'
                id={'multRegistration' + user.bitrix24_user_id}
                checked={user.multiple_registration}
                readOnly
                disabled
              />
              <label className='form-check-label' htmlFor={'multRegistration' + user.bitrix24_user_id}></label>
            </div>
          </td>
          <td role='cell' className='min-w-100px pe-0 px-0'>
            <button
              type='button'
              onClick={() => {
                updateUserState(true)
              }}
              className='btn btn-icon btn-light-primary btn-sm me-1 align-self-center'
            >
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='align-self-center' />
            </button>
          </td>
        </tr>
      )}
      {userState === true && (
        <tr role='row' className='text-center'>
          <td className='d-none'>
            <form
              onSubmit={formikUser.handleSubmit}
              noValidate
              method='GET'
              id={'UserForm' + user?.bitrix24_user_id}
              className='form'
            ></form>
            <input type='hidden' {...formikUser.getFieldProps('id')} />
          </td>
          <td role='cell' className='min-w-70px'>
            <div className='d-flex justify-content-end'>
              <div className='form-check form-check-custom form-check-solid form-check-sm d-block'>
                <input
                  className='form-check-input align-self-center'
                  type='checkbox'
                  id={'switchEditActive' + user.bitrix24_user_id}
                  defaultChecked={user.is_active}
                  form={'Userform' + user?.bitrix24_user_id}
                  onClick={(e: any) => {
                    const numberInput = document.getElementById(
                      'editInternalNumber' + user.bitrix24_user_id
                    ) as HTMLInputElement
                    if (numberInput.value === '') {
                      e.target.checked = false
                      numberInput.classList.add('bg-light-danger')
                      numberInput.focus()
                    }
                  }}
                  {...formikUser.getFieldProps('is_active')}
                />
              </div>
            </div>
          </td>
          <td role='cell' className='min-w-100px pe-0 px-0'>
            <input
              className='form-control form-control-sm form-control-flush text-center'
              form={'Userform' + user?.bitrix24_user_id}
              {...formikUser.getFieldProps('bitrix24_user_id')}
              disabled
            />
          </td>
          <td role='cell' className='min-w-150px pe-0 px-0'>
            <input
              className='form-control form-control-sm form-control-flush text-center'
              {...formikUser.getFieldProps('fullname')}
              form={'UserForm' + user?.bitrix24_user_id}
            />
            {formikUser.touched.fullname && formikUser.errors.fullname && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block text-danger'>{formikUser.errors.fullname}</div>
              </div>
            )}
          </td>
          <td role='cell' className='min-w-150px pe-0 px-0'>
            <input
              className='form-control form-control-sm form-control-flush text-center'
              form={'Userform' + user?.bitrix24_user_id}
              id={'editInternalNumber' + user.bitrix24_user_id}
              onInput={(e: any) => {
                const activeSwitch = document.getElementById(
                  'switchEditActive' + user.bitrix24_user_id
                ) as HTMLInputElement
                let number = e.target.value
                const alph = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
                let mark = false
                for (let i = 0; i < alph.length; i++) {
                  if (number[number.length - 1] === alph[i]) {
                    mark = true
                    break
                  } else {
                    mark = false
                    continue
                  }
                }
                if (mark === false) {
                  e.target.value = number.slice(0, number.length - 1)
                }
                if (activeSwitch.checked === true && e.target.value === '') {
                  activeSwitch.click()
                  e.target.classList.add('bg-light-danger')
                  e.target.focus()
                } else if (e.target.value !== '') {
                  e.target.classList.remove('bg-light-danger')
                }
              }}
              {...formikUser.getFieldProps('internal_number')}
            />
          </td>
          <td role='cell' className='min-w-150px pe-0 px-0'>
            <input
              onInput={(e: any) => {
                let number = e.target.value
                const alph = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '+']
                let mark = false
                for (let i = 0; i < alph.length; i++) {
                  if (number[number.length - 1] === alph[i]) {
                    mark = true
                    break
                  } else {
                    mark = false
                    continue
                  }
                }
                if (mark === false) {
                  e.target.value = number.slice(0, number.length - 1)
                }
              }}
              className='form-control form-control-sm form-control-flush text-center'
              form={'Userform' + user?.bitrix24_user_id}
              {...formikUser.getFieldProps('external_number')}
            />
          </td>
          <td role='cell' className='min-w-100px px-0 d-flex justify-content-center'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-block me-2 align-self-center'>
              <input
                form={'Userform' + user?.bitrix24_user_id}
                className='form-check-input'
                type='checkbox'
                id={'userEditSmartCall' + user.bitrix24_user_id}
                defaultChecked={user.smart_call}
                onClick={(e: any) => {
                  checkSMCallDisabled(e.target.checked)
                }}
                {...formikUser.getFieldProps('smart_call')}
              />
              <label className='form-check-label' htmlFor={'userEditSmartCall' + user.bitrix24_user_id}></label>
            </div>
            <button
              type='button'
              disabled={timeState}
              className='btn btn-icon btn-light-primary btn-sm align-self-center'
              data-bs-toggle='modal'
              data-bs-target={'#SmartCallTimeModal' + user.id}
            >
              <KTSVG path='/media/icons/duotune/general/gen013.svg' className='align-self-center' />
            </button>
          </td>
          <td role='cell' className='min-w-100px px-0'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-block'>
              <input
                className='form-check-input'
                type='checkbox'
                form={'Userform' + user?.bitrix24_user_id}
                id={'userEditB24Time' + user.bitrix24_user_id}
                defaultChecked={user.bitrix24_manager_time}
                disabled={b24TimeDisabledState}
                onClick={(e: any) => {
                  checkTimeDisabled(e.target.checked)
                }}
                {...formikUser.getFieldProps('bitrix24_manager_time')}
              />
              <label className='form-check-label' htmlFor={'userEditB24Time' + user.bitrix24_user_id}></label>
            </div>
          </td>
          <td role='cell' className='min-w-70px pe-0 px-0'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-block'>
              <input
                className='form-check-input'
                type='checkbox'
                form={'Userform' + user?.bitrix24_user_id}
                id={'userEditChat' + user.bitrix24_user_id}
                defaultChecked={user.chat}
                {...formikUser.getFieldProps('chat')}
              />
              <label className='form-check-label' htmlFor={'userEditChat' + user.bitrix24_user_id}></label>
            </div>
          </td>
          <td role='cell' className='min-w-70px pe-0 px-0'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-block'>
              <input
                className='form-check-input'
                type='checkbox'
                form={'Userform' + user?.bitrix24_user_id}
                id={'editAutoAnswer' + user.bitrix24_user_id}
                defaultChecked={user.auto_answer}
                onClick={(e: any) => {
                  const multRegistr = document.getElementById(
                    'editMultRegistration' + user.bitrix24_user_id
                  ) as HTMLInputElement
                  if (e.target.checked === true && multRegistr.checked === true) {
                    multRegistr.click()
                  }
                }}
                {...formikUser.getFieldProps('auto_answer')}
              />
              <label className='form-check-label' htmlFor={'editAutoAnswer' + user.bitrix24_user_id}></label>
            </div>
          </td>
          <td role='cell' className='min-w-70px pe-0 px-0'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-block'>
              <input
                className='form-check-input'
                type='checkbox'
                form={'Userform' + user?.bitrix24_user_id}
                id={'editMultRegistration' + user.bitrix24_user_id}
                defaultChecked={user.multiple_registration}
                onClick={(e: any) => {
                  const autoAnswer = document.getElementById(
                    'editAutoAnswer' + user.bitrix24_user_id
                  ) as HTMLInputElement
                  if (e.target.checked === true && autoAnswer.checked === true) {
                    autoAnswer.click()
                  }
                }}
                {...formikUser.getFieldProps('multiple_registration')}
              />
              <label className='form-check-label' htmlFor={'editMultRegistration' + user.bitrix24_user_id}></label>
            </div>
          </td>
          <td role='cell' className='min-w-100px pe-0 px-0'>
            <button
              form={'UserForm' + user?.bitrix24_user_id}
              type='submit'
              title='Save'
              className='btn btn-icon btn-light-success btn-active-color-light btn-sm me-1 align-self-center'
              disabled={isLoading}
            >
              {!isLoading && <KTSVG path='/media/icons/duotune/general/gen037.svg' className='align-self-center' />}
              {isLoading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  <span className='spinner-border spinner-border-sm align-middle'></span>
                </span>
              )}
            </button>
            <button
              form={'UserForm' + user?.bitrix24_user_id}
              className='btn btn-icon btn-light-primary btn-active-color-light btn-sm me-1 align-self-center'
              title='Close'
              onClick={() => {
                updateUserState(false)
                formikUser.resetForm()
              }}
              disabled={isLoading}
              hidden={isLoading}
            >
              {!isLoading && <KTSVG path='/media/icons/duotune/general/gen034.svg' className='align-self-center' />}
            </button>

            <div className='modal fade' tabIndex={-1} id={'SmartCallTimeModal' + user.id}>
              <div className='modal-dialog modal-dialog-centered mw-400px'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h2>Smart call time</h2>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
                    </div>
                  </div>
                  <div className='modal-body'>
                    <table className='table align-middle table-row-dashed fs-7 gy-5 dataTable no-footer'>
                      <thead>
                        <tr className='text-muted fw-bolder fs-8 text-uppercase gs-0'>
                          <th role='columnheader' className='min-w-70px'>
                            active
                          </th>
                          <th role='columnheader' className='min-w-70px'>
                            day
                          </th>
                          <th role='columnheader' className='min-w-150px'>
                            start time — end time
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr role='row' className='text-center'>
                          <td role='cell' className='min-w-70px pe-0'>
                            <div className='d-flex justify-content-center'>
                              <div className='form-check form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input align-self-center'
                                  type='checkbox'
                                  id={'MonActive' + user.bitrix24_user_id}
                                  defaultChecked={user.smart_call_time.MON_is_active}
                                  form={'Userform' + user?.bitrix24_user_id}
                                  {...formikUser.getFieldProps('smart_call_time.MON_is_active')}
                                />
                              </div>
                            </div>
                          </td>
                          <td role='cell' className='min-w-70px pe-0 text-uppercase text-gray-800'>
                            Monday
                          </td>
                          <td role='cell' className='d-flex min-w-150px pe-0 align-items-center'>
                            <input
                              className='max-w-70px form-control form-control-sm form-control-flush text-center text-gray-900'
                              id={'editstartTimeMon' + user?.bitrix24_user_id}
                              form={'Userform' + user?.bitrix24_user_id}
                              {...formikUser.getFieldProps('smart_call_time.MON_start_time')}
                            />
                            —
                            <input
                              className='max-w-70px form-control form-control-sm form-control-flush text-center text-gray-900'
                              id={'editendTimeMon' + user?.bitrix24_user_id}
                              form={'Userform' + user?.bitrix24_user_id}
                              {...formikUser.getFieldProps('smart_call_time.MON_end_time')}
                            />
                          </td>
                        </tr>
                        <tr role='row' className='text-center'>
                          <td role='cell' className='min-w-70px pe-0'>
                            <div className='d-flex justify-content-center'>
                              <div className='form-check form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input align-self-center'
                                  type='checkbox'
                                  id={'TueActive' + user.bitrix24_user_id}
                                  defaultChecked={user.smart_call_time.TUE_is_active}
                                  form={'Userform' + user?.bitrix24_user_id}
                                  {...formikUser.getFieldProps('smart_call_time.TUE_is_active')}
                                />
                              </div>
                            </div>
                          </td>
                          <td role='cell' className='min-w-70px pe-0 text-uppercase text-gray-800'>
                            Tuesday
                          </td>
                          <td role='cell' className='d-flex min-w-150px pe-0 align-items-center'>
                            <input
                              className='max-w-70px form-control form-control-sm form-control-flush text-center text-gray-900'
                              id={'editstartTimeTue' + user?.bitrix24_user_id}
                              form={'Userform' + user?.bitrix24_user_id}
                              {...formikUser.getFieldProps('smart_call_time.TUE_start_time')}
                            />
                            —
                            <input
                              className='max-w-70px form-control form-control-sm form-control-flush text-center text-gray-900'
                              id={'editendTimeTue' + user?.bitrix24_user_id}
                              form={'Userform' + user?.bitrix24_user_id}
                              {...formikUser.getFieldProps('smart_call_time.TUE_end_time')}
                            />
                          </td>
                        </tr>
                        <tr role='row' className='text-center'>
                          <td role='cell' className='min-w-70px pe-0'>
                            <div className='d-flex justify-content-center'>
                              <div className='form-check form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input align-self-center'
                                  type='checkbox'
                                  id={'WedActive' + user.bitrix24_user_id}
                                  defaultChecked={user.smart_call_time.WED_is_active}
                                  form={'Userform' + user?.bitrix24_user_id}
                                  {...formikUser.getFieldProps('smart_call_time.WED_is_active')}
                                />
                              </div>
                            </div>
                          </td>
                          <td role='cell' className='min-w-70px pe-0 text-uppercase text-gray-800'>
                            Wednesday
                          </td>
                          <td role='cell' className='d-flex min-w-150px pe-0 align-items-center'>
                            <input
                              className='max-w-70px form-control form-control-sm form-control-flush text-center text-gray-900'
                              id={'editstartTimeWed' + user?.bitrix24_user_id}
                              form={'Userform' + user?.bitrix24_user_id}
                              {...formikUser.getFieldProps('smart_call_time.WED_start_time')}
                            />
                            —
                            <input
                              className='max-w-70px form-control form-control-sm form-control-flush text-center text-gray-900'
                              id={'editendTimeWed' + user?.bitrix24_user_id}
                              form={'Userform' + user?.bitrix24_user_id}
                              {...formikUser.getFieldProps('smart_call_time.WED_end_time')}
                            />
                          </td>
                        </tr>
                        <tr role='row' className='text-center'>
                          <td role='cell' className='min-w-70px pe-0'>
                            <div className='d-flex justify-content-center'>
                              <div className='form-check form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input align-self-center'
                                  type='checkbox'
                                  id={'ThuActive' + user.bitrix24_user_id}
                                  defaultChecked={user.smart_call_time.THU_is_active}
                                  form={'Userform' + user?.bitrix24_user_id}
                                  {...formikUser.getFieldProps('smart_call_time.THU_is_active')}
                                />
                              </div>
                            </div>
                          </td>
                          <td role='cell' className='min-w-70px pe-0 text-uppercase text-gray-800'>
                            Thursday
                          </td>
                          <td role='cell' className='d-flex min-w-150px pe-0 align-items-center'>
                            <input
                              className='max-w-70px form-control form-control-sm form-control-flush text-center text-gray-900'
                              id={'editstartTimeThu' + user?.bitrix24_user_id}
                              form={'Userform' + user?.bitrix24_user_id}
                              {...formikUser.getFieldProps('smart_call_time.THU_start_time')}
                            />
                            —
                            <input
                              className='max-w-70px form-control form-control-sm form-control-flush text-center text-gray-900'
                              id={'editendTimeThu' + user?.bitrix24_user_id}
                              form={'Userform' + user?.bitrix24_user_id}
                              {...formikUser.getFieldProps('smart_call_time.THU_end_time')}
                            />
                          </td>
                        </tr>
                        <tr role='row' className='text-center'>
                          <td role='cell' className='min-w-70px pe-0'>
                            <div className='d-flex justify-content-center'>
                              <div className='form-check form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input align-self-center'
                                  type='checkbox'
                                  id={'FriActive' + user.bitrix24_user_id}
                                  defaultChecked={user.smart_call_time.FRI_is_active}
                                  form={'Userform' + user?.bitrix24_user_id}
                                  {...formikUser.getFieldProps('smart_call_time.FRI_is_active')}
                                />
                              </div>
                            </div>
                          </td>
                          <td role='cell' className='min-w-70px pe-0 text-uppercase text-gray-800'>
                            Friday
                          </td>
                          <td role='cell' className='d-flex min-w-150px pe-0 align-items-center'>
                            <input
                              className='max-w-70px form-control form-control-sm form-control-flush text-center text-gray-900'
                              id={'editstartTimeFri' + user?.bitrix24_user_id}
                              form={'Userform' + user?.bitrix24_user_id}
                              {...formikUser.getFieldProps('smart_call_time.FRI_start_time')}
                            />
                            —
                            <input
                              className='max-w-70px form-control form-control-sm form-control-flush text-center text-gray-900'
                              id={'editendTimeFri' + user?.bitrix24_user_id}
                              form={'Userform' + user?.bitrix24_user_id}
                              {...formikUser.getFieldProps('smart_call_time.FRI_end_time')}
                            />
                          </td>
                        </tr>
                        <tr role='row' className='text-center'>
                          <td role='cell' className='min-w-70px pe-0'>
                            <div className='d-flex justify-content-center'>
                              <div className='form-check form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input align-self-center'
                                  type='checkbox'
                                  id={'SatActive' + user.bitrix24_user_id}
                                  defaultChecked={user.smart_call_time.SAT_is_active}
                                  form={'Userform' + user?.bitrix24_user_id}
                                  {...formikUser.getFieldProps('smart_call_time.SAT_is_active')}
                                />
                              </div>
                            </div>
                          </td>
                          <td role='cell' className='min-w-70px pe-0 text-uppercase text-gray-800'>
                            Saturday
                          </td>
                          <td role='cell' className='d-flex min-w-150px pe-0 align-items-center'>
                            <input
                              className='max-w-70px form-control form-control-sm form-control-flush text-center text-gray-900'
                              id={'editstartTimeSat' + user?.bitrix24_user_id}
                              form={'Userform' + user?.bitrix24_user_id}
                              {...formikUser.getFieldProps('smart_call_time.SAT_start_time')}
                            />
                            —
                            <input
                              className='max-w-70px form-control form-control-sm form-control-flush text-center text-gray-900'
                              id={'editendTimeSat' + user?.bitrix24_user_id}
                              form={'Userform' + user?.bitrix24_user_id}
                              {...formikUser.getFieldProps('smart_call_time.SAT_end_time')}
                            />
                          </td>
                        </tr>
                        <tr role='row' className='text-center'>
                          <td role='cell' className='min-w-70px pe-0'>
                            <div className='d-flex justify-content-center'>
                              <div className='form-check form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input align-self-center'
                                  type='checkbox'
                                  id={'SunActive' + user.bitrix24_user_id}
                                  defaultChecked={user.smart_call_time.SUN_is_active}
                                  form={'Userform' + user?.bitrix24_user_id}
                                  {...formikUser.getFieldProps('smart_call_time.SUN_is_active')}
                                />
                              </div>
                            </div>
                          </td>
                          <td role='cell' className='min-w-70px pe-0 text-uppercase text-gray-800'>
                            Sanday
                          </td>
                          <td role='cell' className='d-flex min-w-150px pe-0 align-items-center'>
                            <input
                              className='max-w-70px form-control form-control-sm form-control-flush text-center text-gray-900'
                              id={'editstartTimeSun' + user?.bitrix24_user_id}
                              form={'Userform' + user?.bitrix24_user_id}
                              {...formikUser.getFieldProps('smart_call_time.SUN_start_time')}
                            />
                            —
                            <input
                              className='max-w-70px form-control form-control-sm form-control-flush text-center text-gray-900'
                              id={'editendTimeSun' + user?.bitrix24_user_id}
                              form={'Userform' + user?.bitrix24_user_id}
                              {...formikUser.getFieldProps('smart_call_time.SUN_end_time')}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className='modal-footer'>
                    <div className='card-footer d-flex justify-content-end py-2 px-1' style={{border: 'none'}}>
                      <button
                        type='button'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                        className='btn btn-sm btn-primary me-3 text-uppercase'
                      >
                        ok
                      </button>
                      <button
                        type='button'
                        className='btn btn-sm btn-light-primary align-self-center'
                        id='close-modal'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  )
}

export {OneUserInServiceBitrix24}
