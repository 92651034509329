import {FC, ReactNode} from 'react'
import {IAppsModel} from '../../../../../types/apps/AppsModel'
import {AppItemHeaderControl} from './AppItemHeaderControl'

type Props = {
  app: IAppsModel
}

type WithChildren = {
  children?: ReactNode
}

const AppItemHeaderWrapper: FC<Props & WithChildren> = ({app, children}) => {
  return (
    <div className='py-3 d-flex flex-stack flex-wrap'>
      <div className='d-flex align-items-center '>{children}</div>
      <AppItemHeaderControl app={app} />
    </div>
  )
}

export {AppItemHeaderWrapper}
