/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {Languages} from './Languages'
import {Avatar} from '../../../helpers/components/Avatar'
import {usersAPI} from '../../../../services/UsersService'

const HeaderUserMenu: FC = () => {
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <Avatar
            className='symbol symbol-circle symbol-50px me-5 fs-3'
            userAvatar={currentUser?.avatar}
            userFullName={currentUser?.fullname}
          />
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>{currentUser?.fullname}</div>
            <span className='fw-bold text-muted fs-7'>{currentUser?.email}</span>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'settings/profile/'} className='menu-link px-5'>
          Profile Info
        </Link>
        <Link to={'settings/profile/authlogs/'} className='menu-link px-5'>
          Auth Logs
        </Link>
      </div>

      <div className='separator my-2'></div>

      <Languages />

      <div className='menu-item px-5'>
        <Link to='/logout' className='menu-link px-5'>
          Sign Out
        </Link>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
