import {Column} from 'react-table'
import {IBitrixUserModel} from '../../../../../../../../types/apps/crm/biitrix24/BitrixUserModel'
import {BitrixUserCustomHeader} from './BitrixUserCustomHeader'
import {BitrixUsersEmailCell} from './BitrixUsersEmailCell'
import {BitrixUsersIDCell} from './BitrixUsersIDCell'
import {BitrixUsersNameCell} from './BitrixUsersNameCell'
import {BitrixUsersPhoneCell} from './BitrixUsersPhoneCell'

const BitrixUsersColumns: Column<IBitrixUserModel>[] = [
  {
    Header: (props) => <BitrixUserCustomHeader tableProps={props} title='Bitrix24 ID' className='min-w-125px' />,
    accessor: 'bitrix24_user_id',
    Cell: ({...props}) => <BitrixUsersIDCell id={props.data[props.row.index].bitrix24_user_id} />,
  },
  {
    Header: (props) => <BitrixUserCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    accessor: 'fullname',
    Cell: ({...props}) => <BitrixUsersNameCell name={props.data[props.row.index].fullname} />,
  },
  {
    Header: (props) => <BitrixUserCustomHeader tableProps={props} title='Number' className='min-w-125px' />,
    accessor: 'internal_number',
    Cell: ({...props}) => <BitrixUsersPhoneCell phone={props.data[props.row.index].internal_number} />,
  },
  {
    Header: (props) => <BitrixUserCustomHeader tableProps={props} title='Email' className='min-w-125px' />,
    accessor: 'email',
    Cell: ({...props}) => <BitrixUsersEmailCell email={props.data[props.row.index].email} />,
  },
]

export {BitrixUsersColumns}
