/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {PasswordMeterComponent} from '../../../template/assets/ts/components'
import {authAPI} from '../../../services/AuthService'
import {phoneValidate} from '../../../template/helpers/components/PhoneValidate'

const initialValues = {
  fullname: '',
  phone: '',
  company: '',
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  fullname: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Full name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  phone: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Phone is required'),
  password: Yup.string().min(8, 'Minimum 8 symbols').max(35, 'Maximum 35 symbols').required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  acceptTerms: Yup.bool().required('You must accept the data usage agreement and license agreement.'),
})

export function Registration() {
  const navigate = useNavigate()
  const [register, {isLoading: loading, isError}] = authAPI.useRegisterMutation()

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      await register({
        email: values.email,
        phone: values.phone,
        fullname: values.fullname,
        company: values.company,
        password: values.password,
        confirm_password: values.changepassword,
      })
        .unwrap()
        .then((response) => {
          setStatus(response?.detail)
          setTimeout(() => {
            navigate('/login', {replace: true})
          }, 7000)
        })
        .catch((err) => {
          setSubmitting(false)
          setStatus(err.data.detail)
        })
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='mb-10 text-center'>
        <h1 className='text-dark mb-3 fs-5'>Create an Account</h1>

        <div className='text-gray-500 fs-5'>
          Already have an account?
          <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
            Forgot Password ?
          </Link>
        </div>
      </div>

      {formik.status && (
        <>
          {isError && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}
          {!isError && (
            <div className='mb-lg-15 alert alert-primary'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}
        </>
      )}

      <div className='row fv-row mb-7'>
        <div className='col-xl-6'>
          <label className='form-label fw-bolder text-dark fs-6'>Full name</label>
          <input
            placeholder='Full name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('fullname')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.fullname && formik.errors.fullname,
              },
              {
                'is-valid': formik.touched.fullname && !formik.errors.fullname,
              }
            )}
          />
          {formik.touched.fullname && formik.errors.fullname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.fullname}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-xl-6'>
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>Phone</label>
            <input
              placeholder='Phone'
              type='text'
              autoComplete='off'
              onInput={(e: any) => {
                e.target.value = phoneValidate(e.target.value)
              }}
              {...formik.getFieldProps('phone')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.phone && formik.errors.phone,
                },
                {
                  'is-valid': formik.touched.phone && !formik.errors.phone,
                }
              )}
            />
            {formik.touched.phone && formik.errors.phone && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.phone}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Company</label>
        <input
          placeholder='Company'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('company')}
          className={clsx('form-control form-control-lg form-control-solid')}
        />
        {formik.touched.company && formik.errors.company && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.company}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>

      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='on'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          <div className='d-flex align-items-center mb-3' data-kt-password-meter-control='highlight'>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
        </div>
        <div className='text-muted'>Use 8 or more characters with a mix of letters, numbers & symbols.</div>
      </div>

      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <input
          type='password'
          placeholder='Password confirmation'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label className='form-check-label fw-bold text-gray-700 fs-6' htmlFor='kt_login_toc_agree'>
            I Agree the <a href='https://callbee.io/doc/personal_data_license.pdf'>personal data usage agreement</a> and{' '}
            <a href='https://callbee.io/doc/license_agreement.pdf'>license agreement</a>.
          </label>
          {/* <Link to='/auth/terms' className='ms-1 link-primary'>
              terms and conditions
            </Link> */}
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancel
          </button>
        </Link>
      </div>
    </form>
  )
}
