import {useParams} from 'react-router-dom'
import {AppWsLogs} from '../../../../template/helpers/components/AppWsLogs'

const Logs = () => {
  const {appId} = useParams()

  return (
    <div className='card card-custom card-stretch shadow-sm mb-5'>
      <div className='card-header'>
        <div className='d-flex flex-stack col-xl-12'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder m-0'>Logs</span>
            <span className='text-muted fw-bold mt-1 fs-7'>
              <span className='text-danger'>*</span>beta (only real time logs)
            </span>
          </h3>
        </div>
      </div>
      <div className='card-body card-scroll h-700px'>
        <div className='table-responsive'>
          <AppWsLogs client_id={appId as string} />
        </div>
      </div>
    </div>
  )
}

export {Logs}
