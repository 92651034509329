import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react'
import {RootState} from '../store/Store'
import {IAuthModel} from '../types'

const qs = require('qs')
const API_URL = process.env.REACT_APP_API_URL

interface IAuthLogin {
  username: string
  password: string
}

interface IRegister {
  email: string
  phone: string
  fullname: string
  company: string
  password: string
  confirm_password: string
}

interface IResponse {
  status: string
  detail: string
}

interface IToken {
  token: string
}

interface IUpdatePassword {
  token: string
  password: string
  confirm_password: string
}

interface IForgotPassword {
  email: string
}

export const authAPI = createApi({
  reducerPath: 'authAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers, {getState}) => {
      const accessToken = (getState() as RootState).authReduser.accessToken
      if (accessToken) headers.set('Authorization', `Bearer ${accessToken}`)
      return headers
    },
    credentials: 'include',
  }),
  tagTypes: ['auth'],
  endpoints: (build) => ({
    login: build.mutation<IAuthModel, IAuthLogin>({
      query: ({username, password}) => ({
        url: '/auth/login',
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: qs.stringify({
          username: username,
          password: password,
        }),
      }),
      // invalidatesTags: ['auth'],
    }),
    register: build.mutation<IResponse, IRegister>({
      query: ({email, phone, fullname, company, password, confirm_password}) => ({
        url: '/auth/signup',
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: {
          email: email,
          phone: phone,
          fullname: fullname,
          company: company,
          password: password,
          confirm_password: confirm_password,
        },
      }),
      // invalidatesTags: ['auth'],
    }),
    logout: build.query<void, void>({
      query: () => ({
        url: '/auth/logout',
        headers: {'Content-Type': 'application/json'},
      }),
    }),
    activateUser: build.query<void, IToken>({
      query: ({token}) => ({
        url: `/auth/activate/${token}`,
      }),
    }),
    forgotPassword: build.mutation<IResponse, IForgotPassword>({
      query: ({email}) => ({
        url: '/auth/forgot-password',
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: {
          email: email,
        },
      }),
    }),
    updatePassword: build.mutation<IResponse, IUpdatePassword>({
      query: ({token, password, confirm_password}) => ({
        url: `/auth/update-password/${token}`,
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: {
          password: password,
          confirm_password: confirm_password,
        },
      }),
    }),
  }),
})
