import React, {FC, createContext, useContext, ReactNode} from 'react'
import {usersAPI} from '../../services/UsersService'

type Props = {
  selectedLang: 'en' | 'ru'
}
const initialState: Props = {
  selectedLang: 'en',
}

const I18nContext = createContext<Props>(initialState)

const useLang = () => {
  return useContext(I18nContext).selectedLang
}

type WithChildren = {
  children?: ReactNode
}

const RootI18nProvider: FC<WithChildren> = ({children}) => {
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()

  const lang = currentUser ? ({selectedLang: currentUser?.language} as Props) : initialState

  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>
}

export {RootI18nProvider, useLang}
