/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {KTSVG} from '../../../../../template/helpers'
import {IAppsModel} from '../../../../../types/apps/AppsModel'
import {AppStatusById} from '../../../../../template/helpers/components/AppStatus'

type Props = {
  app: IAppsModel
  linkIsManagment?: boolean
}

const AppItemHeaderData: FC<Props> = ({app, linkIsManagment}) => {
  const {appId} = useParams()
  const [buffer, setBuffer] = useState(false)

  const clipboard = (copyData: string) => {
    setBuffer(true)
    navigator.permissions.query({name: 'clipboard-write' as PermissionName}).then((result) => {
      if (result.state === 'granted' || result.state === 'prompt') {
        navigator.clipboard.writeText(copyData)
      }
    })
    setTimeout(() => {
      setBuffer(false)
    }, 600)
  }

  return (
    <>
      <AppStatusById app_id={app.id} isActive={app.licenses.is_active} />
      <div className='me-3'>
        <div className='fw-bolder text-gray-700 ms-4 text-uppercase small mb-2'>Integration ID</div>
        <div className='d-flex align-items-center'>
          {appId ? (
            <div className={clsx('badge ms-3 me-2', {'badge-light-info': !buffer}, {'badge-light-success': buffer})}>
              ...{app.id.slice(30)}
            </div>
          ) : (
            <Link to={`${linkIsManagment === undefined || linkIsManagment ? '' : '/management'}/apps/${app.id}/`}>
              <div
                role='button'
                className={clsx('badge ms-3 me-2', {'badge-light-info': !buffer}, {'badge-light-success': buffer})}
              >
                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-info me-1' />
                ...{app.id.slice(30)}
              </div>
            </Link>
          )}
          <button
            onClick={() => clipboard(app.id)}
            className={clsx(
              'btn btn-link text-gray-900 pt-0 pb-0',
              {'text-hover-gray-700': !buffer},
              {'text-hover-success': buffer}
            )}
          >
            {!buffer ? (
              <KTSVG path='/media/icons/duotune/general/gen054.svg' className='svg-icon-primary' />
            ) : (
              <KTSVG path='/media/icons/duotune/arrows/arr012.svg' className='svg-icon-success' />
            )}
          </button>
        </div>
      </div>
      <div className='me-3'>
        <div className='fw-bolder text-gray-700 ms-4 text-uppercase small mb-2'>crm / pbx</div>
        <div className='d-flex align-items-center'>
          <div className='badge badge-light-primary ms-3'>{app.crm}</div>
          <KTSVG path='/media/icons/duotune/abstract/abs022.svg' className='svg-icon-gray-600 svg-icon-1x ms-3' />
          <div className='badge badge-light-warning ms-3'>{app.pbx}</div>
        </div>
      </div>
      <div>
        <div className='fw-bolder text-gray-700 text-uppercase small ms-4 mb-2'>Version</div>
        <div className='d-flex align-items-center ms-3'>
          <span className='badge badge-light-info'>{app.version}</span>
        </div>
      </div>
      <div>
        <div className='fw-bolder text-gray-700 text-uppercase small ms-5 mb-2'>Region</div>
        <div className='d-flex align-items-center ms-5'>
          {/* badge-circle */}
          {app.is_auto_mode && <span className='badge badge-light-danger small me-2'>A</span>}
          <span className='badge badge-light-info me-2'>{app.region}</span>
        </div>
      </div>
      <div>
        <div className='fw-bolder text-gray-700 text-uppercase small ms-5 mb-2'>CRM Domain</div>
        <div className='d-flex align-items-center ms-5'>
          <span className='badge badge-light-info me-2'>{app.crm_domain}</span>
        </div>
      </div>
    </>
  )
}

export {AppItemHeaderData}
