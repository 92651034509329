import React, {FC, useEffect} from 'react'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {useLayout} from '../core'
import {DrawerComponent} from '../../assets/ts/components'
import {WithChildren} from '../../helpers/react18MigrationHelpers'

const Content: FC<WithChildren> = ({children}) => {
  const {classes} = useLayout()
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])

  return (
    <div
      id='kt_content_container'
      className={clsx(
        {'container-fluid': location.pathname.indexOf('/apps/') !== -1},
        {[classes.contentContainer.join(' ')]: location.pathname === '/apps/'},
        {[classes.contentContainer.join(' ')]: location.pathname === '/management/apps/'},
        {[classes.contentContainer.join(' ')]: location.pathname === '/settings/profile/apps/'},
        {[classes.contentContainer.join(' ')]: location.pathname.indexOf('/management/users/') !== -1},
        {[classes.contentContainer.join(' ')]: location.pathname.indexOf('/apps/') === -1}
      )}
    >
      {children}
    </div>
  )
}

export {Content}
