import {IYeastarSettingsCreate, YeastarSettingsInitValues} from '../../../core/_models'
import {replaceProtokol} from '../../../../../template/helpers/ReplaceProtokol'
import {IAppsModel} from '../../../../../types/apps/AppsModel'
import {appsAPI} from '../../../../../services/AppsService'
import {KTSVG} from '../../../../../template/helpers'
import {useParams} from 'react-router-dom'
import {useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'

const YeastarSettingsEditShema = Yup.object().shape({
  api_is_active: Yup.boolean(),
  mp3_is_active: Yup.boolean(),
  host: Yup.string(),
  username: Yup.string(),
  secret: Yup.string(),
  ftp_path: Yup.string(),
})

const RecdordFiles = () => {
  const {appId} = useParams()
  const {data: app} = appsAPI.useFetchAppByIdQuery(appId!)
  const [yeastarState, updateYeastarState] = useState(false)
  const [updateApp, {isLoading}] = appsAPI.useUpdateAppMutation()
  const [apiActiveState, updateApiActiveState] = useState<boolean>(app?.settings.yeastar.api_is_active!)
  const [seriesState, updateSeriesState] = useState(app?.settings.yeastar.series)
  const [showPassword, setShowPassword] = useState(false)
  const [protocol, updateProtocol] = useState(
    app?.settings.yeastar.host.indexOf('http://') !== -1 ? 'http://' : 'https://'
  )

  const formik = useFormik<IYeastarSettingsCreate>({
    initialValues: Object.assign(YeastarSettingsInitValues, {
      api_is_active: app?.settings.yeastar.api_is_active,
      mp3_is_active: app?.settings.yeastar.mp3_is_active,
      host: app?.settings.yeastar.host,
      username: app?.settings.yeastar.username,
      secret: app?.settings.yeastar.secret,
      ftp_path: app?.settings.yeastar.ftp_path,
    }),
    validationSchema: YeastarSettingsEditShema,
    onSubmit: async (values) => {
      const data = {
        id: appId,
        settings: {
          ami: {
            host: seriesState === 'P' ? '0.0.0.0' : app?.settings.ami.host,
            username: seriesState === 'P' ? 'default' : app?.settings.ami.username,
            secret: seriesState === 'P' ? 'default' : app?.settings.ami.secret,
          },
          yeastar: {
            series: seriesState,
            api_is_active: seriesState === 'P' ? true : values.api_is_active,
            mp3_is_active: values.mp3_is_active,
            host: apiActiveState ? protocol + replaceProtokol(values.host) : values.host,
            username: values.username,
            secret: values.secret.trim(),
            ftp_path:
              seriesState === 'P'
                ? '/ftp_media/mmc/autorecords/'
                : values.ftp_path !== ''
                ? values.ftp_path
                : '/ftp_media/mmc/autorecords/',
          },
        },
      }
      await updateApp(data as IAppsModel)
      updateYeastarState(false)
      setShowPassword(false)
    },
  })

  return (
    <>
      {yeastarState === false && (
        <div className='card card-custom card-stretch shadow-sm mb-5'>
          <div className='card-header'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>
                Yeastar {app?.settings.yeastar.series === 'S' ? 'S-Series' : 'P-Series'}
              </h3>
            </div>
            <button className='btn btn-sm btn-primary align-self-center' onClick={() => updateYeastarState(true)}>
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-info me-1 align-self-center' />
              Edit
            </button>
          </div>
          <div className='card-body pt-5 pb-5'>
            {app?.settings.yeastar.series === 'S' && (
              <>
                <div className='row mb-5'>
                  <label className='col-lg-4 fw-bolder text-gray-600 text-uppercase small'>FTP / API</label>
                  <div className='col-lg-8'>
                    <span className='badge badge-light-dark'>
                      {app?.settings.yeastar.api_is_active || apiActiveState ? 'API' : 'FTP'}
                    </span>
                  </div>
                </div>
                <div className='separator separator-dashed my-5'></div>
              </>
            )}
            <div className='row mb-5'>
              <label className='col-lg-4 fw-bolder text-gray-600 text-uppercase small'>
                {apiActiveState === false ? 'FTP' : 'API'} static IP address
              </label>

              <div className='col-lg-8'>
                <span className='fw-bolder text-gray-800'>{app?.settings.yeastar.host}</span>
              </div>
            </div>
            <div className='separator separator-dashed my-5'></div>
            <div className='row mb-5'>
              <label className='col-lg-4 fw-bolder text-gray-600 text-uppercase small'>
                {apiActiveState === false ? 'FTP' : 'API'} username
              </label>

              <div className='col-lg-8'>
                <span className='fw-bolder text-gray-800'>{app?.settings.yeastar.username}</span>
              </div>
            </div>
            <div className='separator separator-dashed my-5'></div>
            <div className='row mb-5'>
              <label className='col-lg-4 fw-bolder text-gray-600 text-uppercase small'>
                {apiActiveState === false ? 'FTP' : 'API'} secret
              </label>

              <div className='col-lg-8'>
                <div className='input-group d-flex align-items-center'>
                  <input
                    className='form-control fw-bolder text-gray-800 border-0 fs-7 p-0'
                    type={showPassword ? 'text' : 'password'}
                    value={app?.settings.yeastar.secret}
                    style={{borderRadius: 0}}
                    readOnly
                  ></input>
                  <span
                    className='border-0 p-0 me-2'
                    style={{cursor: 'pointer'}}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <i
                      className={clsx('bi text-gray-800 fs-3', {
                        'bi-eye-fill': !showPassword,
                        'bi-eye-slash-fill': showPassword,
                      })}
                    ></i>
                  </span>
                </div>
              </div>
            </div>
            {app?.settings.yeastar.api_is_active === false && app.settings.yeastar.series === 'S' && (
              <>
                <div className='separator separator-dashed my-5'></div>
                <div className='row mb-5'>
                  <label className='col-lg-4 fw-bolder text-gray-600 text-uppercase small'>FTP path</label>

                  <div className='col-lg-8'>
                    <span className='fw-bolder text-gray-800'>{app?.settings.yeastar.ftp_path}</span>
                  </div>
                </div>
              </>
            )}
            <div className='separator separator-dashed my-5'></div>
            <div className='row mb-5'>
              <label className='col-lg-4 fw-bolder text-gray-600 text-uppercase small'>Convert to mp3</label>

              <div className='col-lg-8'>
                {app?.settings.yeastar.mp3_is_active === true && <span className='badge badge-light-success'>ON</span>}
                {app?.settings.yeastar.mp3_is_active === false && <span className='badge badge-light-danger'>OFF</span>}
              </div>
            </div>
          </div>
        </div>
      )}
      {yeastarState === true && (
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card card-custom card-stretch shadow-sm mb-5'>
            <div className='card-header'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Edit Yeastar</h3>
              </div>
            </div>
            <div className='card-body pt-5 pb-5'>
              {seriesState !== 'P' && (
                <div className='row mb-5'>
                  <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                    <span className='small text-uppercase'>FTP / API</span>
                  </label>

                  <div className='col-lg-8 d-flex align-items-center'>
                    <div className='form-check form-switch form-check-custom form-check-solid me-10'>
                      <input
                        className='form-check-input h-20px w-30px align-self-center'
                        type='checkbox'
                        onClick={(e: any) => {
                          updateApiActiveState(!apiActiveState)
                        }}
                        checked={apiActiveState}
                        {...formik.getFieldProps('api_is_active')}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className='row mb-5'>
                <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>
                    {apiActiveState === false ? 'FTP' : 'API'} static IP address
                  </span>
                </label>
                <div className='col-lg-8'>
                  {apiActiveState ? (
                    <div className='input-group input-group-solid'>
                      <select
                        className='form-select form-select-solid b-none input-group-text text-start mw-100px'
                        defaultValue={app?.settings.yeastar.host.indexOf('http://') !== -1 ? 'http://' : 'https://'}
                        onChange={(e: any) => {
                          updateProtocol(e.target.value)
                        }}
                      >
                        <option value='https://'>https://</option>
                        <option value='http://'>http://</option>
                      </select>
                      <input
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-900'
                        placeholder='example pbx.callbee.io:8088'
                        onChange={(e) => {
                          formik.setFieldValue('host', e.target.value)
                        }}
                        defaultValue={replaceProtokol(
                          app?.settings.yeastar.host ? replaceProtokol(app?.settings.yeastar.host) : ''
                        )}
                        onInput={(e: any) => {
                          e.target.value = replaceProtokol(e.target.value)
                        }}
                      />
                    </div>
                  ) : (
                    <input
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-900'
                      placeholder='example: pbx.callbee.io:21'
                      {...formik.getFieldProps('host')}
                    />
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>{apiActiveState === false ? 'FTP' : 'API'} username</span>
                </label>

                <div className='col-lg-8'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-900'
                    placeholder={apiActiveState === false ? 'FTP username' : 'API username'}
                    {...formik.getFieldProps('username')}
                  />
                </div>
              </div>
              <div className='row mb-5'>
                <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>{apiActiveState === false ? 'FTP' : 'API'} secret</span>
                </label>

                <div className='col-lg-8'>
                  <div className='input-group input-group-solid'>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-900'
                      placeholder={apiActiveState === false ? 'FTP secret' : 'API secret'}
                      autoComplete='off'
                      {...formik.getFieldProps('secret')}
                    />
                    <span
                      className='input-group-text border-0'
                      style={{cursor: 'pointer'}}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <i
                        className={clsx('bi text-gray-800 fs-3', {
                          'bi-eye-fill': !showPassword,
                          'bi-eye-slash-fill': showPassword,
                        })}
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
              {apiActiveState === false && seriesState === 'S' && (
                <div className='row mb-5'>
                  <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                    <span className='small text-uppercase'> FTP path</span>
                  </label>
                  <div className='col-lg-8'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-900'
                      placeholder='/ftp_media/mmc/autorecords/'
                      {...formik.getFieldProps('ftp_path')}
                    />
                  </div>
                </div>
              )}
              <div className='row mb-5'>
                <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>Convert to mp3</span>
                </label>

                <div className='col-lg-8 d-flex align-items-center'>
                  <div className='form-check form-switch form-check-custom form-check-solid me-10'>
                    <input
                      className='form-check-input h-20px w-30px align-self-center'
                      type='checkbox'
                      defaultChecked={app?.settings.yeastar.mp3_is_active === true ? true : false}
                      {...formik.getFieldProps('mp3_is_active')}
                    />
                  </div>
                </div>
              </div>
              <div className='card-footer d-flex justify-content-end py-2 px-1' style={{border: 'none'}}>
                <button type='submit' className='btn btn-sm btn-primary me-3' disabled={isLoading}>
                  {!isLoading && 'Save Changes'}
                  {isLoading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
                <button
                  className='btn btn-sm btn-light-primary align-self-center'
                  onClick={() => {
                    updateYeastarState(false)
                    setShowPassword(false)
                    updateSeriesState(app?.settings.yeastar.series)
                    updateApiActiveState(app?.settings.yeastar.api_is_active!)
                    formik.resetForm()
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  )
}

export {RecdordFiles}
