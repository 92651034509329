import {FC} from 'react'
import {App} from '../App'
import {PrivateRoutes} from './PrivateRoutes'
import {AuthPage, Logout} from '../modules/auth'
import {usersAPI} from '../services/UsersService'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {LayoutSplashScreen} from '../template/layout/core'
import {useAppSelector} from '../hooks/redux'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'

const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {accessToken} = useAppSelector((state) => state.authReduser)
  const {isLoading, isSuccess} = usersAPI.useFetchGetMeQuery()

  // Don't delete !!!
  // useEffect(() => {
  //   const updateSubdomain = () => {
  //     const wl = window.location
  //     if (currentUser?.is_account) {
  //       if (wl.hostname.split('.').length === 2) {
  //         const newHref = `${wl.protocol}//${currentUser.account[0].subdomain}.${wl.host}${wl.pathname}`
  //         dispatch(logoutAction())
  //         window.location.href = newHref
  //       } else if (wl.hostname.split('.')[0] !== currentUser.account[0].subdomain) {
  //         const newHref = `${wl.protocol}//${currentUser.account[0].subdomain}.${wl.host
  //           .split('.')
  //           .slice(-2)
  //           .join('.')}${wl.pathname}`
  //         dispatch(logoutAction())
  //         window.location.href = newHref
  //       }
  //     } else if (wl.hostname.split('.').length >= 3) {
  //       dispatch(logoutAction())
  //       const newHref = `${wl.protocol}//${wl.host.split('.').slice(-2).join('.')}${wl.pathname}`
  //       window.location.href = newHref
  //     }
  //   }

  //   if (currentUser) {
  //     updateSubdomain()
  //   }
  // }, [currentUser, dispatch])

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {isSuccess && accessToken ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/apps/' />} />
            </>
          ) : (
            <>
              {!isLoading && (
                <>
                  <Route path='auth/*' element={<AuthPage />} />
                  <Route path='*' element={<Navigate to='/auth' />} />
                </>
              )}
              {isLoading && (
                <>
                  <Route path='*' element={<LayoutSplashScreen />} />
                </>
              )}
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
