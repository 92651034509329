import {FC} from 'react'

type Props = {
  email: string
}

const BitrixUsersEmailCell: FC<Props> = ({email}) => (
  <input className='text-gray-900 form-control form-control-sm form-control-flush' value={email} disabled />
)

export {BitrixUsersEmailCell}
