import {useParams} from 'react-router-dom'
import {appsAPI} from '../../../../../services/AppsService'

const RecordURL = () => {
  const {appId} = useParams()
  const {data: app} = appsAPI.useFetchAppByIdQuery(appId!)
  return (
    <>
      <div className='row mb-5'>
        <label className='col-lg-4 fw-bolder text-gray-600 text-uppercase small'>Record URL</label>

        <div className='col-lg-8'>
          <span className='fw-bolder text-gray-800'>{app?.settings.asterisk.record_url}</span>
        </div>
      </div>
    </>
  )
}

export {RecordURL}
