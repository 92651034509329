export interface IBitrix24LineUpdate {
  id?: string
  line: string
  description: string
  responsible_user: string
  smart_call: boolean
  b24_manager_time: boolean
  start_time: string
  end_time: string
  auto_lead: boolean
  chat: boolean
}

export const Bitrix24LineUpdateInitValues = {
  id: '',
  line: '',
  description: '',
  responsible_user: '1',
  smart_call: false,
  b24_manager_time: false,
  start_time: '09:00',
  end_time: '18:00',
  auto_lead: false,
  chat: false,
}

export interface IAmoCRMLineUpdate {
  id?: string
  line: string
  description: string
  pipeline: string
  responsible_user: string
  smart_call: boolean
  start_time: string
  end_time: string
  incoming_call: string
  udpc: boolean
  rsm: boolean
  find_unsorted: boolean
}

export const AmoCRMLineUpdateInitValues = {
  id: '',
  line: '',
  description: '',
  pipeline: 'Main',
  responsible_user: '',
  smart_call: false,
  start_time: '09:00',
  end_time: '18:00',
  incoming_call: 'Unsorted',
  udpc: false,
  rsm: false,
  find_unsorted: false,
}

export interface IAmoCRMLineNew {
  line_newline: string
  description_newline: string
  pipeline_newline: string
  responsible_user_newline: string
  smart_call_newline: boolean
  start_time_newline: string
  end_time_newline: string
  incoming_call_newline: string
  udpc_newline: boolean
  rsm_newline: boolean
  find_unsorted_newline: boolean
}

export interface IBitrix24LineNew {
  id_newline?: string
  line_newline: string
  description_newline: string
  responsible_user_newline: string
  smart_call_newline: boolean
  b24_manager_time_newline: boolean
  start_time_newline: string
  end_time_newline: string
  auto_lead_newline: boolean
  chat_newline: boolean
}

export const Bitrix24LineNewInitValues = {
  id_newline: '',
  line_newline: '',
  description_newline: '',
  responsible_user_newline: '1',
  smart_call_newline: false,
  b24_manager_time_newline: false,
  start_time_newline: '09:00',
  end_time_newline: '18:00',
  auto_lead_newline: false,
  chat_newline: false,
}

export interface IAutoLeadUpdate {
  auto_lead: boolean
}

export const AutoLeadUpdateInitValues = {
  auto_lead: false,
}

export const Bitrix24UserInServiceInitValues = {
  id: '',
  is_active: false,
  bitrix24_user_id: '',
  internal_number: '',
  external_number: '',
  fullname: '',
  bitrix24_manager_time: false,
  smart_call: false,
  smart_call_time: {
    MON_is_active: false,
    MON_start_time: '09:00',
    MON_end_time: '18:00',
    TUE_is_active: false,
    TUE_start_time: '09:00',
    TUE_end_time: '18:00',
    WED_is_active: false,
    WED_start_time: '09:00',
    WED_end_time: '18:00',
    THU_is_active: false,
    THU_start_time: '09:00',
    THU_end_time: '18:00',
    FRI_is_active: false,
    FRI_start_time: '09:00',
    FRI_end_time: '18:00',
    SAT_is_active: false,
    SAT_start_time: '09:00',
    SAT_end_time: '18:00',
    SUN_is_active: false,
    SUN_start_time: '09:00',
    SUN_end_time: '18:00',
  },
  chat: false,
  auto_answer: false,
  multiple_registration: false,
}

export interface IAMISettingsCreate {
  host: string
  username: string
  secret: string
}

export const AMISettingsInitValues = {
  host: '',
  username: '',
  secret: '',
}

export interface IRegionSettingsCreate {
  region: 'BY' | 'RU' | 'DE'
  time_zone: string
  is_auto_mode: boolean
}

export const RegionSettingsInitValues = {
  region: 'BY',
  time_zone: '',
  is_auto_mode: '',
}

export interface IOtherSettingsCreate {
  crm_domain: string
  ws_amocrm_token: string
  ws_google_chrome_token: string
  users_in_service: boolean
  record_url: string
  ignore_trunks: string
}

export const OtherSettingsInitValues = {
  crm_domain: '',
  ws_amocrm_token: '',
  ws_google_chrome_token: '',
  users_in_service: false,
  record_url: '',
  ignore_trunks: '',
}

export interface IYeastarSettingsCreate {
  api_is_active: boolean
  mp3_is_active: boolean
  host: string
  username: string
  secret: string
  ftp_path: string
}

export const YeastarSettingsInitValues = {
  api_is_active: false,
  mp3_is_active: false,
  host: '',
  username: '',
  secret: '',
  ftp_path: '/ftp_media/mmc/autorecords/',
}

export interface IOutgoingCallModeUpdate {
  outgoing_call_mode: string
}

export const OutgoingCallModeInitValues = {
  outgoing_call_mode: 'Unsorted',
}

export type AMOLine = {
  id: string
  line: string
  description: string
  pipeline: string
  responsible_user: string
  incoming_call_mode: {}
  smart_call: boolean
  start_time: string
  end_time: string
  udpc: boolean
  repeat_sales_mode: boolean
  find_unsorted: boolean
}
