import {useParams} from 'react-router-dom'
import {appsAPI} from '../../../../../../services/AppsService'
import {Bitrix24UsersInServiceHeader} from './Bitrix24UsersInServiceHeader'
import {OneUserInServiceBitrix24} from './OneUserInServiceBitrix24'

const Bitri24UsersInService = () => {
  const {appId} = useParams()
  const {data: app} = appsAPI.useFetchAppByIdQuery(appId!)
  const users = app?.settings.bitrix24.users_in_service_list

  return (
    <table className='table align-middle table-row-dashed fs-7 gy-5 dataTable no-footer'>
      <Bitrix24UsersInServiceHeader />
      <tbody>
        {users?.map((u) => (
          <OneUserInServiceBitrix24 user={u} key={u.id} />
        ))}
      </tbody>
    </table>
  )
}

export {Bitri24UsersInService}
