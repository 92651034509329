import {FC, ReactNode} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../template/helpers'

type WithChildren = {
  children?: ReactNode
}

const AppsHeaderWrapper: FC<WithChildren> = ({children}) => {
  const intl = useIntl()
  return (
    <div className='card mb-5 mb-xl-10 shadow-sm'>
      <div className='card-header'>
        <div className='card-title m-0'>
          <KTSVG path='/media/icons/duotune/abstract/abs022.svg' className='svg-icon-primary svg-icon-2x me-2' />
          <h2 className='fw-bolder mb-0'>{intl.formatMessage({id: 'MENU.APPS'})}</h2>
        </div>
      </div>
      <div className='card-body pt-3 pb-5'>{children}</div>
    </div>
  )
}

export {AppsHeaderWrapper}
