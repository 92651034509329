import clsx from 'clsx'
import {useState} from 'react'
import {useParams} from 'react-router-dom'
import {appsAPI} from '../../../../../services/AppsService'
import {KTSVG} from '../../../../../template/helpers'

const AMOTokens = () => {
  const [bufferAmoToken, setBufferAmoToken] = useState(false)
  const [bufferGoogleToken, setBufferGoogleToken] = useState(false)
  const {appId} = useParams()
  const {data: app} = appsAPI.useFetchAppByIdQuery(appId!)

  const clipboardAMOToken = (copyData: string) => {
    setBufferAmoToken(true)
    navigator.permissions.query({name: 'clipboard-write' as PermissionName}).then((result) => {
      if (result.state === 'granted' || result.state === 'prompt') {
        navigator.clipboard.writeText(copyData)
      }
    })
    setTimeout(() => {
      setBufferAmoToken(false)
    }, 600)
  }

  const clipboardGoogleToken = (copyData: string) => {
    setBufferGoogleToken(true)
    navigator.permissions.query({name: 'clipboard-write' as PermissionName}).then((result) => {
      if (result.state === 'granted' || result.state === 'prompt') {
        navigator.clipboard.writeText(copyData)
      }
    })
    setTimeout(() => {
      setBufferGoogleToken(false)
    }, 600)
  }

  return (
    <>
      <div className='row mb-5'>
        <label className='col-lg-4 fw-bolder text-gray-600 text-uppercase small'>AmoCRM Token</label>

        <div className='col-lg-8 d-flex'>
          <div className='d-flex justify-content-start flex-column me-3'>
            <span
              className={clsx('badge', {'badge-light-info': !bufferAmoToken}, {'badge-light-success': bufferAmoToken})}
            >
              {app?.settings.amocrm.ws_tokens.ws_amocrm_token}
            </span>
          </div>
          <div className='d-flex flex-column'>
            <button
              onClick={() => clipboardAMOToken(app?.settings.amocrm.ws_tokens.ws_amocrm_token!)}
              className='btn btn-link text-gray-900 text-hover-primary pt-0 pb-0'
            >
              {!bufferAmoToken ? (
                <KTSVG path='/media/icons/duotune/general/gen054.svg' className='svg-icon-gray-900 svg-icon' />
              ) : (
                <KTSVG path='/media/icons/duotune/arrows/arr012.svg' className='svg-icon-success' />
              )}
            </button>
          </div>
        </div>
      </div>
      <div className='separator separator-dashed my-5'></div>
      <div className='row mb-5'>
        <label className='col-lg-4 fw-bolder text-gray-600 text-uppercase small'>Google Chrome Token</label>

        <div className='col-lg-8 d-flex'>
          <div className='d-flex justify-content-start flex-column me-3'>
            <span
              className={clsx(
                'badge',
                {'badge-light-info': !bufferGoogleToken},
                {'badge-light-success': bufferGoogleToken}
              )}
            >
              {app?.settings.amocrm.ws_tokens.ws_google_chrome_token}
            </span>
          </div>

          <div className='d-flex flex-column'>
            <button
              onClick={() => clipboardGoogleToken(app?.settings.amocrm.ws_tokens.ws_google_chrome_token!)}
              className='btn btn-link text-gray-900 text-hover-primary pt-0 pb-0'
            >
              {!bufferGoogleToken ? (
                <KTSVG path='/media/icons/duotune/general/gen054.svg' className='svg-icon-gray-900 svg-icon' />
              ) : (
                <KTSVG path='/media/icons/duotune/arrows/arr012.svg' className='svg-icon-success' />
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export {AMOTokens}
