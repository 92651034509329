import {useParams} from 'react-router-dom'
import {appsAPI} from '../../../services/AppsService'
import {AMISettings} from './common/AMISettings'
import {Region} from './common/Region'
import {OtherSettings} from './common/OtherSettings'
import {RecdordFiles} from './pbx/yeastar/RecordFiles'

const AppInfo = () => {
  const {appId} = useParams()
  const {data: app} = appsAPI.useFetchAppByIdQuery(appId!)

  return (
    <>
      {app?.pbx === 'ASTERISK' && <AMISettings />}
      {app?.pbx === 'YEASTAR' && app?.settings.yeastar.series === 'S' && <AMISettings />}
      {app?.pbx === 'YEASTAR' && <RecdordFiles />}
      <OtherSettings />
      <Region />
    </>
  )
}

export {AppInfo}
