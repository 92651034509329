const Bitrix24LineHeader = () => {
  return (
    <thead>
      <tr className='text-muted fw-bolder fs-8 text-uppercase gs-0 text-center'>
        <th role='columnheader' className='min-w-150px'>
          line (did)
        </th>
        <th role='columnheader' className='min-w-140px px-0'>
          description
        </th>
        <th role='columnheader' className='min-w-100px px-0'>
          user id
        </th>
        <th role='columnheader' className='min-w-85px px-0'>
          smart call
        </th>
        <th role='columnheader' className='min-w-150px px-0 required'>
          manager time
        </th>
        <th role='columnheader' className='min-w-150px px-0'>
          smart call by time
        </th>
        <th role='columnheader' className='min-w-100px px-0'>
          auto lead
        </th>
        <th role='columnheader' className='min-w-100px px-0'>
          add to chat
        </th>
        <th role='columnheader' className='min-w-100px px-0'>
          edit
        </th>
      </tr>
    </thead>
  )
}

export {Bitrix24LineHeader}
