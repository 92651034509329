/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {NavLink, Outlet, useLocation, useNavigate, useParams} from 'react-router-dom'
import {appsAPI} from '../../services/AppsService'
import {EmptyAppPage} from './components/EmptyAppPage'
import {PanelAppItem} from './components/panel_app/PanelAppItem'

const AppsHeader: FC = () => {
  const location = useLocation()
  const {appId} = useParams()
  const navigate = useNavigate()
  const goBack = () => navigate('/management/apps/')
  const {data: app, isLoading, error} = appsAPI.useFetchAppByIdQuery(appId!)

  if (app === null || error) {
    return <EmptyAppPage error={error} />
  }

  return (
    <>
      {location.pathname.indexOf('/management') !== -1 && (
        <>
          <div className='mb-6'>
            <button onClick={() => goBack()} className='btn btn-sm btn-primary'>
              <i className='fas fa-chevron-left' />
              Go back
            </button>
          </div>
        </>
      )}

      <>
        {isLoading ? (
          'Loading...'
        ) : (
          <>
            <div className='d-flex flex-column flex-lg-row'>
              <PanelAppItem app={app!} firstElement={false} />

              <div className='flex-lg-row-fluid ms-lg-15'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-6 fw-bolder flex-nowrap mb-5'>
                  <li className='nav-item'>
                    <NavLink className='nav-link text-muted text-active-primary text-hover-primary' to='./'>
                      <small className='text-uppercase'>Info</small>
                    </NavLink>
                  </li>
                  {(app?.crm === 'BITRIX24' || app?.crm === 'AMOCRM') && (
                    <li className='nav-item'>
                      <NavLink className='nav-link text-muted text-active-primary text-hover-primary' to='lines/'>
                        <small className='text-uppercase'>Lines</small>
                      </NavLink>
                    </li>
                  )}
                  <li className='nav-item'>
                    <NavLink className='nav-link text-muted text-active-primary text-hover-primary' to='users/'>
                      <small className='text-uppercase'>Users</small>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink className='nav-link text-muted text-active-primary text-hover-primary' to='license/'>
                      <small className='text-uppercase'>License</small>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink className='nav-link text-muted text-active-primary text-hover-primary' to='logs/'>
                      <small className='text-uppercase'>Logs</small>
                    </NavLink>
                  </li>
                </ul>
                <Outlet />
              </div>
            </div>
          </>
        )}
      </>
    </>
  )
}

export {AppsHeader}
