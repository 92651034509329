import {useParams} from 'react-router-dom'
import {appsAPI} from '../../../../../services/AppsService'

const UseB24Users = () => {
  const {appId} = useParams()
  const {data: app} = appsAPI.useFetchAppByIdQuery(appId!)
  return (
    <>
      <div className='row mb-5'>
        <label className='col-lg-4 fw-bolder text-gray-600 text-uppercase small'>Use Bitrix24 Users</label>

        <div className='col-lg-8'>
          {app?.settings.bitrix24.users_in_service === true && <span className='badge badge-light-success'>ON</span>}
          {app?.settings.bitrix24.users_in_service === false && <span className='badge badge-light-danger'>OFF</span>}
        </div>
      </div>
    </>
  )
}

export {UseB24Users}
