/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {KTSVG} from '../../../helpers'
import * as Yup from 'yup'
import {IAmoCRMLineNew} from '../../../../modules/apps/core/_models'
import {useFormik} from 'formik'
import {useParams} from 'react-router-dom'
import {appsAPI} from '../../../../services/AppsService'
import {IAppsModel} from '../../../../types/apps/AppsModel'
import {IAmoCRMLineModel} from '../../../../types/apps/crm/amocrm/AmoCRMLineModel'

const addLineAmoCRMSettingsEditShema = Yup.object().shape({
  line_newline: Yup.string().required('Line is required'),
  description_newline: Yup.string(),
  responsible_user_newline: Yup.string().required('Please, enter responsible user'),
  pipeline_newline: Yup.string(),
  smart_call_newline: Yup.boolean(),
  start_time_newline: Yup.string(),
  end_time_newline: Yup.string(),
  incoming_call_newline: Yup.string(),
  udpc_newline: Yup.boolean(),
  rsm_newline: Yup.boolean(),
  find_unsorted_newline: Yup.boolean(),
})

const AddLineAmoCRM: FC = () => {
  const {appId} = useParams()
  const {data: app} = appsAPI.useFetchAppByIdQuery(appId!)
  const [updateApp, {isLoading}] = appsAPI.useUpdateAppMutation()
  const [smartCallState, updateSmartCallState] = useState(false)
  const all_lines: IAmoCRMLineModel[] = app?.settings.amocrm.lines!
  let obj: IAmoCRMLineModel[] = []
  const pipelines: any = app?.settings.amocrm.pipelines
  const users: any = app?.settings.amocrm.users

  const checkDID = (did: string) => {
    const submit_btn = document.getElementById('submit_btn')
    let did_mark = false
    for (let i = 0; i < all_lines.length; i++) {
      if (did !== '' && did === all_lines[i].line) {
        did_mark = true
        break
      }
    }
    if (did_mark === false) {
      submit_btn?.removeAttribute('disabled')
      document.getElementById('error_message')?.classList.add('d-none')
    } else {
      submit_btn?.setAttribute('disabled', 'true')
      document.getElementById('error_message')?.classList.remove('d-none')
    }
  }

  const formikNewLine = useFormik<IAmoCRMLineNew>({
    initialValues: {
      line_newline: '',
      description_newline: '',
      pipeline_newline: pipelines.length !== 0 ? pipelines[0].name : 'Main',
      responsible_user_newline: users.length !== 0 ? users[0].amocrm_user_id : '',
      smart_call_newline: false,
      start_time_newline: '09:00',
      end_time_newline: '18:00',
      incoming_call_newline: 'Unsorted',
      udpc_newline: false,
      rsm_newline: false,
      find_unsorted_newline: false,
    },
    validationSchema: addLineAmoCRMSettingsEditShema,
    onSubmit: (values) => {
      const new_line = {
        line: values.line_newline,
        description: values.description_newline,
        pipeline: values.pipeline_newline,
        responsible_user: values.responsible_user_newline,
        smart_call: values.smart_call_newline,
        start_time: values.start_time_newline,
        end_time: values.end_time_newline,
        incoming_call_mode: {
          none: values.incoming_call_newline === 'None' ? true : false,
          contact: values.incoming_call_newline === 'Contact' ? true : false,
          contact_lead: values.incoming_call_newline === 'ContactLead' ? true : false,
          unsorted: values.incoming_call_newline === 'Unsorted' ? true : false,
        },
        udpc: values.udpc_newline,
        repeat_sales_mode: values.rsm_newline,
        find_unsorted: values.find_unsorted_newline,
      }
      obj = [...all_lines, new_line]

      const data = {
        id: appId,
        settings: {
          amocrm: {
            lines: obj,
          },
        },
      }
      updateApp(data as IAppsModel)
        .unwrap()

        .then(() => {
          const close = document.getElementById('close-modal')
          const smartCallNewSwitch = document.getElementById('smartCallNewSwitch')
          const udpcNewSwitch = document.getElementById('udpcNewSwitch')
          const rsmNewSwitch = document.getElementById('rsmNewSwitch')
          const findUnsortedNewSwitch = document.getElementById('findUnsortedNewSwitch')

          if (smartCallNewSwitch?.getAttribute('value') === 'true') {
            smartCallNewSwitch?.click()
          }

          if (udpcNewSwitch?.getAttribute('value') === 'true') {
            udpcNewSwitch?.click()
          }

          if (rsmNewSwitch?.getAttribute('value') === 'true') {
            rsmNewSwitch?.click()
          }

          if (findUnsortedNewSwitch?.getAttribute('value') === 'true') {
            findUnsortedNewSwitch?.click()
          }
          updateSmartCallState(false)
          formikNewLine.resetForm()
          close?.click()
        })
    },
  })

  return (
    <form onSubmit={formikNewLine.handleSubmit} noValidate className='form'>
      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog modal-dialog-centered mw-700px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2>Create new line</h2>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
              </div>
            </div>
            <div className='modal-body'>
              <div className='flex-row-fluid py-5 px-15'>
                <div className='current' data-kt-stepper-element='content'>
                  <div className='col-12'>
                    <div className='mb-10'>
                      <div className='d-flex flex-stack'>
                        <label className='d-flex align-items-center col-5 self-align-center required'>
                          <span className='small text-uppercase fw-bolder text-gray-600'>Line (DID)</span>
                        </label>
                        <input
                          type='text'
                          className='form-control form-control-solid'
                          placeholder='DID'
                          onInput={(e: any) => {
                            checkDID(e.target.value)
                          }}
                          {...formikNewLine.getFieldProps('line_newline')}
                        />
                      </div>
                      <div className='d-flex justify-content-end'>
                        <div className='fv-plugins-message-container'>
                          <div id='error_message' className='fv-help-block text-danger d-none'>
                            This DID is already in used
                          </div>
                        </div>
                      </div>

                      <div className='d-flex justify-content-end'>
                        {formikNewLine.touched.line_newline && formikNewLine.errors.line_newline && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger'>{formikNewLine.errors.line_newline}</div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='mb-10 d-flex flex-stack'>
                      <label className='d-flex align-items-center col-5 self-align-center'>
                        <span className='small text-uppercase fw-bolder text-gray-600'>Description</span>
                      </label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='Description'
                        {...formikNewLine.getFieldProps('description_newline')}
                      />
                    </div>
                    <div className='mb-10'>
                      <div className='d-flex flex-stack'>
                        <div className='stepper-label align-items-center col-5 self-align-center'>
                          <h3 className='small text-uppercase fw-bolder text-gray-600 required'>User</h3>
                          <div className='text-muted fs-8'>For missed calls</div>
                        </div>
                        {users.length !== 0 ? (
                          <select
                            className='form-select form-select-solid'
                            {...formikNewLine.getFieldProps('responsible_user_newline')}
                          >
                            {users?.map((user: any) => (
                              <option value={user.amocrm_user_id} key={user.fullname}>
                                {user.amocrm_user_id} - {user.fullname}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <input
                            className='form-control form-control-solid'
                            placeholder='user ID'
                            {...formikNewLine.getFieldProps('responsible_user_newline')}
                          />
                        )}
                      </div>
                      <div className='d-flex justify-content-end'>
                        {formikNewLine.touched.responsible_user_newline &&
                          formikNewLine.errors.responsible_user_newline && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger'>
                                {formikNewLine.errors.responsible_user_newline}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className='mb-10'>
                      <div className='d-flex flex-stack'>
                        <div className='stepper-label align-items-center col-5 self-align-center'>
                          <h3 className='small text-uppercase fw-bolder text-gray-600 required'>Pipeline</h3>
                        </div>
                        {pipelines.length !== 0 ? (
                          <select
                            className='form-select form-select-solid'
                            {...formikNewLine.getFieldProps('pipeline_newline')}
                          >
                            {pipelines?.map((pipeline: any) => (
                              <option value={pipeline.name} key={pipeline.name}>
                                {pipeline.name}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <input
                            className='form-control form-control-solid'
                            placeholder='pipeline'
                            disabled
                            {...formikNewLine.getFieldProps('pipeline_newline')}
                          />
                        )}
                      </div>
                      <div className='d-flex justify-content-end'>
                        {formikNewLine.touched.pipeline_newline && formikNewLine.errors.pipeline_newline && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger'>{formikNewLine.errors.pipeline_newline}</div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='mb-10 d-flex'>
                      <div className='d-flex me-15 col-12'>
                        <label className='d-flex flex-column col-5'>
                          <span className='small text-uppercase fw-bolder text-gray-600'>Smart call</span>
                          <span className='small text-gray-500'>by time</span>
                        </label>
                        <div className='d-flex align-items-center'>
                          <div className='form-check form-switch form-check-custom form-check-solid '>
                            <input
                              className='form-check-input h-20px w-30px align-self-center'
                              type='checkbox'
                              onClick={(e: any) => {
                                updateSmartCallState(e.target.checked)
                              }}
                              id='smartCallNewSwitch'
                              {...formikNewLine.getFieldProps('smart_call_newline')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='mb-10 d-flex flex-stack'>
                      <label className='d-flex col-5'></label>
                      <div className='d-flex align-items-center col-7'>
                        <input
                          type='text'
                          className='form-control form-control-solid me-5'
                          placeholder='09:00'
                          disabled={!smartCallState}
                          {...formikNewLine.getFieldProps('start_time_newline')}
                        />
                        -
                        <input
                          type='text'
                          className='form-control form-control-solid ms-5'
                          placeholder='18:00'
                          disabled={!smartCallState}
                          {...formikNewLine.getFieldProps('end_time_newline')}
                        />
                      </div>
                    </div>
                    <div className='mb-10'>
                      <div className='d-flex flex-stack'>
                        <div className='stepper-label align-items-center col-5 self-align-center'>
                          <h3 className='small text-uppercase fw-bolder text-gray-600 required'>Incoming call</h3>
                        </div>
                        <select
                          className='form-control form-control-solid'
                          {...formikNewLine.getFieldProps('incoming_call_newline')}
                        >
                          <option value='None'>None</option>
                          <option value='Unsorted'>Unsorted</option>
                          <option value='Contact'>Contact</option>
                          <option value='ContactLead'>Contact + Lead</option>
                        </select>
                      </div>
                    </div>
                    <div className='mb-10 d-flex'>
                      <div className='d-flex me-15 col-12'>
                        <div className='stepper-label align-items-center col-5 self-align-center'>
                          <h3 className='small text-uppercase fw-bolder text-gray-600'>Unsorted</h3>
                          <div className='text-muted fs-8'>during a phone call</div>
                        </div>

                        <div className='d-flex align-items-center'>
                          <div className='form-check form-switch form-check-custom form-check-solid '>
                            <input
                              className='form-check-input h-20px w-30px align-self-center'
                              type='checkbox'
                              id='udpcNewSwitch'
                              {...formikNewLine.getFieldProps('udpc_newline')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='mb-10 d-flex'>
                      <div className='d-flex me-15 col-12'>
                        <label className='d-flex align-items-center self-align-center col-5'>
                          <span className='small text-uppercase fw-bolder text-gray-600'>Repeat sales mode</span>
                        </label>
                        <div className='d-flex align-items-center'>
                          <div className='form-check form-switch form-check-custom form-check-solid '>
                            <input
                              className='form-check-input h-20px w-30px align-self-center'
                              type='checkbox'
                              id='rsmNewSwitch'
                              {...formikNewLine.getFieldProps('rsm_newline')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='mb-10 d-flex'>
                      <div className='d-flex me-15 col-12'>
                        <label className='d-flex align-items-center self-align-center col-5'>
                          <span className='small text-uppercase fw-bolder text-gray-600'>Find in unsorted</span>
                        </label>
                        <div className='d-flex align-items-center'>
                          <div className='form-check form-switch form-check-custom form-check-solid '>
                            <input
                              className='form-check-input h-20px w-30px align-self-center'
                              type='checkbox'
                              id='findUnsortedNewSwitch'
                              {...formikNewLine.getFieldProps('find_unsorted_newline')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <div className='card-footer d-flex justify-content-end py-2 px-1' style={{border: 'none'}}>
                <button type='submit' id='submit_btn' className='btn btn-sm btn-primary me-3' disabled={isLoading}>
                  {!isLoading && 'Save Changes'}
                  {isLoading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
                <button
                  type='button'
                  className='btn btn-sm btn-light-primary align-self-center'
                  id='close-modal'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export {AddLineAmoCRM}
